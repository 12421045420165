import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Fade,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LastPageIcon from '@mui/icons-material/LastPage';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';
import {
  updateIdentifier,
  updateDescription,
  updateGroupPolicy,
  addNewJiraBoard,
  updateBoardIdentifier,
  updateBoardAccess,
  addNewConfluenceBoard,
  updateSpaceIdentifier,
  updateSpaceAccess,
  updateDefaultConfluenceAccess,
  updateDefaultJiraAccess,
  removeJiraBoard,
  removeConfluenceBoard,
  getGroupApps,
  getConfiguredPolicies,
  updateRestrictedAppList,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import {
  buttonIconStyles,
  mediumIconStyles,
} from '../../../../../../styles/icon';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
  lightBlueBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import { SubTitle } from '../../../../../../styles/subtitle';
import { CustomisedSwitch } from '../../../../../../styles/switch';
import { redirectToEditScreen } from '../../../../../../Redux/Slices/Application/appSlice';
import Cancel from '../../../../../../components/cancelButton';
import ManageRestriction from '../../../../Edit/AppConfiguration/GroupSettings/Common/manageRestriction';

function AtlassianGroupSettings() {
  const [currentApp, setCurrentApp] = useState<null | string>('jira');
  const [showAdvanceSettings, setShowAdvanceSettings] = useState<boolean>(
    false,
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    description,
    apps,
    policy,
    allPolicies,
    defaultPolicyID,
    atlassian,
  } = useSelector((state: RootState) => state.groupSettings);

  useEffect(() => {
    dispatch(updateRestrictedAppList());
  }, [apps]);

  useEffect(() => {
    if (!policy) {
      dispatch(updateGroupPolicy(defaultPolicyID));
    }
  }, [defaultPolicyID]);

  useEffect(() => {
    dispatch(getGroupApps());
    dispatch(getConfiguredPolicies());
  }, []);

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={1}>
          <ResponsiveTypography>Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '0.938rem',
              },
            }}
            name="identifier"
            value={identifier || ''}
            onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
          />
        </Grid>
        <Grid item xs={1} px="1rem">
          <ResponsiveTypography>Description</ResponsiveTypography>
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '0.938rem',
              },
            }}
            name="description"
            value={description || ''}
            onChange={(event: any) => dispatch(updateDescription(event.target.value))}
          />
        </Grid>
        <Grid item xs={1}>
          <ResponsiveTypography>Policy</ResponsiveTypography>
        </Grid>
        <Grid item xs={4}>
          <StyledSelect
            value={policy || defaultPolicyID}
            onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
          >
            {allPolicies.map((source: any) => (
              <MenuItem key={source.identifier} value={source._id}>
                {source.identifier}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ResponsiveTypography py="1rem">
          Application Permission
        </ResponsiveTypography>
        <Box display="flex" gap="1rem">
          {showAdvanceSettings ? (
            <Button
              sx={lightBlueBackgroundButtonStyles}
              onClick={() => setShowAdvanceSettings(false)}
            >
              Back
            </Button>
          ) : null}
          <Button
            sx={cyanBlueBackgroundButtonStyles}
            onClick={() => setShowAdvanceSettings(true)}
          >
            Advance Settings
          </Button>
        </Box>
      </Box>
      {showAdvanceSettings ? (
        <Box display="flex" flexDirection="column" width="100%" gap="0.5rem">
          <Tabs
            value={currentApp}
            onChange={(event: React.SyntheticEvent, newValue: string) => setCurrentApp(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Jira" value="jira" />
            <Tab label="Confluence" value="confluence" />
          </Tabs>
          <Fade
            in={currentApp === 'jira'}
            {...(currentApp === 'jira' ? { timeout: 1000 } : {})}
          >
            <Box
              display={currentApp === 'jira' ? 'flex' : 'none'}
              flexDirection="column"
              width="100%"
              gap="1rem"
              pt={{ xl: '1rem', lg: '0.5rem' }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box display="flex" gap="1rem">
                  <SubTitle>Default Access Control</SubTitle>
                  <CustomisedSwitch
                    checked={atlassian.defaultJiraAccess}
                    onClick={(event: any) => dispatch(updateDefaultJiraAccess(event.target.checked))}
                  />
                </Box>
                <Button
                  sx={{
                    ...lightBackgroundButtonStyles,
                    width: 'auto !important',
                    height: 'auto !important',
                    paddingX: { xl: '1 !important', lg: '2 !important' },
                    paddingY: {
                      xl: '0 !important',
                      lg: '0 !important',
                    },
                  }}
                  onClick={() => dispatch(addNewJiraBoard())}
                >
                  + Add Boards
                </Button>
              </Box>
              <Box
                sx={{
                  height: { xxl: '16rem', xl: '12rem', lg: '10rem' },
                  overflow: 'scroll',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: '0rem !important' }}>
                        <SubTitle>Identifier</SubTitle>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem !important' }}>
                        <SubTitle>Access (Allow / Deny)</SubTitle>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem !important' }}>
                        <SubTitle>Delete</SubTitle>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {atlassian.jiraBoard?.map((element: any, index: number) => (
                      <TableRow>
                        <TableCell
                          sx={{
                            paddingY: '0.5rem !important',
                            paddingX: '0rem !important',
                          }}
                        >
                          <StyledTextField
                            sx={{
                              width: { xl: '8rem', lg: '7rem' },
                            }}
                            id={`board-name-${index}`}
                            size="small"
                            name="name"
                            variant="outlined"
                            value={element.name}
                            onChange={(event: any) => dispatch(
                              updateBoardIdentifier({
                                identifier: event.target.value,
                                index,
                              }),
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '0 !important' }}>
                          <CustomisedSwitch
                            checked={element.access}
                            onChange={(event: any) => dispatch(
                              updateBoardAccess({
                                checked: event.target.checked,
                                index,
                              }),
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: '0 !important' }}>
                          <DeleteOutlineOutlinedIcon
                            sx={mediumIconStyles}
                            onClick={() => dispatch(removeJiraBoard(index))}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Fade>
          <Fade
            in={currentApp === 'confluence'}
            {...(currentApp === 'confluence' ? { timeout: 1000 } : {})}
          >
            <Box
              display={currentApp === 'confluence' ? 'flex' : 'none'}
              flexDirection="column"
              width="100%"
              gap="1rem"
              pt={{ xl: '1rem', lg: '0.5rem' }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box display="flex" gap="1rem">
                  <SubTitle>Default Access Control</SubTitle>
                  <CustomisedSwitch
                    checked={atlassian.defaultJiraAccess}
                    onClick={(event: any) => dispatch(
                      updateDefaultConfluenceAccess(event.target.checked),
                    )}
                  />
                </Box>
                <Button
                  sx={{
                    ...lightBackgroundButtonStyles,
                    width: 'auto !important',
                    height: 'auto !important',
                    paddingX: { xl: '1 !important', lg: '2 !important' },
                    paddingY: {
                      xl: '0rem !important',
                      lg: '0rem !important',
                    },
                  }}
                  onClick={() => dispatch(addNewConfluenceBoard())}
                >
                  + Add Space
                </Button>
              </Box>
              <Box
                sx={{
                  height: { xxl: '16rem', xl: '12rem', lg: '10rem' },
                  overflow: 'scroll',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: '0 !important' }}>
                        <SubTitle>Identifier</SubTitle>
                      </TableCell>
                      <TableCell sx={{ padding: '0 !important' }}>
                        <SubTitle>Access (Allow / Deny)</SubTitle>
                      </TableCell>
                      <TableCell sx={{ padding: '0 !important' }}>
                        <SubTitle>Delete</SubTitle>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {atlassian.confluenceBoard?.map(
                      (element: any, index: number) => (
                        <TableRow>
                          <TableCell
                            sx={{
                              paddingY: '0.5rem !important',
                              paddingX: '0 !important',
                            }}
                          >
                            <StyledTextField
                              sx={{
                                width: { xl: '8rem', lg: '7rem' },
                              }}
                              id={`board-name-${index}`}
                              size="small"
                              name="name"
                              variant="outlined"
                              value={element.name}
                              onChange={(event: any) => dispatch(
                                updateSpaceIdentifier({
                                  identifier: event.target.value,
                                  index,
                                }),
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: '0 !important' }}>
                            <CustomisedSwitch
                              checked={element.access}
                              onChange={(event: any) => dispatch(
                                updateSpaceAccess({
                                  checked: event.target.checked,
                                  index,
                                }),
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: '0 !important' }}>
                            <DeleteOutlineOutlinedIcon
                              onClick={() => dispatch(removeConfluenceBoard(index))}
                              sx={mediumIconStyles}
                            />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Fade>
        </Box>
      ) : (
        <ManageRestriction />
      )}
      <Box display="flex" width="100%" mt="0.5rem" gap="1rem">
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
          onClick={() => dispatch(redirectToEditScreen(true))}
        >
          Skip
        </Button>
        <Cancel />
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(configureNewGroup())}
        >
          Finish&nbsp;&nbsp;
          <LastPageIcon sx={{ ...buttonIconStyles, color: 'white' }} />
        </Button>
      </Box>
    </Box>
  );
}

export default AtlassianGroupSettings;
