export const smallIconStyles = {
  fontSize: {
    xl: '1.25rem !important',
    lg: '1.25rem !important',
    md: '1.125rem !important',
  },
  color: '#171821',
  cursor: 'pointer !important',
};

export const mediumIconStyles = {
  fontSize: { xl: '1.563rem !important', lg: '1.25rem !important' },
  padding: 0,
  margin: 0,
  color: '#171821',
  cursor: 'pointer !important',
};
export const buttonIconStyles = {
  fontSize: {
    xl: '1.125rem !important',
    lg: '0.938rem !important',
    md: '0.75rem !important',
  },
  padding: 0,
  margin: 0,
  color: '#171821',
  cursor: 'pointer !important',
};
export const smallIconStyles1 = {
  fontSize: {
    xl: '1.875rem !important',
    lg: '1.375rem !important',
    md: '1.125rem !important',
  },
  color: '#171821',
  cursor: 'pointer !important',
};
