import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import './assets/css/app.css';
import router from './Router';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Inter',
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400,
      xxl: 1700,
    },
  },
  palette: {
    title: {
      main: '#171821',
    },
    subtitle: {
      main: '#636363',
    },
    info: {
      main: '#717171',
    },
    border: {
      main: '#EBEBEB',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
