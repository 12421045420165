import React from 'react';
import { Box, Button, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ResponsiveTypography } from '../styles/inputLabel';
import { SubTitle } from '../styles/subtitle';
import { SubLabel } from '../styles/sublabel';
import { StyledTextField } from '../styles/textField';
import { smallIconStyles } from '../styles/icon';

interface SAMLIDPMetadataProps {
  open: boolean;
  setOpen: Function;
  IDPMetadata: any;
}

function SAMLIDPMetadata({ open, setOpen, IDPMetadata }: SAMLIDPMetadataProps) {
  const CopiedToClipBoard = () => {
    toast('Copied to Clipboard');
  };
  const handleClose = () => setOpen(false);

  const handleDownloadSAMLCertificate = () => {
    if ((IDPMetadata as any)?.x_509_certificate?.trim() === '') {
      return;
    }
    const blob = new Blob([(IDPMetadata as any)?.x_509_certificate], {
      type: 'application/x-x509-ca-cert',
    });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'saml-certificate.crt';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          sx={{ backgroundColor: 'white' }}
          p="1.25rem"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="1rem"
          >
            <Box>
              <ResponsiveTypography>IDP Metadata</ResponsiveTypography>
              <SubTitle>
                To check out the IDP metadata for this application.
              </SubTitle>
            </Box>
          </Box>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="1.25rem"
          width="100%"
          sx={{ backgroundColor: 'white' }}
          gap="1rem"
        >
          <Box display="flex" flexDirection="column">
            <Box>
              <SubLabel>IdP Entity ID or Issuer</SubLabel>
            </Box>
            <Box>
              <StyledTextField
                disabled
                variant="outlined"
                size="small"
                fullWidth
                value={(IDPMetadata as any)?.entity_id || ''}
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={(IDPMetadata as any)?.entity_id}>
                      <ContentCopyIcon
                        sx={{
                          ...smallIconStyles,
                          backgroundColor: 'white !important',
                          cursor: 'pointer !important',
                        }}
                        onClick={CopiedToClipBoard}
                      />
                    </CopyToClipboard>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box>
              <SubLabel>SAML Login URL</SubLabel>
            </Box>
            <Box>
              <StyledTextField
                disabled
                variant="outlined"
                size="small"
                fullWidth
                value={(IDPMetadata as any)?.login_url || ''}
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={(IDPMetadata as any)?.login_url}>
                      <ContentCopyIcon
                        sx={{
                          ...smallIconStyles,
                          backgroundColor: 'white !important',
                          cursor: 'pointer !important',
                        }}
                        onClick={CopiedToClipBoard}
                      />
                    </CopyToClipboard>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box>
              <SubLabel>X.509 Certificate</SubLabel>
            </Box>
            <Box>
              <StyledTextField
                disabled
                fullWidth
                multiline
                rows={10}
                maxRows={10}
                value={(IDPMetadata as any)?.x_509_certificate}
                InputProps={{
                  endAdornment: (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2rem"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <DownloadIcon
                        sx={{
                          ...smallIconStyles,
                          backgroundColor: 'white !important',
                          cursor: 'pointer !important',
                        }}
                        onClick={handleDownloadSAMLCertificate}
                      />
                      <CopyToClipboard
                        text={(IDPMetadata as any)?.x_509_certificate}
                      >
                        <ContentCopyIcon
                          sx={{
                            ...smallIconStyles,
                            backgroundColor: 'white !important',
                            cursor: 'pointer !important',
                          }}
                          onClick={CopiedToClipBoard}
                        />
                      </CopyToClipboard>
                    </Box>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <br />
        <br />
      </Box>
    </Drawer>
  );
}

export default SAMLIDPMetadata;
