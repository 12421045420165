import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubTitle = styled(Typography)(({ theme }: any) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.5rem !important',
    fontWeight: 200,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.7rem !important',
    fontWeight: 400,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.7rem !important',
    fontWeight: 300,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.8rem !important',
    fontWeight: 400,
  },
}));
