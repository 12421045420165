import React from 'react';
import { Box } from '@mui/material';
import Logo from '../assets/images/miniorange-rp.png';

export default function MiniOrangeLogo() {
  return (
    <Box
      component="img"
      src={Logo}
      alt="miniorange-logo"
      sx={{
        height: {
          xl: '2.5rem !important',
          lg: '2.188rem !important',
          md: '1.563rem !important ',
        },
      }}
    />
  );
}
