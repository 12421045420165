import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArticleIcon from '@mui/icons-material/Article';
import {
  lightBackgroundButtonStyles,
  lightBlueBackgroundButtonStyles,
} from '../../../../../styles/button';
import SAMLIDPMetadata from '../../../../../components/SAMLIDPMetadata';
import { getUser, testSAMLConnection } from '../../../../../utils/utility';
import { RootState } from '../../../../../Redux/store';
import { buttonIconStyles } from '../../../../../styles/icon';
import { apps } from '../../../app';
import { ResponsiveTypography } from '../../../../../styles/inputLabel';

function BasicSettingsInformation() {
  const [openIDPMetadata, setOpenIDPMetadata] = useState<boolean>(false);
  const { app, idpMetadata, authenticationID } = useSelector(
    (state: RootState) => state.application,
  );
  const OpenIDPMetadataURL = async () => {
    const user = await getUser();
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/saml/${user.customer_id}/${
        user.current_active_tenant_id
      }/metadata?app_name=${String(app)}`,
      '_blank',
    );
  };
  return (
    <Box>
      <SAMLIDPMetadata
        open={openIDPMetadata}
        setOpen={setOpenIDPMetadata}
        IDPMetadata={idpMetadata}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        borderBottom="0.063rem solid #E3E3E3"
        py="1rem"
      >
        <ResponsiveTypography>IDP Metadata</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            onClick={OpenIDPMetadataURL}
          >
            Metadata URL
          </Button>
          <Button
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            onClick={() => setOpenIDPMetadata(true)}
          >
            View IDP Metdata
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        borderBottom="0.063rem solid #E3E3E3"
        py="1rem"
      >
        <ResponsiveTypography variant="button">Authentication Source</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            sx={{
              ...lightBlueBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            startIcon={(
              <SettingsEthernetOutlinedIcon
                sx={{ ...buttonIconStyles, color: '#0065FF' }}
              />
            )}
            onClick={() => testSAMLConnection(authenticationID, app)}
          >
            Test Connection
          </Button>
        </Box>
      </Box>
      {apps[app].appConfiguration}
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        borderBottom="0.063rem solid #E3E3E3"
        py="1rem"
      >
        <ResponsiveTypography>Guide</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            startIcon={
              <YouTubeIcon sx={{ ...buttonIconStyles, color: 'red' }} />
            }
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
          >
            Video Guide
          </Button>
          <Button
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            startIcon={
              <ArticleIcon sx={{ ...buttonIconStyles, color: 'blue' }} />
            }
            onClick={() => window.open(`${apps[app].guide}#step3`, '_blank')}
          >
            Setup Guide
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettingsInformation;
