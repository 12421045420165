import { configureStore } from '@reduxjs/toolkit';
import appSlice from './Slices/Application/appSlice';
import authenticationSourceSlice from './Slices/Application/AuthenticationSource/authenticationSourceSlice';
import policySettings from './Slices/Policy/policy';
import proxySettings from './Slices/Policy/Proxy/proxy';
import basicSettingsSlice from './Slices/Application/BasicSettings/basicSettingsSlice';
import manageGroupSlice from './Slices/Application/AppConfiguration/Group/group';
import sessionManagementSlice from './Slices/Application/SessionManagement/sessionManagement';
import customerSlice from './Slices/Customer/customer';
import extensionFeedbackForm from './Slices/Extension-Feedback/feedback-form';
import customerSupportForm from './Slices/Support/support';

export const store = configureStore({
  reducer: {
    application: appSlice.reducer,
    authenticationSource: authenticationSourceSlice.reducer,
    policySettings: policySettings.reducer,
    proxySettings: proxySettings.reducer,
    basicSettings: basicSettingsSlice.reducer,
    groupSettings: manageGroupSlice.reducer,
    session: sessionManagementSlice.reducer,
    customer: customerSlice.reducer,
    extensionFeedback: extensionFeedbackForm.reducer,
    customerSupport: customerSupportForm.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
