import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const CustomisedSwitch = styled(Switch)(({ theme }: any) => ({
  width: '1.75rem',
  height: '1rem',
  padding: '0rem',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '0.938rem',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(0.563rem)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '0.125rem',
    '&.Mui-checked': {
      transform: 'translateX(0.563rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 0.125rem 0.25rem 0 rgb(0 35 11 / 20%)',
    width: '0.75rem',
    height: '0.75rem',
    borderRadius: '0.375rem',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
