import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  name: '',
  query: 'Setup Assistance',
  description: '',
};

interface UpdateFormPayload {
  target: keyof typeof initialState;
  value: string;
}

const extensionFeedbackForm = createSlice({
  name: 'extensionFeedbackForm',
  initialState,
  reducers: {
    updateForm: (state, action: PayloadAction<UpdateFormPayload>) => {
      const { target, value } = action.payload;
      state[target] = value;
    },
  },
});

export const { updateForm } = extensionFeedbackForm.actions;
export default extensionFeedbackForm;
