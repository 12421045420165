import React, { useEffect } from 'react';
import {
  Box, Grid, Button, Typography, Tooltip,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { ResponsiveTypography } from '../../../../../styles/inputLabel';
import { cyanBlueBackgroundButtonStyles } from '../../../../../styles/button';
import { StyledTextField } from '../../../../../styles/textField';
import {
  updateACSURL,
  updateApplicationIdentifier,
  updateAttributeKey,
  updateDomain,
  updateEntityURL,
  updateNameAttributeKey,
  updateCASBType,
  updateEnableMDM,
  updateEnableAuditing,
} from '../../../../../Redux/Slices/Application/BasicSettings/basicSettingsSlice';
import { updateBasicSettings } from '../../../../../Redux/Slices/Application/BasicSettings/updateApplicationHandler';
import { AppDispatch, RootState } from '../../../../../Redux/store';
import { isValidDomain, isValidURL } from '../../../../../utils/utility';
import { buttonIconStyles } from '../../../../../styles/icon';
import { CustomisedSwitch } from '../../../../../styles/switch';
import { SubLabel } from '../../../../../styles/sublabel';
import Cancel from '../../../../../components/cancelButton';

interface BasicSettingsProps {
  appName?: string;
  buttonText?: string;
}

function BasicSettings({
  appName = '',
  buttonText = 'Save & Next',
}: BasicSettingsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    domain,
    attributeKey,
    nameAttributeKey,
    acsURL,
    acsEntityURL,
    casbType,
    mdm,
    auditing,
  } = useSelector((state: RootState) => state.basicSettings);

  useEffect(() => {
    if (appName !== 'Google') {
      dispatch(updateCASBType('offline'));
    }
  }, [appName, dispatch]);

  return (
    <>
      <Box>
        <Grid container sx={{ rowGap: { xl: 2, lg: 1 } }}>
          <Grid item xs={5}>
            <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
              <ResponsiveTypography>Application Name</ResponsiveTypography>
              <ResponsiveTypography color="red">*</ResponsiveTypography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '0.938rem',
                },
              }}
              name="identifier"
              onChange={(e: any) => dispatch(updateApplicationIdentifier(e.target.value))}
              value={identifier}
            />
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
              <ResponsiveTypography>Organization Domain</ResponsiveTypography>
              <ResponsiveTypography color="red">*</ResponsiveTypography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                name="domain"
                onChange={(e: any) => dispatch(updateDomain(e.target.value))}
                value={domain}
              />
              <Typography
                display={!domain || isValidDomain(domain) ? 'none' : 'flex'}
                sx={{ fontSize: '0.625rem', color: 'red' }}
              >
                Please provide a valid domain.
              </Typography>
            </>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
              <ResponsiveTypography>Attribute Key</ResponsiveTypography>
              <ResponsiveTypography color="red">*</ResponsiveTypography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '0.938rem',
                },
              }}
              name="attributeKey"
              onChange={(e: any) => dispatch(updateAttributeKey(e.target.value))}
              value={attributeKey}
            />
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
              <ResponsiveTypography>Name Attribute Key</ResponsiveTypography>
              <ResponsiveTypography color="red">*</ResponsiveTypography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '0.938rem',
                },
              }}
              name="nameAttributeKey"
              onChange={(e: any) => dispatch(updateNameAttributeKey(e.target.value))}
              value={nameAttributeKey}
            />
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
              <ResponsiveTypography>ACS URL</ResponsiveTypography>
              <ResponsiveTypography color="red">*</ResponsiveTypography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                name="acsURL"
                onChange={(e: any) => dispatch(updateACSURL(e.target.value))}
                value={acsURL}
              />
              <Typography
                display={!acsURL || isValidURL(acsURL) ? 'none' : 'flex'}
                sx={{ fontSize: '0.625rem', color: 'red' }}
              >
                Please provide a valid url.
              </Typography>
            </>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
              <ResponsiveTypography>Entity URL</ResponsiveTypography>
              <ResponsiveTypography color="red">*</ResponsiveTypography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '0.938rem',
                },
              }}
              name="entityURL"
              onChange={(e: any) => dispatch(updateEntityURL(e.target.value))}
              value={acsEntityURL}
            />
          </Grid>
          {appName === 'Google' ? (
            <>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <ResponsiveTypography>CASB Type</ResponsiveTypography>
                  <Tooltip
                    title="Choose between With extension and without extension."
                    arrow
                  >
                    <InfoIcon
                      sx={{
                        fontSize: '1rem',
                        marginLeft: '0.5rem',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="CASBType"
                  value={casbType || 'offline'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(updateCASBType(event.target.value))}
                >
                  <FormControlLabel
                    value="inline"
                    control={<Radio size="small" />}
                    label={<SubLabel>Agentless</SubLabel>}
                    sx={{ fontSize: '0.813rem' }}
                  />
                  <FormControlLabel
                    value="offline"
                    control={<Radio size="small" />}
                    label={<SubLabel>Agent</SubLabel>}
                    sx={{ fontSize: '0.938rem' }}
                  />
                </RadioGroup>
              </Grid>
            </>
          ) : null}

          <Grid item xs={5}>
            <ResponsiveTypography>Enable Auditing</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <CustomisedSwitch
              checked={auditing}
              onChange={(event: any) => dispatch(updateEnableAuditing(event.target.checked))}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Enable MDM</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <CustomisedSwitch
              checked={mdm}
              onChange={(event: any) => dispatch(updateEnableMDM(event.target.checked))}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" width="100%" mt={2} gap="1rem">
        <Cancel />
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(updateBasicSettings())}
        >
          {buttonText}
          &nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </>
  );
}
BasicSettings.defaultProps = {
  appName: '',
  buttonText: 'Save & Next',
};

export default BasicSettings;
