import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  AppBar,
  ListItem,
  ListItemButton,
  CssBaseline,
  Tooltip,
  Avatar,
  Typography,
} from '@mui/material';
import { LogoutOutlined, Settings, Logout } from '@mui/icons-material';
import { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import SyncIcon from '@mui/icons-material/Sync';
import MenuItem from '@mui/material/MenuItem';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import LaptopWindowsOutlinedIcon from '@mui/icons-material/LaptopWindowsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveTypography } from '../styles/inputLabel';
import MiniOrangeLogo from './MiniOrangeLogo';
import MiniOrangeShortLogo from './MiniOrangeShortLogo';
import {
  getAllTenants,
  userSwitchTenant,
  getActiveTenant,
  getUser,
} from '../utils/utility';
import {
  // cyanBlueBackgroundButtonStyles,
  cyanBlueBackgroundButtonStyles1,
  zeroPaddingAutoWidthButton,
} from '../styles/button';
import { mediumIconStyles, smallIconStyles } from '../styles/icon';
import { AppDispatch, RootState } from '../Redux/store';
import { updateCustomer } from '../Redux/Slices/Customer/customer';
import SupportForm from './supportForm';
import { getScreenByRoute } from '../data/routes';

interface MainLayoutProps {
  children: React.ReactElement;
}
const customFontStyle = {
  fontWeight: 'bold',
  fontSize: { xl: '1.1rem', lg: '0.9rem', md: '0.7rem' },
  color: '#333',
};

function ListItemComponent({
  icon, text, to, drawer, selected,
}: any) {
  return (
    <Link to={to} style={{ textDecoration: 'none', color: '#9A9A9A' }}>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            '&:hover': {
              backgroundColor: '#E0F2FF',
              cursor: 'pointer',
            },
            borderRadius: { xl: '0.5rem', lg: '0.4rem', md: '0.3rem' },
            border: '0rem, 0rem, 0rem, 0.188rem',
            paddingY: {
              xl: '0.7rem !important',
              lg: '0.5rem !important',
              md: '0.3rem !important',
            },
            backgroundColor: selected ? '#E0F2FF' : 'transparent',
            borderLeft: selected
              ? {
                xl: '0.2rem solid #296ED4',
                lg: '0.15rem solid #296ED4',
                md: '0.13rem solid #296ED4',
              }
              : 'none',
          }}
        >
          {icon}
          &nbsp;&nbsp;
          <ResponsiveTypography
            variant="subtitle2"
            color="#171821"
            sx={{
              display: drawer ? 'block' : 'none',
              ...customFontStyle,
              marginLeft: '0.5rem',
            }}
          >
            {text}
          </ResponsiveTypography>
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

export default function MainLayout({
  children,
}: MainLayoutProps): React.ReactElement {
  const dispatch = useDispatch<AppDispatch>();
  const alphabetColors = [
    { letter: 'a', color: '#FF69B4' },
    { letter: 'b', color: '#33CC33' },
    { letter: 'c', color: '#6666FF' },
    { letter: 'd', color: '#FF9900' },
    { letter: 'e', color: '#0099CC' },
    { letter: 'f', color: '#CC0099' },
    { letter: 'g', color: '#33FFFF' },
    { letter: 'h', color: '#666600' },
    { letter: 'i', color: '#FFCC00' },
    { letter: 'j', color: '#009933' },
    { letter: 'k', color: '#CC6600' },
    { letter: 'l', color: '#33CC00' },
    { letter: 'm', color: '#6666CC' },
    { letter: 'n', color: '#FF0066' },
    { letter: 'o', color: '#0099FF' },
    { letter: 'p', color: '#CC0033' },
    { letter: 'q', color: '#33FF99' },
    { letter: 'r', color: '#666633' },
    { letter: 's', color: '#FFCC33' },
    { letter: 't', color: '#009966' },
    { letter: 'u', color: '#CC0099' },
    { letter: 'v', color: '#33FFFF' },
    { letter: 'w', color: '#666600' },
    { letter: 'x', color: '#FFCC00' },
    { letter: 'y', color: '#009933' },
    { letter: 'z', color: '#CC6600' },
  ];
  const { email } = useSelector((state: RootState) => state.customer);
  // const selectedItem = localStorage.getItem('selectedItem');
  const firstLetter = email.charAt(0).toLowerCase();
  const color = alphabetColors.find((item) => item.letter === firstLetter)?.color
    ?? '#defaultColor';
  const [allTenants, setAllTenants] = useState<any[]>([]);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [drawer, setDrawer] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState({
    xs: '11.25rem',
    sm: '12.5rem',
    md: '14rem',
    lg: '15.625rem',
    xl: '18.625rem',
  });

  const navigate = useNavigate();

  const listItems = [
    {
      icon: <LaptopWindowsOutlinedIcon sx={smallIconStyles} />,
      text: 'Dashboard',
      to: '/dashboard',
      drawer,
    },
    {
      icon: <DashboardOutlinedIcon sx={smallIconStyles} />,
      text: 'Applications',
      to: '/applications',
      drawer,
    },
    {
      icon: <VerifiedUserOutlinedIcon sx={smallIconStyles} />,
      text: 'Manage Policy',
      to: '/manage-policy',
      drawer,
    },
    {
      icon: <LockOpenOutlinedIcon sx={smallIconStyles} />,
      text: 'Authentication Source',
      to: '/configure/authentication-source',
      drawer,
    },
    {
      icon: <ListOutlinedIcon sx={smallIconStyles} />,
      text: 'Secure Cloud App',
      to: '/configure/proxy',
      drawer,
    },
  ];
  const selectedItem = getScreenByRoute(window.location.pathname);
  const handleListItemClick = (to: string) => {
    navigate(to);
  };
  const handleTenantSwitch = (event: SelectChangeEvent) => {
    const tenantName = event.target.value as string;
    setSelectedTenant(tenantName);
    allTenants.forEach(async (tenant) => {
      if (tenantName === tenant.tenant_name) {
        const tenantID = tenant._id;
        await userSwitchTenant({ tenantID }).then(async (response) => {
          const timestamp = new Date().getTime();
          const exp = timestamp + 60 * 60 * 24 * 1000 * 7;
          document.cookie = `token=${response};expires=${exp};path=/`;
          await window.location.reload();
        });
      }
    });
  };

  useEffect(() => {
    async function getAllTenantsInfo() {
      getAllTenants().then((data: any) => {
        setAllTenants(data);
      });
    }
    async function getUserDetails() {
      const user: any = await getUser();
      dispatch(updateCustomer(user));
    }
    getAllTenantsInfo();
    getUserDetails();
  }, []);

  useEffect(() => {
    async function getCurrentActiveTenant() {
      const currentActiveTenant = getActiveTenant();
      currentActiveTenant.then((result) => {
        allTenants.forEach((tenant) => {
          if (tenant._id === result) {
            setSelectedTenant(tenant.tenant_name);
          }
        });
      });
    }
    getCurrentActiveTenant();
  }, [allTenants]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const logoutUser = () => {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // localStorage.removeItem('selectedItem');
    navigate('/login');
  };

  const handleHideSidebar = () => {
    setDrawer(false);
    setDrawer(false);
    setDrawerWidth({
      xs: '3.125rem',
      sm: '3.75rem',
      md: '5rem',
      lg: '5rem',
      xl: '5.25rem',
    });
  };

  const handleShowSidebar = () => {
    setDrawer(true);
    setDrawerWidth({
      xs: '11.25rem',
      sm: '12.5rem',
      md: '14rem',
      lg: '15.625rem',
      xl: '18.625rem',
    });
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        boxShadow: 'none !important',
      }}
    >
      <CssBaseline />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py={{ lg: '0.5rem', xl: '0.5rem', md: '0.5rem' }}
        sx={{
          height: '100%',
          width: {
            xs: drawerWidth.xs,
            sm: drawerWidth.sm,
            md: drawerWidth.md,
            lg: drawerWidth.lg,
            xl: drawerWidth.xl,
          },
          transition: 'width 0.3s',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: '1px',
            backgroundColor: '#D9D9D9',
          }}
        >
          <Box
            onClick={drawer ? handleHideSidebar : handleShowSidebar}
            sx={{
              position: 'absolute',
              top: { md: '4%', lg: '8%', xl: '6%' },
              right: '-0.75rem',
              transform: 'translateY(-50%)',
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 0 0.313rem rgba(0, 0, 0, 0.2)',
              zIndex: '1200',
              '&:hover': {
                backgroundColor: '#0052CC',
                '& .MuiSvgIcon-root': {
                  color: '#FFF',
                  backgroundColor: '#0099FF',
                },
                cursor: 'pointer',
              },
            }}
          >
            {drawer ? (
              <ChevronLeftIcon
                sx={{
                  color: '#0052CC',
                  fontSize: '1.5rem',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  color: '#0052CC',
                  fontSize: '1.5rem',
                  borderRadius: '50%',
                }}
              />
            )}
          </Box>
        </Box>

        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pl="1rem"
            pr={
              drawer
                ? { md: '0rem', lg: '0rem', xl: '0rem' }
                : { md: '1rem', lg: '2.5rem', xl: '2.5rem' }
            }
            marginRight={{ md: '0.5rem', lg: '0.5rem', xl: '0.5rem' }}
            alignItems="center"
          >
            {drawer ? <MiniOrangeLogo /> : <MiniOrangeShortLogo />}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: {
                xl: '3rem !important',
                lg: '2rem !important',
                md: '2rem !important',
              },
              gap: {
                xl: '0.3rem !important',
                lg: '0.3rem !important',
                md: '0.10rem !important',
              },
            }}
            pr="0.5rem"
            pl="0.3rem"
          >
            {listItems.map((item) => (
              <div
                key={item.to}
                onClick={() => handleListItemClick(item.to)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleListItemClick(item.to);
                  }
                }}
              >
                <ListItemComponent
                  {...item}
                  selected={selectedItem === item.to}
                />
              </div>
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          pl={2}
          py={2}
          borderTop="0.063rem solid #EBEBEB"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: '0.5rem',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={logoutUser}
          >
            <LogoutOutlined sx={smallIconStyles} />
            <ResponsiveTypography display={drawer ? 'flex' : 'none'}>
              Logout
            </ResponsiveTypography>
          </Box>
          <Typography
            display={drawer ? 'flex' : 'none'}
            sx={{
              fontSize: { xl: '0.75rem', lg: '0.625rem', md: '0.5rem' },
              color: '#636363',
            }}
          >
            Powered by
            <span style={{ paddingLeft: '0.188rem', color: '#F07D2C' }}>
              miniOrange
            </span>
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flexGrow="1"
        width={{ xl: '100%', lg: '100%', md: '100%' }}
      >
        <AppBar
          style={{
            position: 'sticky',
            background: '#ffffff',
            borderBottom: '0.063rem solid #D9D9D9',
            boxShadow:
              '0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.08)',
          }}
        >
          <Box
            display={{ xs: 'flex', lg: 'flex', md: 'flex' }}
            alignItems="center"
            justifyContent="end"
            gap={{
              xl: '1rem !important',
              lg: '0.75rem !important',
              md: '0.5rem !important',
            }}
            px={{
              xl: '1rem !important',
              lg: '0.75rem !important',
              md: '0.1rem !important',
            }}
            py={{
              xl: '0.5rem !important',
              lg: '0.4rem !important',
              md: '0.35rem !important',
            }}
            width="100%"
          >
            <Box
              display={{ xs: 'flex', lg: 'flex', md: 'flex' }}
              alignItems="center"
              justifyContent="end"
              gap={{ md: '0.7rem', lg: '1rem', xl: '1rem' }}
              paddingX="0.5rem"
            >
              <Button sx={zeroPaddingAutoWidthButton}>
                <Tooltip title="Support" placement="top" arrow>
                  <HeadsetMicIcon
                    sx={mediumIconStyles}
                    onClick={() => window.open(
                      'https://www.miniorange.com/contact',
                      '_blank',
                    )}
                  />
                </Tooltip>
              </Button>
              <Button
                sx={zeroPaddingAutoWidthButton}
                onClick={() => navigate('/settings')}
              >
                <Tooltip title="Tenant Settings" placement="top" arrow>
                  <SettingsIcon sx={mediumIconStyles} />
                </Tooltip>
              </Button>
              <FormControl>
                <Select
                  value={selectedTenant !== '' ? selectedTenant : 'default'}
                  renderValue={() => (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <SyncIcon sx={{ ...smallIconStyles, color: 'white' }} />
                      &nbsp;&nbsp;
                      <Box
                        sx={{
                          padding: { md: '0.1rem', xl: '0rem', lg: '0.4rem' },
                          fontSize: {
                            xl: '1.3rem !important',
                            lg: '0.75rem !important',
                            md: '0.6rem !important',
                          },
                          gap: { md: '0.5rem !important' },
                        }}
                      >
                        Switch Tenant
                        {/* {selectedTenant !== '' ? value : selectedTenant} */}
                      </Box>
                    </span>
                  )}
                  sx={{
                    ...cyanBlueBackgroundButtonStyles1,
                    height: {
                      lg: '1.5rem !important',
                      xl: '2.5rem !important',
                      md: '1.2rem !important',
                    },
                    width: {
                      xl: '14rem !important',
                      lg: '9rem !important',
                      md: '7rem !important',
                    },
                    minWidth: {
                      md: '7rem !important',
                      lg: '8rem !important',
                      xl: '13rem !important',
                    },
                    minHeight: {
                      md: '1.5rem !important',
                      lg: '1.7rem !important',
                      xl: '2rem !important',
                    },
                  }}
                  onChange={handleTenantSwitch}
                  inputProps={{ IconComponent: () => null }}
                >
                  {allTenants.map((tenant) => (
                    <MenuItem
                      value={tenant.tenant_name}
                      key={tenant.tenant_name}
                    >
                      {tenant.tenant_name}
                    </MenuItem>
                  ))}
                  <Link to="/add/tenant" style={{ textDecoration: 'none' }}>
                    <MenuItem
                      value="Add New Tenant"
                      key="Add New Tenant"
                      sx={{ textDecoration: 'none' }}
                    >
                      View All Tenants
                    </MenuItem>
                  </Link>
                </Select>
              </FormControl>
              <Button sx={zeroPaddingAutoWidthButton} onClick={handleClick}>
                <Tooltip title="Profile" placement="top" arrow>
                  <Avatar
                    sx={{
                      width: {
                        xxl: '2.5rem',
                        xl: '1.875rem',
                        lg: '2rem',
                        md: '1.563rem',
                      },
                      height: {
                        xxl: '2.5rem',
                        xl: '1.875rem',
                        lg: '2rem',
                        md: '1.563rem',
                      },
                      backgroundColor: 'white !important',
                      border: '0.125rem solid #BFBFBF',
                      color,
                      fontSize: {
                        xxl: '1.563rem',
                        xl: '1.25rem',
                        lg: '0.938rem',
                        md: '0.625rem',
                      },
                    }}
                  >
                    {firstLetter.toUpperCase()}
                  </Avatar>
                </Tooltip>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  marginTop: {
                    md: '0.313rem',
                    lg: '0.438rem',
                    xl: '0.625rem',
                  },
                }}
              >
                <Box width="13rem" pb="1rem" p="0.5rem">
                  <Button
                    fullWidth
                    onClick={() => navigate('/settings')}
                    sx={{
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                      fontSize: '1.0rem',
                      display: 'flex',
                      gap: '1.5rem',
                    }}
                  >
                    <Settings />
                    Settings
                  </Button>
                  <Button
                    fullWidth
                    onClick={logoutUser}
                    sx={{
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                      fontSize: '1.0rem',
                      display: 'flex',
                      gap: '1.5rem',
                      color: 'red',
                      '&:hover': {
                        color: 'red',
                      },
                    }}
                  >
                    <Logout />
                    {' '}
                    Log Out
                  </Button>
                </Box>
              </Popover>
            </Box>
          </Box>
        </AppBar>
        <Box display="flex" position="relative" height="100vh">
          <Toaster />
          <Box sx={{ width: '100%' }} overflow="hidden">
            {children}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          mr: '1rem',
          mb: '1rem',
        }}
      >
        <SupportForm />
      </Box>
    </Box>
  );
}
