import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  message: '',
  url: '',
};

interface UpdateFormPayload {
  target: keyof typeof initialState;
  value: string;
}

const customerSupportForm = createSlice({
  name: 'customerSupportForm',
  initialState,
  reducers: {
    updateSupportForm: (state, action: PayloadAction<UpdateFormPayload>) => {
      const { target, value } = action.payload;
      state[target] = value;
    },
  },
});

export const { updateSupportForm } = customerSupportForm.actions;
export default customerSupportForm;
