import React from 'react';
import { Box } from '@mui/material';
import Application from '../../Application/application';

function Shopify() {
  return (
    <Box>
      <Application app={process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY!} />
    </Box>
  );
}

export default Shopify;
