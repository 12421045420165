import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer, Box, Grid, Button,
} from '@mui/material';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { ResponsiveTypography } from '../../styles/inputLabel';
import curveimg from '../../assets/images/Policy-curve.svg';
import { StyledTextField } from '../../styles/textField';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateIdentifier,
  updateDescription,
  updateOpen,
} from '../../Redux/Slices/Policy/policy';
import { configureNewPolicySettings } from '../../Redux/Slices/Policy/policyHandler';
import {
  lightBackgroundButtonStyles,
  cyanBlueBackgroundButtonStyles,
} from '../../styles/button';
import NetworkSettings from '../../components/Policy/networkRestriction';
import DrawerHeading from '../../components/Drawer/drawerHeading';
import TimeBasedRestriction from '../../components/Policy/timeBasedRestriction';
import RestrictImportExport from '../../components/Policy/restrictImportExport';
import ClipboardRestriction from '../../components/Policy/clipboardRestriction';

export default function AddNewPolicy() {
  const dispatch = useDispatch<AppDispatch>();
  const { identifier, description, open } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const networkSettingsRef = useRef<HTMLDivElement>(null);
  const timeRestrictionRef = useRef<HTMLDivElement>(null);
  const importExportRestrictionRef = useRef<HTMLDivElement>(null);
  const updatePolicy = () => {
    dispatch(configureNewPolicySettings());
  };
  const [selectedRestriction, setSelectedRestriction] = useState<string>(
    'network',
  );
  const restrictionBoxStyles = (isSelected: boolean) => ({
    backgroundColor: isSelected ? '#e0f0ff' : 'transparent',
    paddingY: { xl: '0.5rem', lg: '0.25rem' },
    paddingX: '0.7rem',
    borderRadius: '1.3rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border 0.3s',
  });
  const handleRestrictionClick = (
    restriction: string,
    ref?: any,
  ) => () => {
    setSelectedRestriction(restriction);
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={() => dispatch(updateOpen(false))}
      anchor="right"
    >
      <Box
        sx={{
          width: {
            xxl: '60rem !important',
            xl: '55rem !important',
            lg: '45rem !important',
            md: '40rem !important',
          },
          height: {
            xxl: '120rem !important',
            xl: '100rem !important',
            lg: '40rem !important',
            md: '40rem !important',
          },
        }}
        p={{ xl: '1rem', lg: '1rem', md: '0.5rem' }}
      >
        <DrawerHeading
          title="Policy Settings"
          subtitle="Configure Policy for your application here."
        />
        <Box pt="1rem">
          <Grid container pb="0.5rem" rowGap="0.5rem">
            <Grid item xs={2}>
              <ResponsiveTypography>Policy Name</ResponsiveTypography>
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                name="identifier"
                value={identifier || ''}
                onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
              />
            </Grid>
          </Grid>
          <Grid container pb="1rem">
            <Grid item xs={2}>
              <ResponsiveTypography>Description</ResponsiveTypography>
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                name="description"
                value={description || ''}
                onChange={(event: any) => dispatch(updateDescription(event.target.value))}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            width="100%"
            pt={{ md: '0rem', lg: '1rem', xl: '1rem' }}
            borderTop="0.063rem solid #E3E3E3"
          >
            <Box width="70%">
              <Box
                id="restriction-List"
                sx={{
                  height: {
                    xxl: '67vh !important',
                    xl: '65vh !important',
                    lg: '50vh !important',
                    md: '60vh !important',
                  },
                  overflow: 'scroll',
                }}
                display="flex"
                flexDirection="column"
                gap="0.5rem"
              >
                <Box ref={networkSettingsRef}>
                  <NetworkSettings />
                </Box>
                <Box ref={timeRestrictionRef}>
                  <TimeBasedRestriction />
                </Box>
                <Box ref={importExportRestrictionRef}>
                  <RestrictImportExport />
                </Box>
                <Box
                  id="disable-clipboard"
                  display="flex"
                  p="1rem"
                  flexDirection="column"
                >
                  <ClipboardRestriction />
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" width="40%" pl="1rem">
              <Box
                sx={{
                  backgroundImage: 'linear-gradient(#FFFFFF, #0065FF, #FFFFFF)',
                  width: '0.188rem !important',
                }}
              />
              <Box ml="0.5rem">
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={restrictionBoxStyles(selectedRestriction === 'network')}
                    onClick={handleRestrictionClick(
                      'network',
                      networkSettingsRef,
                    )}
                  >
                    <PublicOutlinedIcon
                      sx={{
                        width: { xl: '1.25rem', lg: '0.938rem' },
                        color: '#0065FF',
                        cursor: 'pointer',
                      }}
                    />
                    &nbsp;&nbsp;
                    <ResponsiveTypography
                      sx={{ cursor: 'pointer', color: '#0065FF' }}
                    >
                      Network Based Restriction
                    </ResponsiveTypography>
                  </Box>
                  <Box display="flex" flexDirection="row" pl="0.75rem">
                    <Box>
                      <img src={curveimg} alt="curve" height={55} />
                    </Box>
                    <Box ml={1}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => networkSettingsRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          IP Restriction
                        </ResponsiveTypography>
                      </Box>
                      <Box
                        m={0}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => networkSettingsRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Location Based Restriction
                        </ResponsiveTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={restrictionBoxStyles(selectedRestriction === 'time')}
                    onClick={handleRestrictionClick(
                      'time',
                      timeRestrictionRef,
                    )}
                  >
                    <AccessTimeFilledOutlinedIcon
                      sx={{
                        width: { xl: 20, lg: 15 },
                        color: '#0065FF',
                        cursor: 'pointer',
                      }}
                    />
                    &nbsp;&nbsp;
                    <ResponsiveTypography
                      sx={{ cursor: 'pointer', color: '#0065FF' }}
                    >
                      Time Based Restriction
                    </ResponsiveTypography>
                  </Box>
                  <Box display="flex" flexDirection="row" pl={1.5}>
                    <Box>
                      <img src={curveimg} alt="curve" height={55} />
                    </Box>
                    <Box ml={1}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Time of Access Configuration
                        </ResponsiveTypography>
                      </Box>
                      <Box
                        m={0}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          User Session Configuration
                        </ResponsiveTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={restrictionBoxStyles(selectedRestriction === 'file')}
                    onClick={handleRestrictionClick(
                      'file',
                      importExportRestrictionRef,
                    )}
                  >
                    <DescriptionOutlinedIcon
                      sx={{ width: { xl: 20, lg: 15 }, color: '#0065FF' }}
                    />
                    &nbsp;&nbsp;
                    <ResponsiveTypography
                      sx={{ cursor: 'pointer', color: '#0065FF' }}
                    >
                      Restrict Import/Export of Files
                    </ResponsiveTypography>
                  </Box>
                  <Box display="flex" flexDirection="row" pl={1.5}>
                    <Box>
                      <img src={curveimg} alt="curve" height={55} />
                    </Box>
                    <Box ml={1}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => {
                          setSelectedRestriction('import-export');
                          importExportRestrictionRef.current?.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Prevent Download
                        </ResponsiveTypography>
                      </Box>
                      <Box
                        m={0}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Restrict Import of Files
                        </ResponsiveTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={restrictionBoxStyles(
                      selectedRestriction === 'clipboard',
                    )}
                    onClick={handleRestrictionClick(
                      'clipboard',
                      importExportRestrictionRef,
                    )}
                  >
                    <ContentPasteIcon
                      sx={{
                        width: { xl: 20, lg: 15 },
                        color: '#0065FF',
                        cursor: 'pointer',
                      }}
                    />
                    &nbsp;&nbsp;
                    <ResponsiveTypography
                      sx={{ cursor: 'pointer', color: '#0065FF' }}
                    >
                      Clipboard Restriction
                    </ResponsiveTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="1rem">
          <Button
            variant="text"
            sx={{ ...lightBackgroundButtonStyles, width: { xl: '5rem' } }}
            onClick={() => dispatch(updateOpen(false))}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            sx={cyanBlueBackgroundButtonStyles}
            onClick={updatePolicy}
          >
            Save & Next
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
