import React from 'react';
import { Box } from '@mui/material';
import NoRecordsFoundImg from '../assets/images/norecordsfound.svg';
import { ResponsiveTypography } from '../styles/inputLabel';

function NoRecordsFound() {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        component="img"
        src={NoRecordsFoundImg}
        alt="No Records Found"
        sx={{
          width: {
            xs: '10vh',
            md: '20vh',
            lg: '30vh',
            xl: '40vh',
          },
          height: {
            xs: '10vh',
            md: '30vh',
            lg: '30vh',
            xl: '28vh',
          },
        }}
      />
      <ResponsiveTypography variant="body1" mt="1rem">
        No Records Found.
      </ResponsiveTypography>
    </Box>
  );
}
export default NoRecordsFound;

// maxHeight: { md: '18rem', lg: '23rem', xl: '30rem' },
