import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Grid, Fade, MenuItem,
} from '@mui/material';
import { RootState } from '../../../../../../Redux/store';
import ShopifyPlusBasicSettings from '../../../../Configuration/BasicSettings/Custom/ShopifyPlus/basicSettings';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';

function EditShopifyPlusBasicSettings() {
  const { editScreen, authenticationID } = useSelector(
    (state: RootState) => state.application,
  );
  const { configuredAuthenticationSource } = useSelector(
    (state: RootState) => state.authenticationSource,
  );
  return (
    <Fade
      in={editScreen === 'BasicSettings'}
      {...(editScreen === 'BasicSettings' ? { timeout: 1000 } : {})}
    >
      <Box sx={{ paddingTop: { xl: 3, md: 2 } }}>
        <Grid
          container
          sx={{ rowGap: { xl: 2, lg: 1 }, paddingBottom: { xl: 2, lg: 1 } }}
        >
          <Grid item xs={5}>
            <ResponsiveTypography>Authentication Source</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="1rem"
              width="100%"
            >
              <StyledSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                name="authentication_id"
                label=""
                value={authenticationID}
                style={{ flex: 1 }}
              >
                {configuredAuthenticationSource?.map((source) => (
                  <MenuItem key={source?._id} value={source?._id}>
                    {source.identifier}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          </Grid>
        </Grid>
        <ShopifyPlusBasicSettings />
      </Box>
    </Fade>
  );
}

export default EditShopifyPlusBasicSettings;
