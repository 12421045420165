import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Checkbox,
  Pagination,
} from '@mui/material';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { ShimmerTable } from 'react-shimmer-effects';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SPMetadataModal from '../../components/CASBApp/AuthenticationSource/SPMetadataModal';
import { RootState, AppDispatch } from '../../Redux/store';
import MainLayout from '../../components/MainLayout';
import NoRecordsFound from '../../components/NoRecordsFound';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../styles/button';
import { StyledSelect, menuItemCSS } from '../../styles/select';
import {
  fetchAllAuthentications,
  addNewAuthenticationSource,
  updateAuthenticationSource,
  updateOpenDrawer,
} from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';
import { deleteBulkAuthenticationSource } from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceHandler';
import { SubTitle } from '../../styles/subtitle';
import { buttonIconStyles, smallIconStyles } from '../../styles/icon';
import { ResponsiveHeading } from '../../styles/heading';
import { StyledTextField } from '../../styles/textField';
import AuthenticationSourceConfiguration from './AddAuthenticationSource';
import { getUser } from '../../utils/utility';
import { handleSearch } from '../../utils/Groups/groupUtility';
import ConfirmDeleteDialog from '../../components/deleteDialog';
import { ResponsiveTypography } from '../../styles/inputLabel';

const tableCellStyle = {
  borderBottom: '0.063rem solid #D9D9D9',
  paddingTop: 0.8,
  paddingBottom: 0,
  fontSize: { xl: '0.7rem', md: '0.6rem' },
  color: '#6B778C',
};

const tableRowCellStyle = {
  borderBottom: 'none',
  paddingTop: 0.8,
  paddingBottom: 0,
  color: '#172B4D',
  fontSize: { xl: '0.8rem', md: '0.7rem' },
};

function AuthenticationSourceView() {
  const dispatch = useDispatch<AppDispatch>();
  const [dialog, setDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [viewMetadata, setViewMetadata] = useState(false);
  const [bulkAction, setBulkAction] = useState('bulk');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(7);
  const [selectedAuthenticationIds, setSelectedAuthenticationIds] = useState<
    string[]
  >([]);
  const { configuredAuthenticationSource, status } = useSelector(
    (state: RootState) => state.authenticationSource,
  );
  const [
    filteredAuthenticationSource,
    setFilteredAuthenticationSource,
  ] = useState(configuredAuthenticationSource);

  useEffect(() => {
    setFilteredAuthenticationSource(configuredAuthenticationSource);
  }, [configuredAuthenticationSource]);

  const handleSelectAuthentication = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    const selectedIndex = selectedAuthenticationIds.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAuthenticationIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAuthenticationIds.slice(1));
    } else if (selectedIndex === selectedAuthenticationIds.length - 1) {
      newSelected = newSelected.concat(selectedAuthenticationIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAuthenticationIds.slice(0, selectedIndex),
        selectedAuthenticationIds.slice(selectedIndex + 1),
      );
    }
    setSelectedAuthenticationIds(newSelected);
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    sourceId: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(sourceId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentId(null);
  };

  useEffect(() => {
    dispatch(fetchAllAuthentications());
  }, []);

  const handleEditAuthenticationSource = (id: any) => {
    configuredAuthenticationSource.forEach(async (element: any) => {
      if (element._id === id) {
        await dispatch(updateAuthenticationSource(element));
        dispatch(updateOpenDrawer(true));
      }
    });
    handleMenuClose();
  };

  const configureNewAuthenticationSource = async () => {
    await dispatch(addNewAuthenticationSource());
    dispatch(updateOpenDrawer(true));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    newPage -= 1;
    setPage(newPage);
  };

  const applyBulkAction = async () => {
    if (bulkAction === 'delete') {
      await dispatch(deleteBulkAuthenticationSource(selectedAuthenticationIds));
    }
  };

  const handleViewMetadata = (id: string) => {
    configuredAuthenticationSource.forEach(async (element: any) => {
      if (element._id === id) {
        await dispatch(updateAuthenticationSource(element));
        setViewMetadata(true);
      }
    });
    handleMenuClose();
  };

  const deleteAuthenticationSourceHandler = (id: string) => {
    setIdToDelete(id);
    setDialog(true);
    setAnchorEl(null);
  };

  const copyMetadataURL = async (id: string) => {
    const user = await getUser();
    const url = `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user?.current_active_tenant_id}/${id}/generate-sp-metadata`;
    navigator.clipboard.writeText(url);
    toast.success('Copied Metadata URL');
  };

  const pageCount = Math.ceil(
    filteredAuthenticationSource.length / rowsPerPage,
  );

  return (
    <MainLayout>
      <Box
        p={{ md: '0.5rem', lg: '2rem', xl: '2rem' }}
        width="98%"
        height="100%"
      >
        <ConfirmDeleteDialog
          open={dialog}
          setOpen={setDialog}
          action={idToDelete}
        />
        <SPMetadataModal open={viewMetadata} setOpen={setViewMetadata} />
        <AuthenticationSourceConfiguration />
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <ResponsiveHeading>Authentication Source</ResponsiveHeading>
          <SubTitle>
            Configure authentication source for your applications here.
          </SubTitle>
        </Box>
        <Box
          mt={{ md: '0.5rem', lg: '2.5rem', xl: '2.5rem' }}
          display="flex"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            border="0.063rem solid #EBEBEB"
            p="1rem"
            borderRadius="0.625rem"
            height={{ lg: '25rem', xl: '30rem', md: '21rem' }}
            // height={{ xl: '60vh', lg: '55vh', md: '45vh' }}
          >
            <Box display="flex" justifyContent="space-between">
              <StyledTextField
                id="input-with-icon-textfield"
                size="small"
                variant="outlined"
                placeholder="Search by Identifier"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={buttonIconStyles} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: { md: '11rem', lg: '13rem', xl: '13rem' },
                }}
                onChange={(event: any) => handleSearch(
                  event,
                  configuredAuthenticationSource,
                  filteredAuthenticationSource,
                  setFilteredAuthenticationSource,
                )}
              />
              <Box display="flex" gap="0.5rem" alignItems="center">
                <StyledSelect
                  sx={{ width: { xl: '8rem', md: '6rem' } }}
                  value={bulkAction}
                  onChange={(event: any) => setBulkAction(event?.target.value)}
                >
                  <MenuItem value="bulk" sx={menuItemCSS}>
                    Bulk Action
                  </MenuItem>
                  <MenuItem value="delete" sx={menuItemCSS}>
                    Delete
                  </MenuItem>
                </StyledSelect>
                <Button
                  sx={{
                    ...lightBackgroundButtonStyles,
                    width: { xl: '6rem', md: '4rem' },
                  }}
                  onClick={applyBulkAction}
                >
                  Apply
                </Button>
                <Button
                  sx={{
                    ...cyanBlueBackgroundButtonStyles,
                    width: { xl: '8rem', md: '6rem' },
                  }}
                  onClick={configureNewAuthenticationSource}
                >
                  <AddIcon sx={{ ...buttonIconStyles, color: 'white' }} />
                  &nbsp; Add New
                </Button>
              </Box>
            </Box>
            {status === 'idle' && <ShimmerTable row={5} col={4} />}
            {status === 'succeeded' && (
              <TableContainer
                sx={{
                  border: 'none',
                  boxShadow: 'none',
                  maxHeight: { md: '19rem', lg: '25rem', xl: '25rem' },
                }}
              >
                <Table sx={{ border: 'none', boxShadow: 'none' }}>
                  <TableHead
                    sx={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: 'white',
                      zIndex: 1,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        align="center"
                        sx={{ ...tableCellStyle, align: 'center' }}
                      >
                        <Checkbox
                          size="small"
                          color="primary"
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedAuthenticationIds(
                                configuredAuthenticationSource.map(
                                  (source: any) => source._id,
                                ),
                              );
                            } else {
                              setSelectedAuthenticationIds([]);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyle}>Identifiers</TableCell>
                      <TableCell sx={tableCellStyle}>
                        Authentication Type
                      </TableCell>
                      <TableCell align="right" sx={tableCellStyle}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {filteredAuthenticationSource.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <NoRecordsFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableBody>
                    {filteredAuthenticationSource.length > 0
                      && filteredAuthenticationSource
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((source: any) => (
                          <TableRow
                            key={source._id}
                            sx={{
                              padding: {
                                xl: '1rem !important',
                                lg: '0.625rem !important',
                              },
                            }}
                          >
                            <TableCell sx={tableRowCellStyle}>
                              <Checkbox
                                size="small"
                                color="primary"
                                checked={
                                  selectedAuthenticationIds.indexOf(
                                    source._id,
                                  ) !== -1
                                }
                                onChange={(event) => handleSelectAuthentication(event, source._id)}
                              />
                            </TableCell>
                            <TableCell sx={tableRowCellStyle}>
                              {source.identifier}
                            </TableCell>
                            <TableCell sx={tableRowCellStyle}>SAML</TableCell>
                            <TableCell align="right" sx={tableRowCellStyle}>
                              <IconButton
                                onClick={(event) => handleMenuOpen(event, source._id)}
                              >
                                <MoreVertIcon sx={smallIconStyles} />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={
                                  Boolean(anchorEl) && currentId === source._id
                                }
                                onClose={handleMenuClose}
                                PaperProps={{
                                  style: {
                                    boxShadow: 'none',
                                    border: '0.063rem solid #E3E3E3',
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => copyMetadataURL(source._id)}
                                  sx={menuItemCSS}
                                >
                                  Metadata URL
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleViewMetadata(source._id)}
                                  sx={menuItemCSS}
                                >
                                  View SP Metadata
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleEditAuthenticationSource(source._id)}
                                  sx={menuItemCSS}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => deleteAuthenticationSourceHandler(
                                    source._id,
                                  )}
                                  sx={menuItemCSS}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                {configuredAuthenticationSource.length > 0 && (
                  <Pagination
                    size="small"
                    count={pageCount > 0 ? pageCount : 1}
                    page={page + 1}
                    onChange={handleChangePage}
                    color="primary"
                    sx={{
                      '& .MuiPaginationItem-root': {
                        fontSize: { xl: '0.938rem', lg: '0.625rem' },
                      },
                      paddingTop: '1rem',
                    }}
                  />
                )}
              </TableContainer>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            borderBottom="0.063rem solid #E3E3E3"
            py="1rem"
            px={{ lg: '2.5rem', xl: '2.5rem', md: '0.5rem' }}
          >
            <ResponsiveTypography>Guide</ResponsiveTypography>
            <Box display="flex" flexDirection="row" gap="1rem">
              <Button
                startIcon={
                  <YouTubeIcon sx={{ ...buttonIconStyles, color: 'red' }} />
                }
                sx={{
                  ...lightBackgroundButtonStyles,
                  width: {
                    xxl: '12rem !important',
                    xl: '10rem !important',
                    lg: '8rem !important',
                    md: '6rem !important',
                  },
                }}
              >
                Video Guide
              </Button>
              <Button
                sx={{
                  ...lightBackgroundButtonStyles,
                  width: {
                    xxl: '12rem !important',
                    xl: '10rem !important',
                    lg: '8rem !important',
                    md: '6rem !important',
                  },
                }}
                startIcon={
                  <ArticleIcon sx={{ ...buttonIconStyles, color: 'blue' }} />
                }
              >
                Setup Guide
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default AuthenticationSourceView;
