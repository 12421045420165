import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getCookie } from '../../../utils/utility';

export const submitCustomerSupportForm = createAsyncThunk(
  'casb-extension/submitCustomerSupportForm',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    const email = state?.customerSupport?.email;
    const message = state?.customerSupport?.message;

    if (!email || !message) {
      toast.error('Please add all the details');
      return false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error('Invalid email address');
      return false;
    }

    try {
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/customer-support-query`,
          {
            email,
            message,
            url: state?.customerSupport?.url,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          toast.success('We will get back to you soon !');
        });
      return true;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);
