import React from 'react';
import {
  Box, Drawer, Grid, TextField, Radio, Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateAuthenticationName,
  updateIssuer,
  updateLoginURL,
  updateLogoutURL,
  updateX509Certificate,
  updateBindingType,
  updateOpenDrawer,
} from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';
import { configureAuthenticationSource } from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceHandler';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { StyledTextField } from '../../styles/textField';
import { ResponsiveHeading } from '../../styles/heading';
import { SubLabel } from '../../styles/sublabel';
import AuthenticationSourceInformation from '../Application/Configuration/AuthenticationSource/info&links';
import { cyanBlueBackgroundButtonStyles } from '../../styles/button';
import { buttonIconStyles } from '../../styles/icon';
import Cancel from '../../components/cancelButton';

function AuthenticationSourceConfiguration() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    issuer,
    loginURL,
    logoutURL,
    X509Certificate,
    bindingType,
    drawer,
  } = useSelector((state: RootState) => state.authenticationSource);
  const updateApplicationAuthentication = () => {
    dispatch(configureAuthenticationSource());
  };
  return (
    <Drawer
      open={drawer}
      onClose={() => dispatch(updateOpenDrawer(false))}
      anchor="right"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="70vw"
        px={{ lg: '2.5rem', xl: '2.5rem', md: '1rem' }}
        py={{ lg: '1.5rem', xl: '1.5rem', md: '0rem' }}
        gap="1.5rem"
        overflow="auto"
      >
        <Box
          sx={{
            position: 'sticky',
            zIndex: 1,
            top: '0rem',
            backgroundColor: 'white',
          }}
        >
          <ResponsiveHeading>Configure Authentication Source</ResponsiveHeading>
          <SubLabel>Please add details for authentication source</SubLabel>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box pr="1.5rem" py="0.5rem">
            <Grid
              container
              sx={{ rowGap: { xl: '1.5rem', lg: '1rem', md: '0.5rem' } }}
            >
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  Application Name
                </ResponsiveTypography>
              </Grid>

              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="identifier"
                  value={identifier || ''}
                  onChange={(e: any) => dispatch(updateAuthenticationName(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  IdP Entity ID or Issuer
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="issuer"
                  value={issuer || ''}
                  onChange={(e: any) => dispatch(updateIssuer(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  SAML Login URL
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="loginURL"
                  value={loginURL || ''}
                  onChange={(e: any) => dispatch(updateLoginURL(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  SAML Logout URL
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="logoutURL"
                  value={logoutURL || ''}
                  onChange={(e: any) => dispatch(updateLogoutURL(e.target.value))}
                />
              </Grid>
              <Grid item xs={5} sx={{ paddingTop: { md: '0.6rem' } }}>
                <ResponsiveTypography variant="subtitle2">
                  Select Binding Type for SSO Request
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="bindingType"
                  value={bindingType || ''}
                  onChange={(event: any) => dispatch(updateBindingType(event.target.value))}
                  sx={{ py: { xl: '0rem', lg: '0rem' } }}
                >
                  <FormControlLabel
                    value="HttpRedirect"
                    control={<Radio size="small" />}
                    label="HTTP-Redirect Binding"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: {
                          xl: '0.813rem !important',
                          lg: '0.688rem !important',
                          md: '0.313rem !important',
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value="HttpPost"
                    control={<Radio size="small" />}
                    label="HTTP-Post Binding"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: {
                          xl: '0.813rem !important',
                          lg: '0.688rem !important',
                          md: '0.313rem !important',
                        },
                      },
                    }}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  X.509 Certificate
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  multiline
                  name="X509Certificate"
                  rows={8}
                  maxRows={8}
                  value={X509Certificate || ''}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: {
                        xl: '0.938rem !important',
                        lg: '0.625rem !important',
                        md: '0.625rem !important',
                      },
                      height: { xl: '13rem !important', lg: '7rem !important' },
                      padding: { lg: '0.25rem 0.438rem !important' },
                    },
                  }}
                  onChange={(e: any) => dispatch(updateX509Certificate(e.target.value))}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="40%">
            <AuthenticationSourceInformation />
          </Box>
        </Box>
        <Box display="flex" width="100%" mb="2.5rem" gap="1rem">
          <Cancel />
          <Button
            variant="text"
            sx={cyanBlueBackgroundButtonStyles}
            onClick={updateApplicationAuthentication}
          >
            Save and Next&nbsp;&nbsp;
            <ArrowForwardOutlinedIcon
              sx={{ ...buttonIconStyles, color: 'white' }}
            />
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default AuthenticationSourceConfiguration;
