import React from 'react';
import { Box } from '@mui/material';
import Application from '../../Application/application';

function GitHub() {
  return (
    <Box>
      <Application app={process.env.REACT_APP_GITHUB_KEY!} />
    </Box>
  );
}

export default GitHub;
