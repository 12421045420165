import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getCookie } from '../../../utils/utility';

export const submitFeedback = createAsyncThunk(
  'casb-extension/submitFeedback',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    if (
      !state?.extensionFeedback?.email
      || !state?.extensionFeedback?.name
      || !state?.extensionFeedback?.query
    ) {
      toast.error('Please add all the details');
      return false;
    }
    try {
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/casb-extension/feedback`,
          {
            email: state?.extensionFeedback?.email,
            username: state?.extensionFeedback?.name,
            category: state?.extensionFeedback?.query,
            message: state?.extensionFeedback?.description,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          toast.success('We appreciate your feedback !');
          if (window.opener) {
            window.close();
          } else {
            window.location.href = 'about:blank';
            window.open('', '_self')?.close();
          }
        });
      return true;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);
