import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }: any) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '1.5rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.5rem !important',
      padding: '0.313rem 0.438rem !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: '1.85rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.625rem !important',
      padding: '0.313rem 0.438rem !important',
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: '2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.813rem !important',
      padding: '0.344rem 0.875rem !important',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    height: '2.2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.938rem !important',
      padding: '0.344rem 0.875rem !important',
    },
  },
}));

export const StyledTextField1 = styled(TextField)(({ theme }: any) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '1.5rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.5rem !important',
      padding: '0.313rem 0.438rem !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: '1.85rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.625rem !important',
      padding: '3.125 3.125 !important',
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: '2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.813rem !important',
      padding: '3.125rem 3.125rem !important',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    height: '2.2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.938rem !important',
      padding: '0.75rem 0.75rem !important',
      backgroundColor: '#FAFBFC',
      border: '0.188rem solid #DFE1E6',
    },
  },
}));
