import React from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForward';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProxyName,
  updateProxyLink,
  updatePrimaryServerURL,
  updateEnableByIP,
  resetProxySettings,
} from '../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../Redux/store';
import { isValidPath, isValidURL } from '../../../utils/utility';
import { StyledTextField } from '../../../styles/textField';
import { ResponsiveTypography } from '../../../styles/inputLabel';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../styles/button';

interface ProxyDetailsProps {
  setStep: Function;
}

function ProxyDetails({ setStep }: ProxyDetailsProps) {
  const dispatch = useDispatch();
  const proxySettings = useSelector((state: RootState) => state.proxySettings);
  const navigate = useNavigate();
  const saveProxyDetails = () => {
    if (
      !proxySettings.proxyName
      || !proxySettings.proxyLink
      || !proxySettings.primaryServerURL
    ) {
      toast.error('Missing Required Fields');
    } else if (!isValidPath(proxySettings.proxyLink)) {
      toast.error('Invalid Proxy Path please enter a valid Path.');
    } else if (!isValidURL(proxySettings.primaryServerURL)) {
      toast.error('Invalid Server URL please enter a valid URL.');
    } else {
      setStep(2);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        display="flex"
        width="auto"
        px="1rem"
        py="1rem"
        sx={{ backgroundColor: '#ffeef0' }}
      >
        <ResponsiveTypography variant="body2" color="black">
          Configure&nbsp;&nbsp;
          <Link to="/settings">Virtual Hosting</Link>
          &nbsp;&nbsp;from Tenant Setting tab before Configuring Proxy
        </ResponsiveTypography>
      </Box>
      <Grid container spacing="1rem" sx={{ padding: '1.5rem' }}>
        <Grid item xs={4}>
          <ResponsiveTypography>Proxy Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={8}>
          <StyledTextField
            required
            id="outlined-basic"
            size="small"
            name="proxyName"
            variant="outlined"
            placeholder="Enter Proxy Name"
            onChange={(event: any) => dispatch(updateProxyName(event.target.value))}
            value={proxySettings.proxyName || ''}
            sx={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={4}>
          <ResponsiveTypography>Proxy Path</ResponsiveTypography>
        </Grid>
        <Grid item xs={8}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            name="proxyLink"
            placeholder="Enter Proxy Path"
            variant="outlined"
            onChange={(event: any) => dispatch(updateProxyLink(event.target.value))}
            value={proxySettings.proxyLink || ''}
            sx={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={4}>
          <ResponsiveTypography>Origin Server URL</ResponsiveTypography>
        </Grid>
        <Grid item xs={8}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            name="primaryServerURL"
            placeholder="Enter Original Server URL"
            variant="outlined"
            onChange={(event: any) => dispatch(updatePrimaryServerURL(event.target.value))}
            value={proxySettings.primaryServerURL || ''}
            sx={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={4}>
          <ResponsiveTypography>Enable by IP</ResponsiveTypography>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <FormControlLabel
              control={(
                <Checkbox
                  name="enableByIP"
                  checked={proxySettings.enableByIP}
                  onChange={(event: any) => dispatch(updateEnableByIP(event.target.checked))}
                  color="primary"
                  size="small"
                />
              )}
              label=""
            />
            <ResponsiveTypography variant="caption">
              Enable it, if you want to make the proxy using IP or want to skip
              certificate verification for the origin Server.
            </ResponsiveTypography>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="left"
        pl="1rem"
        sx={{ gap: '1rem' }}
      >
        <Button
          variant="text"
          onClick={() => {
            dispatch(resetProxySettings());
            navigate('/configure/proxy');
          }}
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
          startIcon={<DeleteIcon />}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={saveProxyDetails}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon />
        </Button>
      </Box>
    </Box>
  );
}

export default ProxyDetails;
