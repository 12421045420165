import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllPolicies } from './policyHandler';

const initialState = {
  status: 'idle',
  allPolicies: [] as any,
  identifier: '',
  description: '',
  enableIPRestriction: false,
  IPAddressToggle: 'allow',
  ipAddresses: [''] as string[],
  enableTimeRestriction: false,
  timeConstraintToggle: 'allow',
  timezone: null,
  startTime: '',
  endTime: '',
  enablePreventDownload: false,
  enableLocationBasedRestriction: false,
  location: [],
  locationRestrictionToggle: 'allow',
  sessionRestrictionToggle: 'd',
  enableFileBasedRestriction: false,
  enableSessionBasedRestriction: false,
  files: [],
  fileRestrictionToggle: 'allow',
  enableClipboardRestriction: false,
  clipboardRestrictions: [],
  sessionDuration: '',
  _id: '',
  open: false,
  state: 'save',
};

const policySettings = createSlice({
  name: 'policySettings',
  initialState,
  reducers: {
    updateIdentifier: (state, action: PayloadAction<string>) => {
      state.identifier = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    updateEnableIPRestriction: (state, action: PayloadAction<boolean>) => {
      state.enableIPRestriction = action.payload;
    },
    updateIPToggle: (state, action: PayloadAction<string>) => {
      state.IPAddressToggle = action.payload;
    },
    updateEnableTimeRestriction: (state, action: PayloadAction<boolean>) => {
      state.enableTimeRestriction = action.payload;
    },
    updateTimeToggle: (state, action: PayloadAction<string>) => {
      state.timeConstraintToggle = action.payload;
    },
    updateEnablePreventDownload: (state, action: PayloadAction<boolean>) => {
      state.enablePreventDownload = Boolean(action.payload);
    },
    updateEnableDisableClipboard: (state, action: PayloadAction<boolean>) => {
      state.enableClipboardRestriction = Boolean(action.payload);
    },
    updateClipboardRestrictions: (state, action: PayloadAction<any>) => {
      state.clipboardRestrictions = action.payload;
    },
    updateLocationBasedrestriction: (state, action: PayloadAction<boolean>) => {
      state.enableLocationBasedRestriction = Boolean(action.payload);
    },
    updateFileBasedrestriction: (state, action: PayloadAction<boolean>) => {
      state.enableFileBasedRestriction = Boolean(action.payload);
    },
    updateSessionBasedrestriction: (state, action: PayloadAction<boolean>) => {
      state.enableSessionBasedRestriction = Boolean(action.payload);
    },
    updateIPAddressList: (state, action: PayloadAction<any>) => {
      state.ipAddresses = action.payload;
    },
    updateTimeZone: (state, action: PayloadAction<any>) => {
      state.timezone = action.payload;
    },
    updateStartTime: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    updateEndTime: (state, action: PayloadAction<string>) => {
      state.endTime = action.payload;
    },
    updateLocationToggle: (state, action: PayloadAction<string>) => {
      state.locationRestrictionToggle = action.payload;
    },
    updateSessionToggle: (state, action: PayloadAction<string>) => {
      state.sessionRestrictionToggle = action.payload;
    },
    updateSessionDuration: (state, action: PayloadAction<string>) => {
      state.sessionDuration = action.payload;
    },
    updateFileToggle: (state, action: PayloadAction<string>) => {
      state.fileRestrictionToggle = action.payload;
    },
    updateLocationList: (state, action: PayloadAction<any>) => {
      state.location = action.payload;
    },
    updateFileList: (state, action: PayloadAction<any>) => {
      state.files = action.payload;
    },
    removeIP: (state, action: PayloadAction<number>) => {
      state.ipAddresses.splice(action.payload, 1);
    },
    updateIPList: (state, action: PayloadAction<any>) => {
      state.ipAddresses[action.payload.index] = action.payload.value;
    },
    addIPAddress: (state) => {
      state.ipAddresses = [...state.ipAddresses, ''];
    },
    updateOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    updatePolicyState: (state, action: PayloadAction<any>) => {
      state.identifier = action.payload.identifier;
      state.description = action.payload.description;
      state.enableIPRestriction = action.payload.ip_based_restriction_feature.enable;
      state.IPAddressToggle = action.payload.ip_based_restriction_feature.access_control;
      state.ipAddresses = action.payload.ip_based_restriction_feature.ip_address_list;
      state.enableTimeRestriction = action.payload.time_based_restriction_feature.enable;
      state.timeConstraintToggle = action.payload.time_based_restriction_feature.access_control;
      state.timezone = action.payload.time_based_restriction_feature.time_zone;
      state.startTime = action.payload.time_based_restriction_feature.start_time;
      state.endTime = action.payload.time_based_restriction_feature.end_time;
      state.enablePreventDownload = action.payload.file_restriction_feature.enable;
      state.enableLocationBasedRestriction = action.payload.location_based_restriction.enable;
      state.location = action.payload.location_based_restriction.location_list;
      state.locationRestrictionToggle = action.payload.location_based_restriction.access_control;
      state.sessionRestrictionToggle = action.payload.session_based_restriction?.access_control;
      state.enableFileBasedRestriction = action.payload.file_based_restriction.enable;
      state.enableSessionBasedRestriction = action.payload.session_based_restriction?.enable;
      state.sessionDuration = action.payload.session_based_restriction?.session_duration;
      state.files = action.payload.file_based_restriction.file_list;
      state.fileRestrictionToggle = action.payload.file_based_restriction.access_control;
      state.enableClipboardRestriction = action.payload.clipboard_restriction_feature.enable;
      state.clipboardRestrictions = action.payload.clipboard_restriction_feature.clipboard_restriction;
      state._id = action.payload._id;
      state.state = 'update';
    },
    resetPolicyDetails: (state) => {
      state.identifier = '';
      state.description = '';
      state.enableIPRestriction = false;
      state.IPAddressToggle = 'allow';
      state.ipAddresses = [];
      state.enableTimeRestriction = false;
      state.timeConstraintToggle = 'allow';
      state.timezone = null;
      state.startTime = '';
      state.endTime = '';
      state.enablePreventDownload = false;
      state.enableLocationBasedRestriction = false;
      state.location = [];
      state.locationRestrictionToggle = 'allow';
      state.sessionRestrictionToggle = 'd';
      state.sessionDuration = '';
      state.enableFileBasedRestriction = false;
      state.enableSessionBasedRestriction = false;
      state.files = [];
      state.fileRestrictionToggle = 'allow';
      state.enableClipboardRestriction = false;
      state.clipboardRestrictions = [];
      state._id = '';
      state.state = 'save';
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllPolicies.fulfilled, (state, action) => {
      state.allPolicies = action.payload;
      state.status = 'succeeded';
    });
  },
});

export const {
  updateIdentifier,
  updateDescription,
  updateEnableIPRestriction,
  updateEnableTimeRestriction,
  updateEnablePreventDownload,
  updateFileBasedrestriction,
  updateSessionBasedrestriction,
  updateEnableDisableClipboard,
  updateClipboardRestrictions,
  updateLocationBasedrestriction,
  updateIPToggle,
  updateTimeToggle,
  updateIPAddressList,
  updateTimeZone,
  updateStartTime,
  updateEndTime,
  updateLocationToggle,
  updateSessionToggle,
  updateSessionDuration,
  updateLocationList,
  updateFileList,
  resetPolicyDetails,
  updateFileToggle,
  updatePolicyState,
  removeIP,
  updateIPList,
  addIPAddress,
  updateOpen,
} = policySettings.actions;
export default policySettings;
