import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axios from 'axios';
import {
  isValidIPAddress,
  getCookie,
  isValidSessionDuration,
} from '../../../utils/utility';
import { updateOpen } from './policy';

interface PolicyData {
  policy_details: any;
  type: any;
  _id?: string;
}

export const getAllPolicies = createAsyncThunk(
  'getAllPolicies',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      );

      if (response && response.data && response.data.PolicyData) {
        return response.data.PolicyData;
      }
      return [];
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const deletePolicy = createAsyncThunk(
  'policies/deletePolicy',
  async (_id: string, { rejectWithValue, dispatch }) => {
    const token = await getCookie('token');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-policy-settings`,
        { _id },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      if (response.status === 200) {
        toast.success('Policy deleted successfully');
        await dispatch(getAllPolicies());
        return { _id };
      }

      return rejectWithValue('Failed to delete policy');
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessage = err.response.data.errors[0].message;
        toast.error(errorMessage);
        return rejectWithValue(errorMessage);
      }

      toast.error('Failed to delete policy');
      return rejectWithValue(err.message);
    }
  },
);

export const configureNewPolicySettings = createAsyncThunk(
  'configureNewPolicy',
  async (_, thunkAPI) => {
    var hasInvalidIP = false;
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    if (!state.policySettings.identifier || !state.policySettings.description) {
      toast.error('Please add necessary details.');
    } else if (state.policySettings?.enableIPRestriction) {
      hasInvalidIP = state.policySettings?.ipAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          return true;
        }
        return false;
      });
    }
    if (state.policySettings?.enableSessionBasedRestriction) {
      if (
        !state.policySettings.sessionDuration
        || !isValidSessionDuration(state.policySettings.sessionDuration)
      ) {
        toast.error('Invalid Session Duration');
        return;
      }
    }
    if (hasInvalidIP) {
      toast.error('Invalid IP Address');
      return;
    }
    const policyData: PolicyData = {
      policy_details: state.policySettings,
      type: state.policySettings.state,
    };

    if (state.policySettings._id) {
      policyData._id = state.policySettings._id;
    }

    await axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        policyData,
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(async () => {
        await thunkAPI.dispatch(getAllPolicies());
        await thunkAPI.dispatch(updateOpen(false));
        toast.success('Policy has been Configured.');
        return;
      })
      .catch((error: any) => {
        if (error) {
          toast.error(error.response.data.message);
        }
      });
  },
);
