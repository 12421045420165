import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getCookie, getDraftProxyApplication } from '../../../utils/utility';
import { setSessionDetails } from './appSlice';

export const getApplicationStatus = createAsyncThunk(
  'getApplicationStatus',
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState();
    try {
      const response = await getDraftProxyApplication(state.application.app);
      return response;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const getSessionDetails = createAsyncThunk(
  'getSessionDetails',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    // const state: any = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-session-details`,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      );
      await thunkAPI.dispatch(setSessionDetails(response.data));
      return true;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const downloadFederationScript = createAsyncThunk(
  'office365/downloadFederationScript',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    if (!state?.basicSettings?.domain) {
      toast.error('Please enter a valid domain.');
      return false;
    }
    try {
      const logoutURL = state.application?.idpMetadata?.login_url.replace(
        'login',
        'logout',
      );
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-federation-script`,
          {
            entity_id: state.application?.idpMetadata?.entity_id,
            login_url: state.application?.idpMetadata?.login_url,
            logout_url: logoutURL,
            x_509_certificate:
              state.application?.idpMetadata?.x_509_certificate,
            domain: state?.basicSettings?.domain,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async (response) => {
          const content = await response.data;
          const blob = new Blob([content], { type: 'text/plain' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${state?.basicSettings?.domain}.ps1`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      return true;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);
