import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Card,
  CardContent,
  MenuItem,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AppsIcon from '@mui/icons-material/Apps';
import { AppDispatch, RootState } from '../../../../../../Redux/store';
import { SmallHeading } from '../../../../../../styles/smallHeading';
import {
  removeApp,
  updateAppAllowedList,
  updateAppBlockedList,
  updateAppRestrictionList,
  updateCustomRestrictionList,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { smallIconStyles } from '../../../../../../styles/icon';
import { StyledSelect } from '../../../../../../styles/select';
import {
  determineAppPolicyID,
  handleAppCustomRestrictionPolicyChange,
} from '../../../../../../utils/Groups/groupUtility';
import { SubLabel } from '../../../../../../styles/sublabel';

function ManageRestriction() {
  const dispatch = useDispatch<AppDispatch>();
  const { isConfigured } = useSelector((state: RootState) => state.application);
  const {
    apps,
    restrictedApps,
    allowedApps,
    blockApps,
    defaultPolicyID,
    customRestrictionApps,
    allPolicies,
  } = useSelector((state: RootState) => state.groupSettings);
  const [selectedApps, setSelectedApps] = useState<any[]>([]);
  const restrictionBoxRef = useRef<HTMLDivElement>(null);

  const handleSelectApp = (app: any) => {
    setSelectedApps((prev) => {
      const isAppSelected = prev.some(
        (selectedApp) => selectedApp.appKey === app.appKey,
      );

      return isAppSelected
        ? prev.filter((selectedApp) => selectedApp.appKey !== app.appKey)
        : [...prev, app];
    });
  };

  const handleDragEnd = async (result: any) => {
    const { destination, draggableId } = result;
    if (!destination) {
      return;
    }

    const draggedApps = selectedApps.length
      ? selectedApps
      : [apps?.find((app) => app.appKey === draggableId)];

    draggedApps.forEach(async (app) => {
      await dispatch(removeApp(app.appKey));
      switch (destination.droppableId) {
        case 'App Restriction':
          dispatch(updateAppRestrictionList(app.appKey));
          break;
        case 'No App Restrictions':
          dispatch(updateAppAllowedList(app.appKey));
          break;
        case 'Disable Apps':
          dispatch(updateAppBlockedList(app.appKey));
          break;
        case 'Custom Restrictions':
          dispatch(updateCustomRestrictionList(app.appKey));
          break;
        default:
          break;
      }
    });

    setSelectedApps([]);
  };

  const handleDragStart = (start: any) => {
    const draggedApp = apps?.find((app) => app.appKey === start.draggableId);
    if (
      !selectedApps.some(
        (selectedApp) => selectedApp.appKey === draggedApp.appKey,
      )
    ) {
      setSelectedApps([draggedApp]);
    }
  };

  const renderDraggingUI = () => (
    <Box
      sx={{
        width: '100% !important',
        justifyContent: 'flex-start !important',
        py: '1rem',
        px: '1rem',
      }}
    >
      {selectedApps.map((app: any) => (
        <Box
          key={app.appKey}
          sx={{
            width: '100% !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start !important',
            borderRadius: '1.063rem',
            marginTop: '0.5rem',
            py: '0.25rem',
            px: '0.5rem',
            color: selectedApps.some(
              (selectedApp) => selectedApp.appKey === app.appKey,
            )
              ? '#0065FF'
              : '#5E5E5E',
            border: selectedApps.some(
              (selectedApp) => selectedApp.appKey === app.appKey,
            )
              ? '0.125rem solid #0065FF'
              : '0.063rem solid #5E5E5E',
            backgroundColor: selectedApps.some(
              (selectedApp) => selectedApp.appKey === app.appKey,
            )
              ? '#e0f0ff'
              : '#F5FBFF',
            fontWeight: selectedApps.some(
              (selectedApp) => selectedApp.appKey === app.appKey,
            )
              ? 'bold'
              : 'normal',
          }}
        >
          <DragIndicatorIcon
            sx={{
              ...smallIconStyles,
              color: selectedApps.some(
                (selectedApp) => selectedApp.appKey === app.appKey,
              )
                ? '#0065FF'
                : '#5E5E5E',
              borderRight: selectedApps.some(
                (selectedApp) => selectedApp.appKey === app.appKey,
              )
                ? '0.063rem solid #0065FF'
                : '0.063rem solid #5E5E5E',
              mr: 1,
            }}
          />
          <Box
            component="img"
            src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
            alt={app.name}
            sx={{
              height: '0.938rem',
              mr: '0.5rem',
              fontWeight: selectedApps.some(
                (selectedApp) => selectedApp.appKey === app.appKey,
              )
                ? 'bold'
                : 'normal',
              color: selectedApps.some(
                (selectedApp) => selectedApp.appKey === app.appKey,
              )
                ? '#0065FF'
                : '#5E5E5E',
            }}
          />
          <Box
            sx={{
              fontWeight: selectedApps.some(
                (selectedApp) => selectedApp.appKey === app.appKey,
              )
                ? 'bold'
                : 'normal',
              color: selectedApps.some(
                (selectedApp) => selectedApp.appKey === app.appKey,
              )
                ? '#0065FF'
                : '#5E5E5E',
            }}
          >
            <SubLabel>{app.name}</SubLabel>
          </Box>
        </Box>
      ))}
    </Box>
  );

  const renderApp = (app: any, status: string, index: number) => (
    <Draggable key={app.appKey} draggableId={app.appKey} index={index}>
      {(provided: any, snapshot: any) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => handleSelectApp(app)}
          sx={{
            color: selectedApps.some(
              (selectedApp) => selectedApp.appKey === app.appKey,
            )
              ? '#0065FF'
              : '#5E5E5E',
            py: 0,
            px: '1rem',
            mb: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            height:
              status === 'Custom Restrictions'
                ? {
                  xl: '5.2rem !important',
                  lg: '5.2rem !important',
                }
                : {
                  xl: '2.2rem !important',
                  lg: '2.0rem !important',
                },
            mt: '0.4rem',
          }}
        >
          {snapshot.isDragging ? (
            renderDraggingUI()
          ) : (
            <>
              <div> </div>
              {status === 'Custom Restrictions' ? (
                <Box
                  sx={{
                    width: '100% !important',
                    marginY: '0.4rem',
                    borderRadius: '1.063rem',
                    height: {
                      xl: '5.2rem !important',
                      lg: '5.0rem !important',
                    },
                    fontWeight: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? 'bold'
                      : 'normal',
                    border: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? '0.125rem solid #0065FF'
                      : '0.063rem solid #9fbae3',
                    backgroundColor: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? '#e0f7fa'
                      : '#F5FBFF',
                    color: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? '#0065FF'
                      : '#5E5E5E',
                    overflow: 'hidden',
                    paddingY: '0.25rem',
                    paddingX: '0.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100% !important',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start !important',
                    }}
                  >
                    <DragIndicatorIcon
                      sx={{
                        ...smallIconStyles,
                        color: selectedApps.some(
                          (selectedApp) => selectedApp.appKey === app.appKey,
                        )
                          ? '#0065FF'
                          : '#5E5E5E',
                        borderRight: selectedApps.some(
                          (selectedApp) => selectedApp.appKey === app.appKey,
                        )
                          ? '0.063rem solid #0065FF'
                          : '0.063rem solid #5E5E5E',
                        mr: '0.5rem',
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <Box
                          component="img"
                          src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                          alt={app.name}
                          sx={{ height: '0.938rem', mr: '0.5rem' }}
                        />
                        <SubLabel>{app.name}</SubLabel>
                      </Box>
                    </Box>
                  </Box>

                  <hr
                    style={{
                      margin: '0.3rem 0',
                      border: '0.063rem solid transparent',
                    }}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <span style={{ marginRight: '0.5rem' }}>
                      <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
                        Policy:
                      </Typography>
                    </span>
                    <StyledSelect
                      value={determineAppPolicyID(
                        app.appKey,
                        customRestrictionApps,
                        defaultPolicyID,
                      )}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleAppCustomRestrictionPolicyChange(
                        app.appKey,
                        event.target.value,
                        dispatch,
                      )}
                      sx={{
                        borderRadius: '1.063rem',
                        width: {
                          xl: '7rem',
                          lg: '6rem',
                        },
                      }}
                    >
                      {allPolicies.map((source: any) => (
                        <MenuItem key={source.identifier} value={source._id}>
                          {source.identifier}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100% !important',
                    border: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? '0.125rem solid #0065FF'
                      : '0.063rem solid #9fbae3',
                    backgroundColor: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? '#e0f0ff'
                      : '#F5FBFF',
                    fontWeight: selectedApps.some(
                      (selectedApp) => selectedApp.appKey === app.appKey,
                    )
                      ? 'bold'
                      : 'normal',
                    paddingY: '0.25rem',
                    paddingX: '0.5rem',
                    borderRadius: '1.063rem',
                    height: {
                      xl: '2.2rem !important',
                      lg: '2.0rem !important',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100% !important',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start !important',
                    }}
                  >
                    <DragIndicatorIcon
                      sx={{
                        ...smallIconStyles,
                        color: selectedApps.some(
                          (selectedApp) => selectedApp.appKey === app.appKey,
                        )
                          ? '#0065FF'
                          : '#5E5E5E',
                        borderRight: selectedApps.some(
                          (selectedApp) => selectedApp.appKey === app.appKey,
                        )
                          ? '0.063rem solid #0065FF'
                          : '0.063rem solid #5E5E5E',
                        mr: '0.5rem',
                      }}
                    />
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                      alt={app.name}
                      sx={{
                        width: '0.938rem',
                        mr: '0.5rem',
                        fontWeight: selectedApps.includes(app.appKey)
                          ? 'bold'
                          : 'normal',
                        color: selectedApps.includes(app.appKey)
                          ? '#0065FF'
                          : 'black',
                      }}
                    />
                    <SubLabel>{app.name}</SubLabel>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </Draggable>
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        restrictionBoxRef.current
        && !restrictionBoxRef.current.contains(event.target as Node)
      ) {
        setSelectedApps([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={restrictionBoxRef}>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Grid
          container
          spacing="1rem"
          mt={{ xl: '1 !important', lg: '0.5 !important' }}
        >
          {[
            'App Restriction',
            'No App Restrictions',
            'Disable Apps',
            'Custom Restrictions',
          ].map((status) => (
            <Grid
              item
              xs={3}
              px={{ xl: '3 !important', lg: '2 !important' }}
              py="0.5rem"
              key={status}
            >
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <SmallHeading gutterBottom color="#0065FF">
                      {status}
                    </SmallHeading>
                  </Box>
                  <Droppable droppableId={status}>
                    {(provided: any, snapshot: any) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                          height: {
                            xxl: isConfigured ? '28rem' : '17rem',
                            xl: isConfigured ? '24.5rem' : '13rem',
                            lg: isConfigured ? '20rem' : '9rem',
                          },
                          overflow: 'scroll',
                        }}
                      >
                        {apps
                          ?.filter((app) => {
                            if (status === 'App Restriction') {
                              return restrictedApps.includes(app.appKey);
                            }
                            if (status === 'No App Restrictions') {
                              return allowedApps.includes(app.appKey);
                            }
                            if (status === 'Disable Apps') {
                              return blockApps.includes(app.appKey);
                            }
                            if (status === 'Custom Restrictions') {
                              return (
                                customRestrictionApps
                                && app.appKey in customRestrictionApps
                              );
                            }
                            return false;
                          })
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((app: any, index: number) => renderApp(app, status, index))}

                        {provided.placeholder}

                        {apps?.filter((app) => {
                          if (status === 'No App Restrictions') {
                            return allowedApps.includes(app.appKey);
                          }
                          if (status === 'App Restriction') {
                            return restrictedApps.includes(app.appKey);
                          }
                          if (status === 'Disable Apps') {
                            return blockApps.includes(app.appKey);
                          }
                          if (status === 'Custom Restrictions') {
                            return (
                              customRestrictionApps
                              && app.appKey in customRestrictionApps
                            );
                          }
                          return false;
                        }).length === 0 && (
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: '0.5rem',
                              padding: '1rem',
                              marginTop: '1rem',
                              bottom: 0,
                              width: '100%',
                              height: '90%',
                              display: snapshot.isDraggingOver
                                ? 'none'
                                : 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              border: '0.125rem dashed grey',
                            }}
                          >
                            <Box
                              sx={{
                                zIndex: 1,
                                height: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <AppsIcon
                                sx={{
                                  fontSize: '3.75rem',
                                  marginBottom: '0.5rem',
                                  color: 'lightgray',
                                }}
                              />
                              <p
                                style={{
                                  color: 'lightgray',
                                  fontSize: '1.2rem',
                                }}
                              >
                                Drag and drop your applications here.
                              </p>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Droppable>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DragDropContext>
    </div>
  );
}

export default ManageRestriction;
