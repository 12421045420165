import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ShimmerText, ShimmerPostDetails } from 'react-shimmer-effects';
import { Box } from '@mui/material';
import { AppDispatch, RootState } from '../../Redux/store';
import MainLayout from '../../components/MainLayout';
import { updateApplication } from '../../Redux/Slices/Application/appSlice';
import ConfigurationScreen from './Configuration/configurationScreen';
import EditScreen from './Edit/editConfigurationScreen';
import { getApplicationStatus } from '../../Redux/Slices/Application/applicationHandler';
import { fetchAllAuthentications } from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';

interface IProps {
  app: string;
}

function Application({ app }: IProps) {
  const { isConfigured, status } = useSelector(
    (state: RootState) => state.application,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(updateApplication(app));
  }, []);

  useEffect(() => {
    dispatch(getApplicationStatus());
  }, []);

  useEffect(() => {
    async function getAuthenticationSources() {
      dispatch(fetchAllAuthentications());
    }
    getAuthenticationSources();
  }, []);

  return (
    <MainLayout>
      {status === 'succeeded' ? (
        !isConfigured ? (
          <ConfigurationScreen app={app} />
        ) : (
          <EditScreen app={app} />
        )
      ) : (
        <Box display="flex" flexDirection="column" width="100%">
          <Box p={5}>
            <Box width="60%">
              <ShimmerText line={5} gap={10} />
            </Box>
            <Box display="flex" flexDirection="row">
              <Box width="60%">
                <ShimmerPostDetails card cta variant="SIMPLE" />
              </Box>
              <Box width="40%" px={5}>
                <ShimmerText line={5} gap={10} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </MainLayout>
  );
}

export default Application;
