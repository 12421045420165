import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Autocomplete,
} from '@mui/material';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateEndTime,
  updateStartTime,
  updateTimeZone,
  updateTimeToggle,
  updateEnableTimeRestriction,
  updateSessionDuration,
  updateSessionToggle,
  updateSessionBasedrestriction,
} from '../../Redux/Slices/Policy/policy';
import { StyledTextField } from '../../styles/textField';
import { SmallHeading } from '../../styles/smallHeading';
import { SubLabel } from '../../styles/sublabel';
import defaultTimeZones from '../../data/timezones.json';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { SubTitle } from '../../styles/subtitle';

function TimeBasedRestriction() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    timeConstraintToggle,
    enableTimeRestriction,
    sessionRestrictionToggle,
    enableSessionBasedRestriction,
    timezone,
    startTime,
    endTime,
  } = useSelector((state: RootState) => state.policySettings);
  return (
    <Box>
      <SmallHeading>Time Based Restriction</SmallHeading>
      <Box id="time-restriction" display="flex" p="1rem" flexDirection="column">
        <Box display="flex" gap="1rem" pb="0.5rem">
          <ResponsiveTypography>
            Time of Access Configuration
          </ResponsiveTypography>
        </Box>
        <Box
          pt={1}
          display="flex"
          flexDirection="column"
          borderTop="0.063rem solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enableTimeRestriction}
                onChange={() => (enableTimeRestriction
                  ? dispatch(updateEnableTimeRestriction(false))
                  : dispatch(updateEnableTimeRestriction(true)))}
                name="ip-restriction-checkbox"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                Enabling this will apply time restrictions to users based on the
                configured settings and specified timings.
              </SubLabel>
            )}
          />
          {enableTimeRestriction && (
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="1rem"
                alignItems="center"
              >
                <SubLabel>Action if Time Restriction in the interval</SubLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="TimeAddressToggle"
                  value={timeConstraintToggle}
                  onChange={(event) => dispatch(updateTimeToggle(event?.target.value))}
                >
                  <FormControlLabel
                    value="allow"
                    control={<Radio size="small" />}
                    label={<SubLabel>Allow</SubLabel>}
                    sx={{ fontSize: '0.813rem' }}
                  />
                  <FormControlLabel
                    value="deny"
                    control={<Radio size="small" />}
                    label={<SubLabel>Deny</SubLabel>}
                    sx={{ fontSize: '0.938rem' }}
                  />
                </RadioGroup>
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Autocomplete
                  disablePortal
                  fullWidth
                  size="small"
                  id="timezone"
                  options={defaultTimeZones}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label={<SubLabel>Select Timezone</SubLabel>}
                    />
                  )}
                  value={timezone}
                  onChange={(event: any, values: any) => {
                    dispatch(updateTimeZone(values.label));
                  }}
                />
                <Box display="flex" sx={{ gap: '2.5rem' }}>
                  <Box>
                    <SubTitle>Start Time</SubTitle>
                    <StyledTextField
                      id="outlined-basic"
                      size="small"
                      type="time"
                      variant="outlined"
                      defaultValue="00:00"
                      sx={{
                        backgroundColor: '#F6F6F6',
                        border: 'none !important',
                      }}
                      inputProps={{
                        style: {
                          fontSize: '0.938rem',
                        },
                      }}
                      name="startTime"
                      value={startTime}
                      onChange={(event: any) => {
                        dispatch(updateStartTime(event.target.value));
                      }}
                    />
                  </Box>
                  <Box>
                    <SubTitle>End Time</SubTitle>
                    <StyledTextField
                      id="outlined-basic"
                      size="small"
                      type="time"
                      variant="outlined"
                      defaultValue="00:00"
                      sx={{
                        backgroundColor: '#F6F6F6',
                        border: 'none !important',
                      }}
                      inputProps={{
                        style: {
                          fontSize: '0.938rem',
                        },
                      }}
                      name="endTime"
                      value={endTime}
                      onChange={(event: any) => {
                        dispatch(updateEndTime(event.target.value));
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        id="session-based-restriction"
        display="flex"
        p="1rem"
        flexDirection="column"
      >
        <Box display="flex" gap="1rem" pb="0.5rem">
          <ResponsiveTypography>Session Based Restriction</ResponsiveTypography>
        </Box>
        <Box
          pt="0.5rem"
          display="flex"
          flexDirection="column"
          borderTop="0.063rem solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enableSessionBasedRestriction}
                onChange={() => (enableSessionBasedRestriction
                  ? dispatch(updateSessionBasedrestriction(false))
                  : dispatch(updateSessionBasedrestriction(true)))}
                name="session-based-restriction"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                Enable to restrict user sessions based on configured duration.
              </SubLabel>
            )}
          />
          {enableSessionBasedRestriction && (
            <Box>
              <Box display="flex" flexDirection="column" py="0.5rem">
                <SubLabel>
                  Action for session management should be based on days or
                  hours:
                </SubLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sessionRestrictionToggle"
                  value={sessionRestrictionToggle}
                  onChange={(event) => dispatch(updateSessionToggle(event?.target.value))}
                >
                  <FormControlLabel
                    value="d"
                    control={<Radio size="small" />}
                    label={<SubLabel>Days</SubLabel>}
                    sx={{ fontSize: '0.813rem' }}
                  />
                  <FormControlLabel
                    value="h"
                    control={<Radio size="small" />}
                    label={<SubLabel>Hours</SubLabel>}
                    sx={{ fontSize: '0.938rem' }}
                  />
                </RadioGroup>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="1rem"
                alignItems="center"
              >
                <SubLabel>Duration:</SubLabel>
                <StyledTextField
                  id="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(updateSessionDuration(e.target.value.toString()))}
                  defaultValue="15"
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default TimeBasedRestriction;
