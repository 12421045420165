import React, { useState } from 'react';
import axios from 'axios';
import {
  MenuItem,
  Select,
  Box,
  TextField,
  Grid,
  Button,
  Link,
  FormControl,
  Typography,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { ToastContainer, toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignUpCarousel from '../../components/SignUpCarousel';
import ErrorMessage from '../../components/ErrorMessage';
import 'react-phone-input-2/lib/style.css';
import './SignupForm.css';

export default function SignupForm() {
  const [signUpInputFields, setSignUpInputFields] = useState({
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    areaofinterest: '',
  });
  const navigate = useNavigate();
  const { search } = useLocation();
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [requiredError, setRequiredError] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onChange = (event: any) => {
    var value = '';
    setPhoneError('');
    setRequiredError('');
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    if (name === 'confirm_password') {
      if (signUpInputFields.password !== value) {
        setPasswordError('Password and confirmation Password do not match.');
      } else {
        setPasswordError('');
      }
    } else if (name === 'email') {
      if (isValidEmail(value)) {
        setEmailError('');
      } else {
        setEmailError('Please enter a valid email address.');
      }
    } else if (name === 'password') {
      if (
        signUpInputFields.confirm_password === value
        || signUpInputFields.confirm_password === ''
      ) {
        setPasswordError('');
      } else {
        setPasswordError('Please make sure your passwords match.');
      }
    }
    setSignUpInputFields((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (
      !signUpInputFields.email
      || !signUpInputFields.password
      || !signUpInputFields.confirm_password
      || !signUpInputFields.areaofinterest
    ) {
      setRequiredError('Please fill in required fields.');
    } else if (
      signUpInputFields.confirm_password !== signUpInputFields.password
    ) {
      return;
    }
    const toastId = toast.loading('Processing your request...');
    const params = new URLSearchParams(new URL(window.location.href).search);
    const referer = params.get('referer');
    axios
      .post(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/user-register`, {
        email: signUpInputFields.email,
        password: signUpInputFields.password,
        confirm_password: signUpInputFields.confirm_password,
        phone: signUpInputFields.phone,
        areaofinterest: signUpInputFields.areaofinterest,
        referer,
      })
      .then(() => {
        navigate('/?sign-up=success');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          toast.update(toastId, {
            render: 'Something went wrong!',
            type: 'error',
            autoClose: 1000,
            closeButton: true,
            isLoading: false,
          });
          const { errors } = err.response.data;
          errors.forEach((error: any) => {
            if (error.path === 'phone') {
              setPhoneError(error.msg);
            } else if (error.path === 'email') {
              setEmailError(error.msg);
            } else if (error.path === 'password') {
              setPasswordError(error.msg);
            }
          });
        } else if (
          err.response
          && err.response.data
          && err.response.data.signup_message
        ) {
          toast.update(toastId, {
            render: 'Something went wrong!',
            type: 'error',
            autoClose: 1000,
            closeButton: true,
            isLoading: false,
          });
          setRequiredError(err.response.data.signup_message);
        } else {
          setPhoneError('');
          setEmailError('');
          setPasswordError('');
          setRequiredError('');
        }
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleSignUpRedirect = () => {
    const params = new URLSearchParams(search);
    const referer = params.get('referer');
    if (referer) {
      navigate(`/login?source=${referer}`);
    } else {
      navigate('/login');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        height: '90%',
        color: '#5F636D',
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '50%',
          overflow: 'hidden',
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            px: '0.313rem',
          }}
        >
          <SignUpCarousel />
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: 'flex' },
          width: { xs: '100%', md: '50%' },
          alignItems: 'center',
          justifyContent: 'center',
          px: { xs: '1.5rem', md: '3rem' },
          py: { xs: '2rem' },
        }}
      >
        <Box
          component="form"
          noValidate
          sx={{ px: { xs: '0.25rem', md: '3rem' } }}
        >
          <Grid container spacing="1rem">
            <Grid item xs={12}>
              <Typography
                variant="h4"
                align="center"
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                <b>Create an Account</b>
              </Typography>
              <Typography
                variant="h4"
                align="left"
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <b>Get started with Reverse Proxy</b>
              </Typography>
              <br />
              <Typography
                variant="subtitle2"
                align="center"
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                Get started with a free trial and enjoy world-class service by
                miniOrange Reverse Proxy
              </Typography>
              <Typography
                variant="body1"
                align="left"
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                Securing enterprises and cloud apps without trading performance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {requiredError && <ErrorMessage message={requiredError} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" width="100%">
                <Typography
                  variant="subtitle2"
                  sx={{ '@media (max-width:37.5rem)': { fontSize: '0.75rem' } }}
                >
                  Business Email
                </Typography>
                <Typography color="#FF5656" variant="subtitle2">
                  &nbsp;*
                </Typography>
              </Box>
              <TextField
                name="email"
                required
                fullWidth
                id="email"
                size="small"
                autoFocus
                onChange={onChange}
              />
              {emailError !== '' && (
                <Typography variant="body2" color="#FF5656">
                  {emailError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                sx={{ '@media (max-width:37.5rem)': { fontSize: '0.75rem' } }}
              >
                Phone Number
              </Typography>
              <PhoneInput
                country="us"
                onChange={(phone: any) => setSignUpInputFields((prevValue) => ({
                  ...prevValue,
                  phone,
                }))}
              />
              {phoneError !== '' && (
                <Typography variant="body2" color="#FF5656">
                  {phoneError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" width="100%">
                <Typography
                  variant="subtitle2"
                  sx={{ '@media (max-width:37.5rem)': { fontSize: '0.75rem' } }}
                >
                  Password
                </Typography>
                <Typography color="#FF5656" variant="subtitle2">
                  &nbsp;*
                </Typography>
              </Box>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <OutlinedInput
                  id="password"
                  name="password"
                  onChange={onChange}
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" width="100%">
                <Typography
                  variant="subtitle2"
                  sx={{ '@media (max-width:37.5rem)': { fontSize: '0.75rem' } }}
                >
                  Confirm Password
                </Typography>
                <Typography color="#FF5656" variant="subtitle2">
                  &nbsp;*
                </Typography>
              </Box>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <OutlinedInput
                  id="confirm_password"
                  name="confirm_password"
                  onChange={onChange}
                  size="small"
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {passwordError !== '' && (
                <Typography variant="body2" color="#FF5656">
                  {passwordError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" width="100%">
                <Typography
                  variant="subtitle2"
                  sx={{ '@media (max-width:37.5rem)': { fontSize: '0.75rem' } }}
                >
                  Area of Interest
                </Typography>
                <Typography color="#FF5656" variant="subtitle2">
                  &nbsp;*
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Select
                  id="areaofinterest"
                  name="areaofinterest"
                  size="small"
                  onChange={onChange}
                  required
                >
                  <MenuItem value="Secure Access - Google Workplace">
                    Secure Access - Google Workplace
                  </MenuItem>
                  <MenuItem value="Header Based Authentication">
                    Header Based Authentication
                  </MenuItem>
                  <MenuItem value="Secure Access - Shopify Store">
                    Secure Access - Shopify Store
                  </MenuItem>
                  <MenuItem value="Secure Wordpress site">
                    Secure Wordpress site
                  </MenuItem>
                  <MenuItem value="IP Restriction | CORS | Load Balancing | Rate Limiting | Substitution">
                    IP Restriction | CORS | Load Balancing | Rate Limiting |
                    Substitution
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                By proceeding I agree to the Terms of Use and acknowledge that I
                have read the privacy policy&nbsp;
                <Link href="https://www.miniorange.com/usecases/miniOrange_User_Agreement.pdf">
                  Terms
                </Link>
                &nbsp;and&nbsp;
                <Link href="https://www.miniorange.com/usecases/miniOrange_Privacy_Policy.pdf">
                  Policies
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <ToastContainer />
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: '1.5rem', mb: '1rem', backgroundColor: '#2D3748' }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="center">
            <Typography variant="subtitle2">
              Already have an account?&nbsp;
              <Button
                onClick={handleSignUpRedirect}
                sx={{
                  '&:hover': {
                    backgroundColor: '#fff',
                    color: '#007bff',
                  },
                }}
              >
                Sign in
              </Button>
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
