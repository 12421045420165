import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppStateFactory } from '../class-app-factory';
import { redirectToEditScreen } from '../appSlice';

export const updateBasicSettings = createAsyncThunk(
  'updateBasicSettings',
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const step = await AppStateFactory.updateBasicApplicationSettings(
      state.application.app,
      state.basicSettings,
      state.application.authenticationID,
      state.application.step,
    );
    if (step === process.env.REACT_APP_CONFIGURATION_COMPLETED) {
      await thunkAPI.dispatch(redirectToEditScreen(true));
    }
    return step;
  },
);
