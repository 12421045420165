import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  session: any;
}

const initialState: InitialState = {
  session: [],
};

const sessionManagementSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateUserSession: (state, action: PayloadAction<any>) => {
      state.session = action.payload;
    },
  },
});

export const { updateUserSession } = sessionManagementSlice.actions;
export default sessionManagementSlice;
