export const routesData = [
  {
    route: '/casb/google',
    screen: '/applications',
  },
  {
    route: '/casb/office365',
    screen: '/applications',
  },
  {
    route: '/casb/atlassian',
    screen: '/applications',
  },
  {
    route: '/casb/shopify',
    screen: '/applications',
  },
  {
    route: '/casb/shopify-plus',
    screen: '/applications',
  },
  {
    route: '/add/tenant',
    screen: '/dashboard',
  },
  {
    route: '/settings',
    screen: '/dashboard',
  },
  {
    route: '/dashboard',
    screen: '/dashboard',
  },
  {
    route: '/manage-policy',
    screen: '/manage-policy',
  },
  {
    route: '/configure/authentication-source',
    screen: '/configure/authentication-source',
  },
  {
    route: '/add/proxy',
    screen: '/configure/proxy',
  },
  {
    route: '/configure/proxy',
    screen: '/configure/proxy',
  },
  {
    route: '/applications',
    screen: '/applications',
  },
];

export const getScreenByRoute = (currentRoute: any) => {
  const routeData = routesData.find((route) => route.route === currentRoute);
  return routeData ? routeData.screen : '/applications';
};
