import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import miniorange from '../../assets/images/miniorange-logo.webp';
import Image404 from '../../assets/images/404.svg';

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ width: '100vw !important', height: '100vh !important' }}
      display="flex"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="50%"
        alignItems="right"
        justifyContent="space-between"
        paddingX="0.938rem"
        paddingTop="0.625rem"
        paddingBottom="1.563rem"
      >
        <Box>
          <img alt="miniorange-logo" src={miniorange} width="15.625rem" />
        </Box>
        <Box display="flex" flexDirection="column" sx={{ gap: '1rem' }}>
          <Typography variant="h1" color="#777876">
            404
          </Typography>
          <Typography variant="h5" color="initial">
            OOOOPS! Page not found
          </Typography>
          <Box>
            <Typography variant="subtitle1" color="#777876">
              This page doesn't exist or was removed!
            </Typography>
            <Typography variant="subtitle1" color="#777876">
              Please contact your administrator.
            </Typography>
          </Box>
          <Button
            variant="text"
            sx={{
              backgroundColor: '#2D3748',
              color: '#ffffff',
              width: '10rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#2D3748',
                color: '#ffffff',
              },
              '&:disabled': {
                backgroundColor: 'lightgray',
              },
            }}
            onClick={() => navigate('/login')}
          >
            Back Home
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="50%"
        alignItems="center"
        justifyContent="center"
      >
        <img src={Image404} alt="image404" width="600" />
      </Box>
    </Box>
  );
}
