import { toast } from 'react-toastify';
import { getUser, generateMetaData } from './utility';
import { updateAuthenticationID, updateSPMetadata } from '../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';

export const downloadXML = async ({ identifier, _id, dispatch }: any) => {
  const user = await getUser();
  if (!identifier) {
    return;
  }
  if (!_id) {
    await generateMetaData(identifier)
      .then(async (data) => {
        dispatch(updateAuthenticationID((data as any).success.sp_metadata._id));
        dispatch(updateSPMetadata((data as any).success.sp_metadata?.saml));
        const response = await fetch(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${
            user.customer_id
          }/${user?.current_active_tenant_id}/${
            (data as any).success.sp_metadata._id
          }/generate-sp-metadata`,
        );
        const blob = await response.blob();

        const url = window.URL.createObjectURL(
          new Blob([blob], { type: 'application/xml' }),
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sp-metadata.xml';
        a.click();
      })
      .catch((error) => {
        toast.error(error);
      });
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user?.current_active_tenant_id}/${_id}/generate-sp-metadata`,
    );
    const blob = await response.blob();

    // Create a download link and click it
    const url = window.URL.createObjectURL(
      new Blob([blob], { type: 'application/xml' }),
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sp-metadata.xml';
    a.click();
  }
};

export const generateAndOpenMetadataURL = async ({
  identifier,
  _id,
  dispatch,
}: any) => {
  const user = await getUser();
  if (!identifier) {
    toast.error('Please add identifier name');
    return;
  }
  if (!_id) {
    await generateMetaData(identifier)
      .then((data) => {
        dispatch(
          updateAuthenticationID((data as any).success?.sp_metadata?._id),
        );
        dispatch(updateSPMetadata((data as any).success?.sp_metadata?.saml));
        window.open(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${
            user.customer_id
          }/${user?.current_active_tenant_id}/${
            (data as any).success.sp_metadata._id
          }/generate-sp-metadata`,
          '_blank',
        );
      })
      .catch((error: any) => {
        toast.error(error);
      });
  } else {
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user?.current_active_tenant_id}/${_id}/generate-sp-metadata`,
      '_blank',
    );
  }
};
