import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApplicationStatus } from '../applicationHandler';

interface InitialState {
  identifier: string;
  domain: string;
  attributeKey: string;
  nameAttributeKey: string;
  acsURL: string;
  acsEntityURL: string;
  casbType: string;
  mdm: boolean;
  auditing: boolean;
  nameIDFormat: string;
  casb: boolean;
  multistaff: boolean;
  shopifyUsers: { username: string; password: string; group_name: string }[];
}

const initialState: InitialState = {
  identifier: '',
  domain: '',
  attributeKey: '',
  nameAttributeKey: '',
  acsURL: '',
  acsEntityURL: '',
  casbType: 'offline',
  auditing: false,
  mdm: false,
  nameIDFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
  casb: false,
  multistaff: false,
  shopifyUsers: [],
};

const basicSettingsSlice = createSlice({
  name: 'basicSettings',
  initialState,
  reducers: {
    updateApplicationIdentifier: (state, action: PayloadAction<string>) => {
      state.identifier = action.payload;
    },
    updateDomain: (state, action: PayloadAction<string>) => {
      state.domain = action.payload;
    },
    updateAttributeKey: (state, action: PayloadAction<string>) => {
      state.attributeKey = action.payload;
    },
    updateNameAttributeKey: (state, action: PayloadAction<string>) => {
      state.nameAttributeKey = action.payload;
    },
    updateACSURL: (state, action: PayloadAction<string>) => {
      state.acsURL = action.payload;
    },
    updateEntityURL: (state, action: PayloadAction<string>) => {
      state.acsEntityURL = action.payload;
    },
    updateCASBType: (state, action: PayloadAction<string>) => {
      state.casbType = action.payload;
    },
    updateEnableCASB: (state, action: PayloadAction<boolean>) => {
      state.casb = action.payload;
    },
    updateEnableMDM: (state, action: PayloadAction<boolean>) => {
      state.mdm = action.payload;
    },
    updateEnableAuditing: (state, action: PayloadAction<boolean>) => {
      state.auditing = action.payload;
    },
    updateEnableMultistaff: (state, action: PayloadAction<boolean>) => {
      state.multistaff = action.payload;
    },
    updateNameIDFormat: (state, action: PayloadAction<string>) => {
      state.nameIDFormat = action.payload;
    },
    addNewUser: (state) => {
      state.shopifyUsers.push({ username: '', password: '', group_name: '' });
    },
    updateGroup: (
      state,
      action: PayloadAction<{
        groupName: string;
        index: number;
      }>,
    ) => {
      const { groupName, index } = action.payload;
      if (state.shopifyUsers[index]) {
        state.shopifyUsers[index].group_name = groupName;
      }
    },
    updateUserName: (
      state,
      action: PayloadAction<{
        username: string;
        index: number;
      }>,
    ) => {
      const { username, index } = action.payload;
      if (state.shopifyUsers[index]) {
        state.shopifyUsers[index].username = username;
      }
    },
    updatePassword: (
      state,
      action: PayloadAction<{
        password: string;
        index: number;
      }>,
    ) => {
      const { password, index } = action.payload;
      if (state.shopifyUsers[index]) {
        state.shopifyUsers[index].password = password;
      }
    },
    removeUser: (state, action: PayloadAction<number>) => {
      const indexToRemove = action.payload;
      if (indexToRemove >= 0 && indexToRemove < state.shopifyUsers.length) {
        state.shopifyUsers.splice(indexToRemove, 1);
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getApplicationStatus.fulfilled, (state, action: any) => {
      state.identifier = action.payload?.success?.identifier ?? initialState.identifier;
      state.domain = action.payload?.success?.organization_domain ?? initialState.domain;
      state.acsURL = action.payload?.success?.acs_url ?? initialState.acsURL;
      state.acsEntityURL = action.payload?.success?.entity_id_url ?? initialState.acsEntityURL;
      state.attributeKey = action.payload?.success?.attribute_key ?? initialState.attributeKey;
      state.nameAttributeKey = action.payload?.success?.name_attribute_key
        ?? initialState.nameAttributeKey;
      state.casbType = action.payload?.success?.casb_type ?? initialState.casbType;
      state.mdm = action.payload?.success?.mdm ?? initialState.mdm;
      state.auditing = action.payload?.success?.auditing ?? initialState.auditing;
      state.casb = action.payload?.success?.features?.casb ?? initialState.casb;
      state.multistaff = action.payload?.success?.features?.multi_staff
        ?? initialState.multistaff;
      state.nameIDFormat = action.payload?.success?.name_id_format ?? initialState.nameIDFormat;
      state.shopifyUsers = action.payload?.success?.users ?? initialState.shopifyUsers;
    });
  },
});

export const {
  updateApplicationIdentifier,
  updateDomain,
  updateAttributeKey,
  updateNameAttributeKey,
  updateACSURL,
  updateEntityURL,
  updateCASBType,
  updateEnableCASB,
  updateEnableMDM,
  updateEnableAuditing,
  updateNameIDFormat,
  updateEnableMultistaff,
  addNewUser,
  removeUser,
  updateUserName,
  updatePassword,
  updateGroup,
} = basicSettingsSlice.actions;
export default basicSettingsSlice;
