import React, { useState } from 'react';
import {
  Fade, Box, Grid, InputBase, IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import MainLayout from '../../components/MainLayout';
import { ResponsiveHeading } from '../../styles/heading';
import { ResponsiveImage } from '../../styles/app-logo';
import { apps } from '../Application/app';

function Applications() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredApps = Object.values(apps)
    .filter((app) => app.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <MainLayout>
      <Fade in timeout={500}>
        <Box p="2.5rem">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="1.5rem"
          >
            <ResponsiveHeading>Applications</ResponsiveHeading>
            <Box
              component="form"
              display="flex"
              alignItems="center"
              sx={{
                borderRadius: '1.25rem',
                backgroundColor: '#F5F5F5',
                p: '0.25rem 0.5rem',
                boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }}
            >
              <InputBase
                placeholder="Search Applications"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ ml: '0.5rem', flex: 1 }}
              />
              <IconButton type="submit" sx={{ p: '0.625rem' }}>
                <SearchIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing="1rem" py="1.5rem">
            {filteredApps.map((app) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={app.title}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    backgroundColor: '#E3F2FD',
                    borderRadius: '0.625rem',
                    padding: '1.5rem',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-0.25rem)',
                      boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.15)',
                    },
                  }}
                  onClick={() => navigate(app.path)}
                >
                  <ResponsiveImage src={app.logo} alt={app.title} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </MainLayout>
  );
}

export default Applications;
