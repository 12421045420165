import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../assets/images/miniorange-shortlogo.webp';

export default function MiniOrangeShortLogo() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  let height;
  if (xl) {
    height = '3rem';
  } else if (lg) {
    height = '2.5rem';
  } else if (md) {
    height = '2rem';
  } else {
    height = '1.5rem';
  }
  return (
    <Box component="img" src={Logo} alt="miniorange-logo" sx={{ height }} />
  );
}
