import React from 'react';
import { Box, Drawer } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { RootState } from '../../../Redux/store';
import { StyledTextField } from '../../../styles/textField';
import { smallIconStyles } from '../../../styles/icon';
import { ResponsiveSubHeading } from '../../../styles/subHeading';
import { SubTitle } from '../../../styles/subtitle';

interface SPMetadataModalProps {
  open: boolean;
  setOpen: Function;
}

function SPMetadataField({
  label, value, copyText, onClick,
}: any) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        <SubTitle>{label}</SubTitle>
      </Box>
      <Box>
        <StyledTextField
          disabled
          variant="outlined"
          size="small"
          fullWidth
          value={value}
          InputProps={{
            endAdornment: (
              <CopyToClipboard text={copyText}>
                <ContentCopyIcon
                  sx={{ ...smallIconStyles, cursor: 'pointer !important' }}
                  onClick={onClick}
                />
              </CopyToClipboard>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function SPMetadataModal({ open, setOpen }: SPMetadataModalProps) {
  const handleClose = () => setOpen(false);

  const CopiedToClipBoard = () => {
    toast('Copied to Clipboard');
  };

  const {
    spEntityURL,
    spAudienceURL,
    spAcsURL,
    spLogoutAcsUrl,
    spX509Certificate,
  } = useSelector((state: RootState) => state.authenticationSource);

  const handleDownloadSAMLCertificate = () => {
    if (!spX509Certificate?.trim()) return;

    const blob = new Blob([spX509Certificate], {
      type: 'application/x-x509-ca-cert',
    });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'saml-sp-certificate.crt';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
        width="100%"
        display="flex"
        sx={{
          backgroundColor: 'white',
          width: {
            xl: '40rem !important',
            lg: '35rem !important',
            md: '30rem !important',
          },
        }}
        p="1.25rem"
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap="1rem">
          <ResponsiveSubHeading color="#5F636D">
            Service Provider Metadata:
          </ResponsiveSubHeading>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        p="1.25rem"
        width="100%"
        sx={{
          backgroundColor: 'white',
          width: {
            xl: '40rem !important',
            lg: '35rem !important',
            md: '30rem !important',
          },
        }}
        gap="1rem"
      >
        {[
          {
            label: 'SP Entity ID or Issuer:',
            value: spEntityURL || '',
            copyText: spEntityURL,
          },
          {
            label: 'Audience URI:',
            value: spAudienceURL || '',
            copyText: spAudienceURL,
          },
          {
            label: 'ACS URL:',
            value: spAcsURL || '',
            copyText: spAcsURL,
          },
          {
            label: 'Single Logout URL:',
            value: spLogoutAcsUrl || '',
            copyText: spLogoutAcsUrl,
          },
        ].map(({ label, value, copyText }) => (
          <SPMetadataField
            key={label}
            label={label}
            value={value}
            copyText={copyText}
            onClick={CopiedToClipBoard}
          />
        ))}
        <Box display="flex" flexDirection="column" width="100%">
          <Box>
            <SubTitle>Signing Certificate:</SubTitle>
          </Box>
          <Box>
            <StyledTextField
              disabled
              fullWidth
              multiline
              rows={10}
              maxRows={10}
              value={spX509Certificate || ''}
              InputProps={{
                endAdornment: (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="2rem"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <DownloadIcon onClick={handleDownloadSAMLCertificate} />
                    <CopyToClipboard
                      text={spX509Certificate}
                      onCopy={CopiedToClipBoard}
                    >
                      <ContentCopyIcon sx={{ ...smallIconStyles, cursor: 'pointer !important' }} />
                    </CopyToClipboard>
                  </Box>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default SPMetadataModal;
