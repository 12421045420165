import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Box } from '@mui/material';
import { AppDispatch, RootState } from '../../../../../Redux/store';
import { ResponsiveHeading } from '../../../../../styles/heading';
import { apps } from '../../../app';
import {
  clearGroupSettings,
  updateClose,
} from '../../../../../Redux/Slices/Application/AppConfiguration/Group/group';

function GroupConfiguration() {
  const dispatch = useDispatch<AppDispatch>();
  const { app } = useSelector((state: RootState) => state.application);
  const { close } = useSelector((state: RootState) => state.groupSettings);
  const handleClose = async () => {
    await dispatch(clearGroupSettings());
    await dispatch(updateClose(false));
  };
  return (
    <Drawer open={close} onClose={handleClose} anchor="right">
      <Box
        display="flex"
        flexDirection="column"
        gap="2rem"
        p="1rem"
        width={apps[app]?.isSingletonApplication ? '48vw' : '85vw'}
        px="2.5rem"
        pt="1.5rem"
      >
        <ResponsiveHeading>Group Settings</ResponsiveHeading>
        {apps[app].editScreen.groupAppSettings}
      </Box>
    </Drawer>
  );
}

export default GroupConfiguration;
