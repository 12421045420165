import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ResponsiveTypography = styled(Typography)(({ theme }: any) => ({
  fontWeight: 500,
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.55rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
}));
