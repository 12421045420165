import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  proxyName: '',
  proxyLink: '',
  primaryServerURL: '',
  enableByIP: false,
  id: null,
  IPRestriction: {
    enable: false,
    IPList: [] as string[],
    restrictIPs: false,
  },
  LocationBasedRestriction: {
    enable: false,
    LocationList: [] as string[],
    restrictLocations: false,
  },
  CORS: {
    enable: false,
    DomainList: [] as string[],
  },
  CachingFeature: {
    enable: false,
    cachingTime: null,
  },
  LoadBalanceFeature: {
    enable: false,
    memberList: [] as string[],
  },
  RateLimitingFeature: {
    enable: false,
    maxRequest: 100,
    timeLimit: 5,
    whiteListPath: [] as string[],
  },
  CookieDomainRewriteFeature: {
    enable: false,
    CookieDomainPathList: [{ domain: '', path: '' }],
  },
  Authentication: {
    enable: false,
    authenticationID: 'Select Authentication',
    redirectAfterAuthentication: '',
  },
  Substitution: {
    enable: false,
    htmlString: [] as string[],
    locationHeader: [],
  },
};

const proxySettings = createSlice({
  name: 'proxySettings',
  initialState,
  reducers: {
    updateProxyName: (state, action: PayloadAction<string>) => {
      state.proxyName = action.payload;
    },
    updateProxyLink: (state, action: PayloadAction<string>) => {
      state.proxyLink = action.payload;
    },
    updatePrimaryServerURL: (state, action: PayloadAction<string>) => {
      state.primaryServerURL = action.payload;
    },
    updateEnableByIP: (state, action: PayloadAction<boolean>) => {
      state.enableByIP = action.payload;
    },
    updateId: (state, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    updateIPRestriction: (state, action: PayloadAction<any>) => {
      state.IPRestriction = action.payload;
    },
    updateLocationBasedRestriction: (state, action: PayloadAction<any>) => {
      state.LocationBasedRestriction = action.payload;
    },
    updateCORS: (state, action: PayloadAction<any>) => {
      state.CORS = action.payload;
    },
    updateCachingFeature: (state, action: PayloadAction<any>) => {
      state.CachingFeature = action.payload;
    },
    updateLoadBalanceFeature: (state, action: PayloadAction<any>) => {
      state.LoadBalanceFeature = action.payload;
    },
    updateRateLimitingFeature: (state, action: PayloadAction<any>) => {
      state.RateLimitingFeature = action.payload;
    },
    updateCookieDomainRewriteFeature: (state, action: PayloadAction<any>) => {
      state.CookieDomainRewriteFeature = action.payload;
    },
    updateAuthentication: (state, action: PayloadAction<any>) => {
      state.Authentication = action.payload;
    },
    updateSubstitution: (state, action: PayloadAction<any>) => {
      state.Substitution = action.payload;
    },
    updateProxyState: (state, action: PayloadAction<any>) => {
      state.proxyName = action.payload.proxyName;
      state.proxyLink = action.payload.proxyLink;
      state.primaryServerURL = action.payload.primaryServerURL;
      state.enableByIP = action.payload.enableByIP;
      state.id = action.payload.id;
      state.IPRestriction = action.payload.IPRestriction;
      state.LocationBasedRestriction = action.payload.LocationBasedRestriction;
      state.CORS = action.payload.CORS;
      state.CachingFeature = action.payload.CachingFeature;
      state.LoadBalanceFeature = action.payload.LoadBalanceFeature;
      state.RateLimitingFeature = action.payload.RateLimitingFeature;
      state.CookieDomainRewriteFeature = action.payload.CookieDomainRewriteFeature;
      state.Authentication = action.payload.Authentication;
      state.Substitution = action.payload.Substitution;
    },
    resetProxySettings: () => initialState,
  },
});

export const {
  updateProxyName,
  updateProxyLink,
  updatePrimaryServerURL,
  updateEnableByIP,
  updateId,
  updateIPRestriction,
  updateLocationBasedRestriction,
  updateCORS,
  updateCachingFeature,
  updateLoadBalanceFeature,
  updateRateLimitingFeature,
  updateCookieDomainRewriteFeature,
  updateAuthentication,
  updateSubstitution,
  updateProxyState,
  resetProxySettings,
} = proxySettings.actions;
export default proxySettings;
