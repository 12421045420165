import React from 'react';
import { Button } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { lightBackgroundButtonStyles } from '../styles/button';
import { buttonIconStyles } from '../styles/icon';

interface CancelProps {
  buttonText?: string;
  iconType?: 'ArrowBackIcon' | 'DeleteOutlineOutlinedIcon';
}

// Use a function declaration instead of an arrow function
function Cancel({ buttonText = 'Cancel', iconType }: CancelProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  // Use the passed icon or default to DeleteOutlineOutlinedIcon
  const IconComponent = iconType === 'ArrowBackIcon' ? ArrowBackIcon : DeleteOutlineOutlinedIcon;

  return (
    <Button
      variant="text"
      sx={{ ...lightBackgroundButtonStyles, width: '6rem' }}
      startIcon={
        <IconComponent sx={{ ...buttonIconStyles, color: '#373737' }} />
      }
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
}

// Set default props for the component
Cancel.defaultProps = {
  buttonText: 'Cancel',
  iconType: undefined,
};

export default Cancel;
