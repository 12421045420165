const steps = {
  AuthenticationScreen: {
    title: 'Authentication Source',
    subtitle: 'Configure Authentication Source for the App.',
  },
  BasicSettingScreen: {
    title: 'Basic Settings',
    subtitle: 'Configure Application Settings.',
  },
  PolicyScreen: {
    title: 'Policy Settings',
    subtitle: 'Configure Policy for the application.',
  },
  GroupScreen: {
    title: 'Group Configuration',
    subtitle: 'Configure Groups for the application.',
  },
};
export default steps;
