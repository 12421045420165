import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCookie } from '../../../../utils/utility';

interface AuthenticationSource {
  status: string;
  identifier: string;
  issuer: string;
  loginURL: string;
  logoutURL: string;
  bindingType: string;
  X509Certificate: string;
  _id: string;
  spEntityURL: string;
  spAudienceURL: string;
  spAcsURL: string;
  spLogoutAcsUrl: string;
  spX509Certificate: string;
  selectedSource: string;
  configuredAuthenticationSource: any[];
  drawer: boolean;
}

const initialState: AuthenticationSource = {
  status: 'idle',
  identifier: '',
  issuer: '',
  loginURL: '',
  logoutURL: '',
  bindingType: 'HttpRedirect',
  X509Certificate: '',
  _id: '',
  spEntityURL: '',
  spAudienceURL: '',
  spAcsURL: '',
  spLogoutAcsUrl: '',
  spX509Certificate: '',
  selectedSource: 'Add Authentication Source',
  configuredAuthenticationSource: [],
  drawer: false,
};

export const fetchAllAuthentications = createAsyncThunk(
  'authenticationSource/fetchAllAuthentications',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
        {},
        { headers: { Authorization: token } },
      );

      if (response.data?.authentication_source) {
        return response.data.authentication_source;
      }
      return thunkAPI.rejectWithValue('No authentication source found');
    } catch (err: any) {
      const errorMessage = err.response?.data?.errors?.[0]?.message
        || 'An error occurred while fetching authentications';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  },
);

export const draftAuthenticationSource = createAsyncThunk(
  'draftAuthenticationSource',
  async (_, thunkAPI) => {
    var selectedSource: any = null;
    try {
      const state: any = thunkAPI.getState();
      await state?.authenticationSource?.configuredAuthenticationSource?.forEach(
        (element: any) => {
          if (element._id === state.application.authenticationID) {
            selectedSource = element;
          }
        },
      );
      return selectedSource;
    } catch (err: any) {
      const errorMessage = err.response?.data?.errors?.[0]?.message
        || 'An error occurred while drafting authentication source';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  },
);

const updateStateFromPayload = (state: AuthenticationSource, payload: any) => {
  state.identifier = payload?.identifier;
  state.issuer = payload?.saml?.entity_id;
  state.loginURL = payload?.saml?.login_url;
  state.logoutURL = payload?.saml?.logout_url;
  state.X509Certificate = payload?.saml?.x_509_certificate;
  state.bindingType = payload?.saml?.binding_type;
  state.spAcsURL = payload?.saml?.saml_urls?.acs_url;
  state.spLogoutAcsUrl = payload?.saml?.saml_urls?.logout_acs_url;
  state.spEntityURL = payload?.saml?.sp_metadata?.entity_id;
  state.spAudienceURL = payload?.saml?.sp_metadata?.entity_id;
  state.spX509Certificate = payload?.saml?.sp_metadata?.sp_x_509_certificate;
  state._id = payload?._id;
  state.selectedSource = payload?._id;
};

const authenticationSourceSlice = createSlice({
  name: 'authenticationSource',
  initialState,
  reducers: {
    updateAuthenticationName: (state, action: PayloadAction<string>) => {
      state.identifier = action.payload;
    },
    updateIssuer: (state, action: PayloadAction<string>) => {
      state.issuer = action.payload;
    },
    updateLoginURL: (state, action: PayloadAction<string>) => {
      state.loginURL = action.payload;
    },
    updateLogoutURL: (state, action: PayloadAction<string>) => {
      state.logoutURL = action.payload;
    },
    updateX509Certificate: (state, action: PayloadAction<string>) => {
      state.X509Certificate = action.payload;
    },
    updateAuthenticationID: (state, action: PayloadAction<string>) => {
      state._id = action.payload;
    },
    updateBindingType: (state, action: PayloadAction<string>) => {
      state.bindingType = action.payload;
    },
    updateSPMetadata: (state, action: PayloadAction<any>) => {
      state.spEntityURL = action.payload.sp_metadata.entity_id;
      state.spAudienceURL = action.payload.sp_metadata.entity_id;
      state.spAcsURL = action.payload.saml_urls?.acs_url;
      state.spLogoutAcsUrl = action.payload.saml_urls?.logout_acs_url;
      state.spX509Certificate = action.payload.sp_metadata?.sp_x_509_certificate;
    },
    updateAuthenticationSource: (state, action: PayloadAction<any>) => {
      updateStateFromPayload(state, action.payload);
    },
    addNewAuthenticationSource: (state) => {
      state.identifier = '';
      state.issuer = '';
      state.loginURL = '';
      state.logoutURL = '';
      state.X509Certificate = '';
      state.bindingType = 'HttpRedirect';
      state.spAcsURL = '';
      state.spLogoutAcsUrl = '';
      state.spEntityURL = '';
      state.spAudienceURL = '';
      state.spX509Certificate = '';
      state._id = '';
      state.selectedSource = 'Add Authentication Source';
    },
    updateOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllAuthentications.fulfilled, (state, action) => {
      state.configuredAuthenticationSource = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(
      draftAuthenticationSource.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { payload } = action;
        updateStateFromPayload(state, payload);
      },
    );
  },
});

export const {
  updateAuthenticationName,
  updateIssuer,
  updateLoginURL,
  updateLogoutURL,
  updateX509Certificate,
  updateSPMetadata,
  updateAuthenticationSource,
  updateAuthenticationID,
  addNewAuthenticationSource,
  updateBindingType,
  updateOpenDrawer,
} = authenticationSourceSlice.actions;

export default authenticationSourceSlice;
