import React, { useState } from 'react';
import { Box, Switch, Button } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateLoadBalanceFeature,
  updateRateLimitingFeature,
} from '../../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../../Redux/store';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { StyledTextField } from '../../../../styles/textField';
import { SubTitle } from '../../../../styles/subtitle';
import { SubLabel } from '../../../../styles/sublabel';

function LoadBalancingRateLimiting() {
  const dispatch = useDispatch();
  const loadBalanceFeature = useSelector(
    (state: RootState) => state.proxySettings.LoadBalanceFeature,
  );
  const rateLimitingFeature = useSelector(
    (state: RootState) => state.proxySettings.RateLimitingFeature,
  );

  const [rateLimiterMembers, setRateLimiterMembers] = useState<string[]>(
    rateLimitingFeature.whiteListPath || [''],
  );
  const addFormFieldsRateLimiter = () => {
    setRateLimiterMembers([...rateLimiterMembers, '']);
  };

  const updateReduxRateLimiting = (updatedRateLimiting: any) => {
    dispatch(updateRateLimitingFeature(updatedRateLimiting));
  };

  const removeFieldsRateLimiter = (index: number) => {
    const updatedPaths = [...rateLimiterMembers];
    updatedPaths.splice(index, 1);
    setRateLimiterMembers(updatedPaths);
    updateReduxRateLimiting({
      ...rateLimitingFeature,
      whiteListPath: updatedPaths,
    });
  };

  const handleChangeRateLimiter = (index: number, event: any) => {
    const updatedPaths = [...rateLimiterMembers];
    updatedPaths[index] = event.target.value;
    setRateLimiterMembers(updatedPaths);
    updateReduxRateLimiting({
      ...rateLimitingFeature,
      whiteListPath: updatedPaths,
    });
  };

  const [balancerMembers, setBalancerMembers] = useState<any[]>(
    loadBalanceFeature.memberList || [''],
  );

  const addFormFieldsLoadBalancer = () => {
    setBalancerMembers([...balancerMembers, '']);
  };

  const updateReduxLoadBalance = (updatedLoadBalance: any) => {
    dispatch(updateLoadBalanceFeature(updatedLoadBalance));
  };

  const removeFieldsLoadBalancer = (index: number) => {
    const updatedMembers = [...balancerMembers];
    updatedMembers.splice(index, 1);
    setBalancerMembers(updatedMembers);
    updateReduxLoadBalance({
      ...loadBalanceFeature,
      memberList: updatedMembers,
    });
  };

  const handleChangeLoadBalancer = (index: number, event: any) => {
    const updatedMembers = [...balancerMembers];
    updatedMembers[index] = event.target.value;
    setBalancerMembers(updatedMembers);
    updateReduxLoadBalance({
      ...loadBalanceFeature,
      memberList: updatedMembers,
    });
  };

  const handleLoadBalanceToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateLoadBalanceFeature({
        ...loadBalanceFeature,
        enable: event.target.checked,
      }),
    );
  };

  const handleRateLimitingToggle = (event: any) => {
    dispatch(
      updateRateLimitingFeature({
        ...rateLimitingFeature,
        enable: event.target.checked,
      }),
    );
  };

  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          sx={{ gap: '0.5rem' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ResponsiveTypography>Rate Limiting</ResponsiveTypography>
            <Switch
              onChange={handleRateLimitingToggle}
              checked={rateLimitingFeature.enable}
            />
          </Box>
          <SubTitle>
            Set the maximum number of requests and the time limit.
          </SubTitle>
          <Box display="flex" alignItems="center" sx={{ gap: '0.5rem' }}>
            <StyledTextField
              size="small"
              label="Maximum Request"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateReduxRateLimiting({
                  ...rateLimitingFeature,
                  maxRequest: parseInt(event.target.value, 10),
                });
              }}
              value={rateLimitingFeature.maxRequest.toString()}
            />
            <StyledTextField
              size="small"
              label="Time Limit(in mins)"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateReduxRateLimiting({
                  ...rateLimitingFeature,
                  timeLimit: parseInt(event.target.value, 10),
                });
              }}
              value={rateLimitingFeature.timeLimit.toString()}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="90%"
            sx={{ gap: '0.5rem', backgroundColor: '#F6F6F6' }}
            p="1rem"
          >
            <Box display="flex" flexDirection="column">
              <SubTitle>Whitelisted Path(s)</SubTitle>
              <SubLabel>
                Static resource paths could be excluded from Rate Limiter.
              </SubLabel>
              <SubLabel style={{ color: 'red' }}>
                (Whitelisted paths will be matched on the basis of regExp. )
              </SubLabel>
            </Box>
            <Box display="flex" flexDirection="column" gap="0.75rem">
              <Box>
                <Button onClick={addFormFieldsRateLimiter}>
                  + Add whitelisted Paths
                </Button>
              </Box>
              <Box display="flex" flexDirection="column">
                {rateLimiterMembers.map((element: any, index: number) => (
                  <Box
                    display="flex"
                    sx={{ gap: '0.5rem', padding: '0.25rem' }}
                  >
                    <StyledTextField
                      id="outlined-basic"
                      size="small"
                      label="Eg.  /static-contents"
                      variant="outlined"
                      sx={{
                        backgroundColor: '#F6F6F6',
                        border: 'none !important',
                        width: '25rem',
                      }}
                      inputProps={{
                        style: {
                          fontSize: '0.938rem',
                        },
                      }}
                      value={element || ''}
                      onChange={(event: any) => handleChangeRateLimiter(index, event)}
                    />
                    <RemoveCircleIcon
                      fontSize="large"
                      sx={{ color: '#E34614', marginLeft: '2rem' }}
                      onClick={() => removeFieldsRateLimiter(index)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ResponsiveTypography>Load Balancing</ResponsiveTypography>
          <Switch
            onChange={handleLoadBalanceToggle}
            checked={loadBalanceFeature.enable}
          />
        </Box>
        <SubLabel>
          Load Balancing feature is used to ensure horizontal scaling of
          requests across the member servers. Your primary server address is
          also one of the participants in load distribution.
        </SubLabel>
        <Box display="flex" flexDirection="column" gap="0.75rem">
          <Box>
            <Button onClick={addFormFieldsLoadBalancer}>
              + Add Balancer Member
            </Button>
          </Box>
          <Box display="flex" flexDirection="column">
            {balancerMembers.map((element: any, index: number) => (
              <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  label="Eg.  https://<domain>.com or 23.30.3000"
                  variant="outlined"
                  sx={{
                    backgroundColor: '#F6F6F6',
                    border: 'none !important',
                    width: '25rem',
                  }}
                  inputProps={{
                    style: {
                      fontSize: '0.938rem',
                    },
                  }}
                  value={element || ''}
                  onChange={(event: any) => handleChangeLoadBalancer(index, event)}
                />
                <RemoveCircleIcon
                  fontSize="large"
                  sx={{ color: '#E34614', marginLeft: '2rem' }}
                  onClick={() => removeFieldsLoadBalancer(index)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoadBalancingRateLimiting;
