import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface DeleteModalProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function DeleteModal({
  open,
  title,
  message,
  onConfirm,
  onCancel,
}: DeleteModalProps) {
  const handleConfirm = () => {
    onConfirm();
  };
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      PaperProps={{
        sx: {
          padding: '0.5rem',
          borderRadius: '0.75rem',
          boxShadow: '0rem 0.5rem 1rem rgba(0,0,0,0.12)',
          maxWidth: '25rem',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#2e3b4e',
          paddingBottom: '0.5rem',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText
          id="confirm-dialog-description"
          sx={{ fontSize: '1rem', color: '#5a6771' }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '0.5rem',
          gap: '5rem',
        }}
      >
        <Button
          onClick={onCancel}
          sx={{
            backgroundColor: '#1976d2',
            color: 'white',
            padding: '0.375rem 1rem',
            fontSize: '0.875rem',
            textTransform: 'none',
            borderRadius: '0.5rem',
            '&:hover': {
              backgroundColor: '#1565c0',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{
            backgroundColor: '#d32f2f',
            color: 'white',
            padding: '0.375rem 1rem',
            fontSize: '0.875rem',
            textTransform: 'none',
            borderRadius: '0.5rem',
            marginLeft: '0.5rem',
            '&:hover': {
              backgroundColor: '#c62828',
            },
          }}
        >
          Revoke
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteModal;
