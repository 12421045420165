import React from 'react';
import { Box } from '@mui/material';
import Application from '../../Application/application';

function Office365() {
  return (
    <Box>
      <Application app={process.env.REACT_APP_OFFICE365_KEY!} />
    </Box>
  );
}

export default Office365;
