import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCookie } from '../utils/utility';
import Loading from '../assets/images/loading.webp';

function LoadingPage() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img
        src={Loading}
        alt="loading"
        style={{ marginBottom: '1rem', width: '6.25rem' }}
      />
      <Typography variant="h6" align="center">
        Loading...
      </Typography>
    </Container>
  );
}

function ProtectedRouteforDashboard({ children }: any) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/customer-login-status`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          const flag = res?.data?.flag;
          setIsLoggedIn(flag);
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_CUSTOMER_LOGIN_STATUS_ERROR);
        });
    };
    checkLoginStatus();
  }, []);

  if (isLoggedIn === null) {
    return <LoadingPage />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRouteforDashboard;
