import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Fade,
  InputAdornment,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RootState, AppDispatch } from '../../../../../Redux/store';
import { cyanBlueBackgroundButtonStyles } from '../../../../../styles/button';
import GroupConfiguration from './groupConfiguration';
import { StyledTextField } from '../../../../../styles/textField';
import { buttonIconStyles } from '../../../../../styles/icon';
import {
  getGroupApps,
  getConfiguredPolicies,
  resetGroupConfiguration,
  updateGroupConfiguration,
  updateClose,
} from '../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { deleteGroupConfiguration } from '../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import { handleSearch } from '../../../../../utils/Groups/groupUtility';

const tableCellStyle = {
  borderBottom: '0.063rem solid #D9D9D9',
  paddingBottom: 0,
  fontSize: { xl: '0.7rem', md: '0.6rem' },
  color: '#6B778C',
};

const tableRowCellStyle = {
  borderBottom: 'none',
  paddingBottom: 0,
  color: '#172B4D',
  fontSize: { xl: '0.8rem', md: '0.7rem' },
};

function GroupSettingsTable() {
  const dispatch = useDispatch<AppDispatch>();
  const { editScreen, configuredGroups, app } = useSelector(
    (state: RootState) => state.application,
  );
  const { allPolicies } = useSelector(
    (state: RootState) => state.groupSettings,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const [filteredGroup, setFilteredGroup] = useState(configuredGroups);
  const [policyMap, setPolicyMap] = useState<Record<string, string>>({});

  useEffect(() => {
    const idToIdentifierMap: Record<string, string> = allPolicies.reduce(
      (map, obj) => {
        map[obj._id] = obj.identifier;
        return map;
      },
      {},
    );

    setPolicyMap(idToIdentifierMap);
  }, [allPolicies]);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    sourceId: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(sourceId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openGroupConfigurationScreen = async () => {
    await dispatch(resetGroupConfiguration());
    await dispatch(updateClose(true));
  };

  const handleEditGroup = async (id: string) => {
    await configuredGroups.forEach(async (element: any) => {
      if (element._id === id) {
        await dispatch(updateGroupConfiguration(element));
        await dispatch(updateClose(true));
      }
    });
    setAnchorEl(null);
  };
  const handleDeleteGroup = async (id: string) => {
    await dispatch(deleteGroupConfiguration({ id, app }));
  };

  useEffect(() => {
    dispatch(getGroupApps());
    dispatch(getConfiguredPolicies());
  }, []);

  useEffect(() => {
    setFilteredGroup(configuredGroups);
  }, [configuredGroups]);

  const emptyRows = rowsPerPage
    - Math.min(rowsPerPage, configuredGroups.length - page * rowsPerPage);
  const pageCount = Math.ceil(filteredGroup.length / rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    newPage -= 1;
    setPage(newPage);
  };

  return (
    <Fade
      in={editScreen === 'GroupSettings'}
      {...(editScreen === 'GroupSettings' ? { timeout: 1000 } : {})}
    >
      <Box>
        <GroupConfiguration />
        <Box display="flex" justifyContent="space-between" p="1rem">
          <StyledTextField
            id="input-with-icon-textfield"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={buttonIconStyles} />
                </InputAdornment>
              ),
            }}
            onChange={(event: any) => handleSearch(
              event,
              configuredGroups,
              filteredGroup,
              setFilteredGroup,
            )}
            sx={{ width: '15rem' }}
          />
          <Button
            sx={{ ...cyanBlueBackgroundButtonStyles, width: '8rem' }}
            onClick={openGroupConfigurationScreen}
          >
            <AddIcon sx={{ ...buttonIconStyles, color: 'white' }} />
            &nbsp; Add New
          </Button>
        </Box>
        <TableContainer
          sx={{
            border: 'none',
            boxShadow: 'none',
            height: { xl: '55vh', md: '40vh' },
            overflow: 'auto',
          }}
        >
          <Table sx={{ border: 'none', boxShadow: 'none' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  padding="checkbox"
                  align="center"
                  sx={{ ...tableCellStyle, align: 'center' }}
                >
                  <Checkbox
                    size="small"
                    color="primary"
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell sx={tableCellStyle}>Identifiers</TableCell>
                <TableCell sx={tableCellStyle}>Description</TableCell>
                <TableCell sx={tableCellStyle}>Policy</TableCell>
                <TableCell align="right" sx={tableCellStyle}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroup
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((group: any) => (
                  <TableRow key={group._id}>
                    <TableCell sx={tableRowCellStyle}>
                      <Checkbox
                        size="small"
                        color="primary"
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </TableCell>
                    <TableCell sx={tableRowCellStyle}>
                      {group.identifier}
                    </TableCell>
                    <TableCell sx={tableRowCellStyle}>
                      {group.description}
                    </TableCell>
                    <TableCell sx={tableRowCellStyle}>
                      {policyMap[group.policy_id]}
                    </TableCell>
                    <TableCell align="right" sx={tableRowCellStyle}>
                      <IconButton
                        onClick={(event) => handleMenuOpen(event, group._id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && currentId === group._id}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            boxShadow: 'none',
                            border: '0.063rem solid #E3E3E3',
                          },
                        }}
                      >
                        <MenuItem
                          disabled={
                            currentId === group._id
                            && group.identifier === 'default'
                          }
                          onClick={() => handleEditGroup(group._id)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          disabled={
                            currentId === group._id
                            && group.identifier === 'default'
                          }
                          onClick={() => handleDeleteGroup(group._id)}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {filteredGroup.length > 0 && (
            <Pagination
              size="small"
              count={pageCount > 0 ? pageCount : 1}
              page={page + 1}
              onChange={handleChangePage}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  fontSize: { xl: '0.938rem', lg: '0.625rem' },
                },
                paddingTop: '1rem',
              }}
            />
          )}
        </TableContainer>
      </Box>
    </Fade>
  );
}

export default GroupSettingsTable;
