import axios from 'axios';
import toast from 'react-hot-toast';
import { isValidDomain, isValidURL, getCookie } from '../../../utils/utility';

type BasicSettings = {
  identifier: string;
  domain: string;
  attributeKey: string;
  nameAttributeKey: string;
  acsURL: string;
  acsEntityURL: string;
  casbType: 'offline' | string;
  auditing: boolean;
  mdm: boolean;
  nameIDFormat: string;
  casb: boolean;
  multistaff: boolean;
  shopifyUsers: { username: string; password: string }[];
};

class AppStateFactory {
  static async updateBasicApplicationSettings(
    app: string,
    basicSettings: BasicSettings,
    authenticationID: string,
    step: string,
  ) {
    switch (app) {
      case process.env.REACT_APP_OFFICE365_KEY:
      case process.env.REACT_APP_GOOGLE_KEY:
      case process.env.REACT_APP_ATLASSIAN_KEY:
      case process.env.REACT_APP_GITHUB_KEY:
        if (
          !this.isValidInput(basicSettings, ['identifier', 'domain', 'acsURL'])
        ) {
          toast.error('Empty Input Fields');
        } else if (!this.isValidDomainAndURL(basicSettings)) {
          toast.error('URLs provided are not valid.');
        } else {
          await this.updateProxyConfiguration(
            basicSettings,
            authenticationID,
            app,
          );
          step = process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? '';
        }
        break;

      case process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY:
        if (!this.isValidInput(basicSettings, ['identifier', 'domain'])) {
          toast.error('Empty Input Fields');
        } else if (!isValidDomain(basicSettings.domain)) {
          toast.error('Invalid Domain provided.');
        } else {
          await this.updateProxyConfigurationForShopifyNonPlus(
            basicSettings,
            authenticationID,
            app,
          );
          if (step === process.env.REACT_APP_BASIC_SETTINGS_SCREEN) {
            step = process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? '';
          } else if (step === process.env.REACT_APP_GROUP_SETTINGS_SCREEN) {
            step = process.env.REACT_APP_CONFIGURATION_COMPLETED ?? '';
          }
        }
        break;

      case process.env.REACT_APP_SHOPIFY_PLUS_KEY:
        if (
          !this.isValidInput(basicSettings, ['identifier', 'domain', 'acsURL'])
        ) {
          toast.error('Empty Input Fields');
        } else if (!this.isValidDomainAndURL(basicSettings)) {
          toast.error('URLs provided are not valid.');
        } else {
          await this.updateProxyConfigurationForShopifyPlus(
            basicSettings,
            authenticationID,
            app,
          );
          step = process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? '';
        }
        break;

      default:
        break;
    }

    return step;
  }

  static async updateProxyConfiguration(
    basicSettings: BasicSettings,
    authenticationID: string,
    app: string,
  ) {
    const token = await getCookie('token');
    const data = {
      identifier: basicSettings.identifier,
      organization_domain: basicSettings.domain,
      authentication_id: authenticationID,
      acs_url: basicSettings.acsURL,
      entity_id_url: basicSettings.acsEntityURL,
      attribute_key: basicSettings.attributeKey,
      name_attribute_key: basicSettings.nameAttributeKey,
      mdm: basicSettings.mdm,
      auditing: basicSettings.auditing,
      casb_type: basicSettings.casbType,
      app,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        data,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      toast.success('Basic Settings Configured');
    } catch (error) {
      toast.error('Unable to update Proxy');
    }
  }

  static async updateProxyConfigurationForShopifyNonPlus(
    basicSettings: BasicSettings,
    authenticationID: string,
    app: string,
  ) {
    const token = await getCookie('token');
    const data = {
      identifier: basicSettings.identifier,
      organization_domain: basicSettings.domain,
      authentication_id: authenticationID,
      attribute_key: basicSettings.attributeKey,
      multistaff: basicSettings.multistaff,
      auditing: basicSettings.auditing,
      casb: basicSettings.casb,
      user_details: basicSettings.shopifyUsers,
      app,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        data,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      toast.success('Basic Settings Configured');
    } catch (error) {
      toast.error('Unable to update Proxy');
    }
  }

  static async updateProxyConfigurationForShopifyPlus(
    basicSettings: BasicSettings,
    authenticationID: string,
    app: string,
  ) {
    const token = await getCookie('token');
    const data = {
      identifier: basicSettings.identifier,
      organization_domain: basicSettings.domain,
      authentication_id: authenticationID,
      acs_url: basicSettings.acsURL,
      entity_id_url: basicSettings.acsEntityURL,
      attribute_key: basicSettings.attributeKey,
      name_attribute_key: basicSettings.nameAttributeKey,
      mdm: basicSettings.mdm,
      auditing: basicSettings.auditing,
      casb_type: basicSettings.casbType,
      name_id_format: basicSettings.nameIDFormat,
      app,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        data,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      toast.success('Basic Settings Configured');
    } catch (error) {
      toast.error('Unable to update Proxy');
    }
  }

  static isValidInput(
    basicSettings: BasicSettings,
    requiredFields: (keyof BasicSettings)[],
  ) {
    return requiredFields.every((field) => basicSettings[field] !== '');
  }

  static isValidDomainAndURL(basicSettings: BasicSettings) {
    return (
      isValidDomain(basicSettings.domain) && isValidURL(basicSettings.acsURL)
    );
  }
}

export { AppStateFactory };
