import { styled } from '@mui/material/styles';

export const ResponsiveImage = styled('img')(({ theme }: any) => ({
  [theme.breakpoints.up('md')]: {
    height: '2rem',
  },
  [theme.breakpoints.up('xl')]: {
    height: '2.2rem',
  },
}));
