import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getCookie } from '../../../../utils/utility';
import { updateUserSession } from './sessionManagement';

export const getUserSession = createAsyncThunk(
  'getUserSession',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    const { app } = state.application;
    await axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-user-session`,
        { app },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(async (response) => {
        const transformedData = response.data.map((group: any) => ({
          ...group,
          users: group.users.map((user: any) => ({
            ...user,
            login_timestamp: new Date(
              user.login_timestamp * 1000,
            ).toLocaleString(),
            session_validity: new Date(
              user.session_validity * 1000,
            ).toLocaleString(),
            status:
              user.session_validity > user.login_timestamp
                ? 'Active'
                : 'Inactive',
          })),
        }));
        await thunkAPI.dispatch(updateUserSession(transformedData));
      })
      .catch((error: any) => {
        if (error) {
          toast.error(error.response.data.message);
        }
      });
  },
);

export const revokeUserSession = createAsyncThunk(
  'revokeUserSession',
  async (selectedUsers: string[], thunkAPI) => {
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    const { app } = state.application;
    await axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/revoke-user-session`,
        { app, uuid: selectedUsers },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(async (response) => {
        const transformedData = response.data.map((group: any) => ({
          ...group,
          users: group.users.map((user: any) => ({
            ...user,
            login_timestamp: new Date(
              user.login_timestamp * 1000,
            ).toLocaleString(),
            session_validity: new Date(
              user.session_validity * 1000,
            ).toLocaleString(),
            status:
              user.session_validity > user.login_timestamp
                ? 'Active'
                : 'Inactive',
          })),
        }));
        await thunkAPI.dispatch(updateUserSession(transformedData));
      })
      .catch((error: any) => {
        if (error) {
          toast.error(error.response.data.message);
        }
      });
  },
);
