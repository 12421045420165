import React, { useState, useEffect } from 'react';
import {
  Box, Switch, MenuItem, Select,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateAuthentication } from '../../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../../Redux/store';
import { getCookie } from '../../../../utils/utility';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { SubLabel } from '../../../../styles/sublabel';
import { StyledTextField } from '../../../../styles/textField';
import { SubTitle } from '../../../../styles/subtitle';

function Authentication() {
  const dispatch = useDispatch();
  const authenticationState = useSelector(
    (state: RootState) => state.proxySettings.Authentication,
  );
  const [authenticationSources, setAuthenticationSources] = useState<any[]>([]);
  const [selectedSource, setSelectedSource] = useState('');
  useEffect(() => {
    async function getAuthenticationSources() {
      const token = getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAuthenticationSources(res.data.authentication_source);
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }

    getAuthenticationSources();
  }, []);
  useEffect(() => {
    const selected = authenticationSources.find(
      (source) => source._id === authenticationState.authenticationID,
    );
    if (selected) {
      setSelectedSource(selected.identifier);
    }
  }, [authenticationSources, authenticationState.authenticationID]);

  const selectAuthenticationSource = (event: any) => {
    const selected = authenticationSources.find(
      (source) => source.identifier === event.target.value,
    );
    if (selected) {
      setSelectedSource(selected.identifier);
      dispatch(
        updateAuthentication({
          authenticationID: selected._id,
          redirectAfterAuthentication:
            authenticationState.redirectAfterAuthentication,
          enable: authenticationState.enable,
        }),
      );
    }
  };
  const handleAuthenticationToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateAuthentication({
        authenticationID: authenticationState.authenticationID,
        redirectAfterAuthentication:
          authenticationState.redirectAfterAuthentication,
        enable: event.target.checked,
      }),
    );
  };

  const handleRedirectAfterAuthenticationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateAuthentication({
        authenticationID: authenticationState.authenticationID,
        redirectAfterAuthentication: event.target.value,
        enable: authenticationState.enable,
      }),
    );
  };
  return (
    <Box display="flex" flexDirection="column" sx={{ gap: '0.5rem' }}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ResponsiveTypography>Authentication</ResponsiveTypography>
          <Switch
            checked={authenticationState.enable}
            onChange={handleAuthenticationToggle}
          />
        </Box>
        <SubLabel>
          Enable the authentication feature for your proxy URL. You are also
          required to set the maximum number of requests and the time limit.
        </SubLabel>
      </Box>
      <Box display="flex" sx={{ gap: '5rem' }} alignItems="center" py="1rem">
        <SubTitle>Select Authentication</SubTitle>
        <Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            sx={{ width: '30rem', height: '2.2rem' }}
            onChange={selectAuthenticationSource}
            value={selectedSource}
          >
            {authenticationSources.map((source) => (
              <MenuItem value={source.identifier}>{source.identifier}</MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" py="1rem" sx={{ gap: '1.5rem' }}>
        <SubTitle>Redirect after Authentication</SubTitle>
        <StyledTextField
          size="small"
          sx={{ width: '40%' }}
          value={authenticationState.redirectAfterAuthentication}
          onChange={handleRedirectAfterAuthenticationChange}
        />
      </Box>
    </Box>
  );
}

export default Authentication;
