import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Fade, Grid, MenuItem,
} from '@mui/material';
import { AppDispatch, RootState } from '../../../../../Redux/store';
import { ResponsiveTypography } from '../../../../../styles/inputLabel';
import BasicSettings from '../../../Configuration/BasicSettings/Common/basicSettings';
import { StyledSelect } from '../../../../../styles/select';
import { updateApplicationAuthenticationID } from '../../../../../Redux/Slices/Application/appSlice';

interface BasicSettingsProps {
  appName?: string;
  isEdit?: boolean;
  buttonText?: string;
}

function CommonBasicSettings({
  appName = '',
  isEdit = true,
}: BasicSettingsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { editScreen, authenticationID } = useSelector(
    (state: RootState) => state.application,
  );
  const { configuredAuthenticationSource } = useSelector(
    (state: RootState) => state.authenticationSource,
  );
  const buttonText = isEdit ? 'Save' : 'Save and Next';
  return (
    <Fade
      in={editScreen === 'BasicSettings'}
      {...(editScreen === 'BasicSettings' ? { timeout: 1000 } : {})}
    >
      <Box sx={{ paddingTop: { xl: '1.5rem', md: '1rem' } }}>
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            sx={{
              rowGap: { xl: '1rem', lg: '0.5rem' },
              paddingBottom: { xl: '1rem', lg: '0.5rem' },
            }}
          >
            <Grid item xs={5}>
              <ResponsiveTypography>Authentication Source</ResponsiveTypography>
            </Grid>
            <Grid item xs={7}>
              <StyledSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                name="authentication_id"
                label=""
                value={authenticationID}
                onChange={(event: any) => dispatch(
                  updateApplicationAuthenticationID(event.target.value),
                )}
              >
                {configuredAuthenticationSource?.map((source) => (
                  <MenuItem key={source?._id} value={source?._id}>
                    {source.identifier}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
          </Grid>
          <BasicSettings appName={appName} buttonText={buttonText} />
        </Box>
      </Box>
    </Fade>
  );
}

CommonBasicSettings.defaultProps = {
  appName: '',
  buttonText: 'Save',
  isEdit: true,
};

export default CommonBasicSettings;
