import React, { useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BasicSettings from '../../Common/basicSettings';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { AppDispatch, RootState } from '../../../../../../Redux/store';
import { StyledSelect, menuItemCSS } from '../../../../../../styles/select';
import { updateNameIDFormat } from '../../../../../../Redux/Slices/Application/BasicSettings/basicSettingsSlice';

function ShopifyPlusBasicSettings() {
  const [allNameIdFormat] = useState<any[]>([
    'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
    'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
    'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent',
    'urn:oasis:names:tc:SAML:2.0:nameid-format:transient',
  ]);
  const dispatch = useDispatch<AppDispatch>();
  const { nameIDFormat } = useSelector(
    (state: RootState) => state.basicSettings,
  );
  return (
    <>
      <Grid
        container
        sx={{ rowGap: { xl: '1rem', lg: '0.5rem' }, paddingBottom: { xl: '1rem', lg: '0.5rem' } }}
      >
        <Grid item xs={5}>
          <ResponsiveTypography>Name ID Format</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledSelect
            fullWidth
            name="nameIdFormat"
            value={nameIDFormat}
            onChange={(event: any) => dispatch(updateNameIDFormat(event.target.value))}
          >
            {allNameIdFormat?.map((source: string) => (
              <MenuItem key={source} value={source} sx={menuItemCSS}>
                {source}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <BasicSettings />
    </>
  );
}

export default ShopifyPlusBasicSettings;
