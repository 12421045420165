import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Box, Grid, Fade, Chip, useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../components/MainLayout';
import { AppDispatch, RootState } from '../../Redux/store';
import { apps } from '../Application/app';
import { ResponsiveImage } from '../../styles/app-logo';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { ResponsiveHeading } from '../../styles/heading';
import { SmallHeading } from '../../styles/smallHeading';
import { ResponsiveSubHeading } from '../../styles/subHeading';
import { getSessionDetails } from '../../Redux/Slices/Application/applicationHandler';
import { getAllPolicies } from '../../Redux/Slices/Policy/policyHandler';
import { CustomInputLabel } from '../../styles/customInputLabel';

interface DailyData {
  date: string;
  users: number;
}
function SessionInsight({ appSessionsMap }: any) {
  const [activeSessions, setActiveSessions] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);

  const isSmallScreen = useMediaQuery('(max-width: 37.5rem)');
  const isMediumScreen = useMediaQuery('(max-width: 75rem)');

  useEffect(() => {
    if (!appSessionsMap) return;
    let active = 0;
    let total = 0;

    Object.keys(appSessionsMap).forEach((key) => {
      if (Array.isArray(appSessionsMap[key])) {
        appSessionsMap[key].forEach((item: any) => {
          active += item.activeSessions;
          total += item.users.length;
        });
      }
    });

    setActiveSessions(active);
    setTotalSessions(total);
  }, [appSessionsMap]);

  const chartWidth = isSmallScreen ? 200 : isMediumScreen ? 300 : 350;
  const labels = isMediumScreen
    ? ['Active', 'Inactive']
    : isSmallScreen
      ? ['Active Sessions', 'Inactive Sessions']
      : ['Active Session', 'Inactive Session'];
  const state = {
    series: [activeSessions, totalSessions - activeSessions],
    options: {
      chart: {
        type: 'donut',
      },
      labels,
      responsive: [
        {
          breakpoint: 1400,
          options: {
            chart: {
              width: 450,
              height: 180,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 300,
              height: 160,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              width: 200,
              height: 110,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 900,
          options: {
            chart: {
              width: 200,
              height: 1000,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '10vw',
            paddingLeft: '0.625rem',
            height: {
              xs: '30%',
              sm: '25%',
              md: '100%',
              lg: '100%',
              xl: '100%',
            },
          }}
        >
          <Chart
            options={state.options}
            series={state.series}
            type="donut"
            width={chartWidth}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={{ md: '0.5rem', lg: '1rem' }}
      >
        <ResponsiveTypography>
          Total Sessions:
          {totalSessions}
        </ResponsiveTypography>
      </Box>
    </>
  );
}

function Policies({ allPolicies }: any) {
  return (
    <Box
      my={{
        xl: '1rem',
        lg: '1rem',
        md: '0.5rem',
      }}
      sx={{
        height: {
          xl: '38vh',
          lg: '35vh',
          md: '30vh',
        },
        overflow: 'auto',
      }}
    >
      <Grid container spacing="0.5rem">
        <Grid item xs={12} sm={6} md={3} lg={3} xl={4} py="0.5rem">
          <ResponsiveTypography color="#87888C">
            Policy Name
          </ResponsiveTypography>
        </Grid>

        <Grid item xs={12} sm={6} md={9} lg={9} xl={8} py="0.5rem">
          <ResponsiveTypography color="#87888C">
            Restriction Type
          </ResponsiveTypography>
        </Grid>

        {allPolicies?.slice(0, 5).map((policy: any) => {
          let restrictions = 0;
          const restrictionChips = [];
          if (policy.ip_based_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="IP Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.time_based_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="Time Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.file_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="File Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.location_based_restriction.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="Location Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.file_based_restriction.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="File Based"
                color="primary"
                size="small"
              />,
            );
          }

          if (policy.clipboard_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="Clipboard Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.session_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '0.063rem' }}
                label="Session Based"
                color="primary"
                size="small"
              />,
            );
          }

          return (
            <React.Fragment key={policy._id}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={4} py="0.5rem">
                <ResponsiveTypography>{policy.identifier}</ResponsiveTypography>
              </Grid>
              <Grid item xs={12} sm={6} md={9} lg={9} xl={8} py="0.5rem">
                {restrictionChips.slice(0, 2).map((chip) => (
                  <React.Fragment key={policy._id}>{chip}</React.Fragment>
                ))}
                {restrictions > 2 && (
                  <Chip
                    sx={{ marginX: '0.063rem' }}
                    label={`+${restrictions - 2} more`}
                    color="primary"
                    size="small"
                  />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}

function LineChart({ loginDateMap }: any) {
  const [dailyData, setDailyData] = useState<DailyData[]>([]);
  useEffect(() => {
    if (!loginDateMap) return;

    const dailyDataArray: DailyData[] = Object.entries(loginDateMap).map(
      ([date, users]) => ({
        date,
        users: users as number,
      }),
    );
    setDailyData(dailyDataArray);
  }, [loginDateMap]);

  const series = [
    {
      name: 'Users',
      data: dailyData.map((data) => data.users),
    },
  ];

  const options = {
    chart: {
      type: 'line',
    },
    xaxis: {
      categories: dailyData.map((data) => new Date(data.date).toLocaleDateString()),
    },
    fill: {
      type: 'solid',
      opacity: 0.5,
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      fill={{
        type: 'solid',
        opacity: 0.5,
      }}
      width="100%"
      height="86%"
    />
  );
}

function Dashboard() {
  const navigate = useNavigate();
  const { sessionDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const { allPolicies } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getSessionDetails());
    dispatch(getAllPolicies());
  }, []);

  return (
    <MainLayout>
      <Box display="flex" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          p={3}
          sx={{
            width: {
              xs: '90%',
              sm: '80%',
              md: '70%',
              lg: '80%',
              xl: '66%',
            },
            height: {
              xl: '90%',
              lg: '80%',
              md: '60%',
            },
          }}
        >
          <ResponsiveHeading>Dashboard</ResponsiveHeading>
          <Fade in timeout={1000}>
            <Box display="flex" gap="1rem">
              <Grid container spacing="1rem" py="1.5rem">
                {Object.values(apps)
                  .slice(0, 5)
                  .map((app) => (
                    <Grid item xs={2} key={app.title}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          position: 'relative',
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '100%',
                            lg: '100%',
                            xl: '100%',
                          },
                          height: {
                            xl: '10rem',
                            lg: '8rem',
                            md: '5rem',
                          },
                          borderRadius: '0.625rem',
                          border: '0.063rem solid #D9D9D9',
                          paddingY: '1rem',
                          paddingX: '1.5rem',
                          boxShadow: '0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)',
                          cursor: 'pointer',
                          overflow: 'hidden',
                          transition: 'box-shadow 0.3s ease, border 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-0.25rem)',
                            boxShadow:
                              '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.15)',
                          },
                        }}
                        onClick={() => navigate(app.path)}
                        gap="1rem"
                      >
                        <ResponsiveImage
                          src={app.smallLogo}
                          alt={app.title}
                          sx={{
                            transition: 'transform 0.7s ease',
                            transformOrigin: 'center',
                          }}
                        />

                        <CustomInputLabel
                          className="title"
                          sx={{
                            transition: 'transform 0.7s ease',
                          }}
                        >
                          {app.title}
                        </CustomInputLabel>
                      </Box>
                    </Grid>
                  ))}

                <Grid item xs={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      borderRadius: '0.625rem',
                      border: '0.063rem solid #D9D9D9',
                      paddingY: '1rem',
                      paddingX: '1.5rem',
                      boxShadow: '0rem 0rem 0.625ren rgba(0, 0, 0, 0.05)',
                      cursor: 'pointer',
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '100%',
                        xl: '100%',
                      },
                      height: {
                        xl: '10rem',
                        lg: '8rem',
                        md: '5rem',
                      },
                      '&:hover': {
                        transform: 'translateY(-0.25rem)',
                        boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.15)',
                      },
                    }}
                    onClick={() => navigate('/applications')}
                    height="10rem"
                    gap="1rem"
                  >
                    <ResponsiveTypography>View All</ResponsiveTypography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
          <Fade in timeout={1000}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%',
                },
                height: {
                  md: '51vh',
                  lg: '50vh',
                  xl: '53vh',
                },
                borderRadius: '0.625rem',
                border: '0.063rem solid #D9D9D9',
                paddingY: '1rem',
                paddingX: {
                  xs: '1rem',
                  sm: '1.5rem',
                  md: '2rem',
                },
                boxShadow: '0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)',
              }}
            >
              <SmallHeading>Session Overview</SmallHeading>
              <ResponsiveSubHeading pt={{ xl: '0.5rem' }}>
                Based on Configured Apps.
              </ResponsiveSubHeading>
              <LineChart loginDateMap={sessionDetails?.loginDateMap} />
            </Box>
          </Fade>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="30%"
          my={{ xl: '2rem', lg: '2.25rem', md: '2.25rem' }}
          marginRight="1.5rem"
          gap={{ xl: '1rem', md: '0.7rem' }}
        >
          <Box sx={{ color: 'transparent' }}>
            <ResponsiveHeading>.</ResponsiveHeading>
          </Box>
          <Fade in timeout={1000}>
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="0.625rem"
              border="0.063rem solid #D9D9D9"
              height={{ md: '11.25rem', lg: '15rem', xl: '17.188rem' }}
              p={{
                xl: '1rem',
                md: '0.5rem',
                lg: '0.75rem',
              }}
              boxShadow="0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)"
            >
              <SmallHeading>Session Insight</SmallHeading>
              <SessionInsight appSessionsMap={sessionDetails?.appSessionsMap} />
            </Box>
          </Fade>
          <Fade in timeout={1000}>
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="0.625rem"
              p={{
                xl: '1rem',
                md: '0.35rem',
                lg: '0.75rem',
              }}
              height={{ md: '35vh', lg: '35vh', xl: '39vh' }}
              border="0.063rem solid #D9D9D9"
              boxShadow="0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)"
            >
              <SmallHeading>Policies</SmallHeading>
              <Policies allPolicies={allPolicies} />
            </Box>
          </Fade>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default Dashboard;
