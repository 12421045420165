import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Box,
  MenuItem,
  Button,
  Checkbox,
  Pagination,
  Fade,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CachedIcon from '@mui/icons-material/Cached';
import {
  zeroPaddingAutoWidthButton,
  lightBackgroundButtonStyles,
  cyanBlueBackgroundButtonStyles,
} from '../../../../styles/button';
import { AppDispatch, RootState } from '../../../../Redux/store';
import {
  getUserSession,
  revokeUserSession,
} from '../../../../Redux/Slices/Application/SessionManagement/sessionHandler';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import { SubLabel } from '../../../../styles/sublabel';
import { menuItemCSS, StyledSelect } from '../../../../styles/select';
import { StyledTextField } from '../../../../styles/textField';
import { buttonIconStyles } from '../../../../styles/icon';
import DeleteModal from '../../../../components/modal/deleteModal';

function SessionManagement() {
  const { editScreen } = useSelector((state: RootState) => state.application);
  const { session } = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getUserSession());
  }, []);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState('group');
  const [filteredSession, setFilteredSession] = useState(session);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [dropdownValue, setDropdownValue] = useState('none');
  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchBy(e.target.value);
  };
  const handleCheckboxChange = (event: any, userUuid: string) => {
    if (event.target.checked) {
      setSelectedUsers([...selectedUsers, userUuid]);
    } else {
      setSelectedUsers(selectedUsers.filter((uuid) => uuid !== userUuid));
    }
  };
  const handleDropdownChange = (e: any) => {
    setDropdownValue(e.target.value);
  };
  useEffect(() => {
    setFilteredSession(session);
  }, [session]);

  const handleSearch = () => {
    if (searchQuery === '') {
      setFilteredSession(session);
    } else if (searchBy === 'group') {
      const filteredData = session.filter((group: any) => group.identifier.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredSession(filteredData);
    } else if (searchBy === 'identifier') {
      const filteredData = session.filter((group: any) => group.users.some((user: any) => user.email.toLowerCase().includes(searchQuery.toLowerCase())));
      setFilteredSession(filteredData);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedUsers(
        [...filteredSession].reduce(
          (acc: string[], usersession: any) => [
            ...acc,
            ...(usersession.users || []).map((user: any) => user.uuid_key),
          ],
          [],
        ),
      );
    } else {
      setSelectedUsers([]);
    }
  };
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredSession.length);
  const paginatedData = filteredSession.slice(startIndex, endIndex);
  const handleApply = () => {
    setOpenDialog(true);
  };

  const handleConfirmRevoke = () => {
    setOpenDialog(false);
    dispatch(revokeUserSession(selectedUsers));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Fade
      in={editScreen === 'SessionManagement'}
      {...(editScreen === 'SessionManagement' ? { timeout: 1000 } : {})}
    >
      <Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" gap="1rem" pt={2} alignItems="center">
            <StyledTextField
              sx={{ width: '12rem' }}
              placeholder="Search your Query"
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
            <StyledSelect
              sx={{ width: '8rem' }}
              value={searchBy}
              onChange={handleSearchByChange}
            >
              <MenuItem value="group" sx={menuItemCSS}>
                <SubLabel>Group Name</SubLabel>
              </MenuItem>
              <MenuItem value="identifier" sx={menuItemCSS}>
                <SubLabel>Identifier</SubLabel>
              </MenuItem>
            </StyledSelect>
            <Button
              sx={{ ...lightBackgroundButtonStyles }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
          <Box display="flex" gap="1rem" pt={2} alignItems="center">
            <StyledSelect
              sx={{ width: '9rem' }}
              value={dropdownValue}
              onChange={handleDropdownChange}
              displayEmpty
            >
              <MenuItem value="none" disabled sx={menuItemCSS}>
                <SubLabel>Select Action</SubLabel>
              </MenuItem>
              <MenuItem value="revoke" sx={menuItemCSS}>
                <SubLabel>Revoke Session</SubLabel>
              </MenuItem>
            </StyledSelect>
            <Button
              sx={{ ...cyanBlueBackgroundButtonStyles }}
              onClick={handleApply}
              disabled={dropdownValue === 'none' || selectedUsers.length === 0}
            >
              Apply
            </Button>
            <Button
              size="small"
              startIcon={<CachedIcon sx={buttonIconStyles} />}
              onClick={() => dispatch(getUserSession())}
            >
              Reload
            </Button>
          </Box>
        </Box>
        <DeleteModal
          open={openDialog}
          title="Confirm Session Revoke"
          message="Are you sure you want to revoke the selected user sessions?"
          onConfirm={handleConfirmRevoke}
          onCancel={handleCloseDialog}
        />
        <TableContainer
          sx={{
            border: 'none',
            boxShadow: 'none',
            height: { xl: '58.5vh', lg: '50vh' },
            overflow: 'auto',
            paddingTop: 2,
          }}
        >
          <Table sx={{ border: 'none', boxShadow: 'none' }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={zeroPaddingAutoWidthButton}>
                  <Checkbox size="small" onChange={handleSelectAll} />
                </TableCell>
                <TableCell align="left" sx={zeroPaddingAutoWidthButton}>
                  <SubLabel>Group Name</SubLabel>
                </TableCell>
                <TableCell align="left" sx={zeroPaddingAutoWidthButton}>
                  <SubLabel>Identifier</SubLabel>
                </TableCell>
                <TableCell sx={zeroPaddingAutoWidthButton} align="center">
                  <SubLabel>Status</SubLabel>
                </TableCell>
                <TableCell sx={zeroPaddingAutoWidthButton} align="center">
                  <SubLabel>Login Timestamp</SubLabel>
                </TableCell>
                <TableCell sx={zeroPaddingAutoWidthButton} align="center">
                  <SubLabel> Session Validity</SubLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {paginatedData.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <NoRecordsFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableBody>
              {paginatedData.map((group: any) => group.users.map((user: any) => (
                <TableRow key={user.uuid_key}>
                  <TableCell align="left" sx={zeroPaddingAutoWidthButton}>
                    <Checkbox
                      size="small"
                      checked={selectedUsers?.includes(
                          user.uuid_key as string,
                      )}
                      onChange={(event: any) => handleCheckboxChange(event, user.uuid_key as string)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      ...zeroPaddingAutoWidthButton,
                      paddingY: '0.5rem !important',
                    }}
                  >
                    <SubLabel>{group.identifier}</SubLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...zeroPaddingAutoWidthButton,
                      paddingY: '0.5rem !important',
                    }}
                  >
                    <SubLabel>{user.email}</SubLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      ...zeroPaddingAutoWidthButton,
                      paddingY: '0.5rem !important',
                    }}
                  >
                    <Chip
                      label={user.status}
                      size="small"
                      sx={{
                        color:
                            user.status === 'Active' ? '#00A233' : '#DE3737',
                        backgroundColor:
                            user.status === 'Active' ? '#DFF0E5' : '#F4EBEB',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      ...zeroPaddingAutoWidthButton,
                      paddingY: '0.5rem !important',
                    }}
                  >
                    <SubLabel>{user.login_timestamp}</SubLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      ...zeroPaddingAutoWidthButton,
                      paddingY: '0.5rem !important',
                    }}
                  >
                    <SubLabel>{user.session_validity}</SubLabel>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(filteredSession.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePaginationChange}
          size="small"
        />
      </Box>
    </Fade>
  );
}

export default SessionManagement;
