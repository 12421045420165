import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Button, Switch, Typography,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { cyanBlueBackgroundButtonStyles } from '../../../../../../styles/button';
import { StyledTextField } from '../../../../../../styles/textField';
import {
  updateApplicationIdentifier,
  updateAttributeKey,
  updateDomain,
  updateEnableCASB,
  updateEnableMultistaff,
  updateEnableAuditing,
} from '../../../../../../Redux/Slices/Application/BasicSettings/basicSettingsSlice';
import { updateBasicSettings } from '../../../../../../Redux/Slices/Application/BasicSettings/updateApplicationHandler';
import { AppDispatch, RootState } from '../../../../../../Redux/store';
import { isValidDomain } from '../../../../../../utils/utility';
import { buttonIconStyles } from '../../../../../../styles/icon';
import Cancel from '../../../../../../components/cancelButton';

function ShopifyBasicSettings() {
  const dispatch = useDispatch<AppDispatch>();
  const [url, setUrl] = useState('');
  const {
    identifier,
    domain,
    attributeKey,
    casb,
    multistaff,
    auditing,
  } = useSelector((state: RootState) => state.basicSettings);
  const { authenticationID } = useSelector(
    (state: RootState) => state.application,
  );
  const { currentActiveTenantID, customerID } = useSelector(
    (state: RootState) => state.customer,
  );

  useEffect(() => {
    setUrl(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${customerID}/${currentActiveTenantID}/${authenticationID}/samllogin?app_name=${process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY}&RelayState=${process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY}`,
    );
  }, [customerID, currentActiveTenantID]);
  return (
    <>
      <Box>
        <Grid container sx={{ rowGap: { xl: '1rem', lg: '0.5rem' } }}>
          <Grid item xs={5}>
            <ResponsiveTypography>Application Name</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '0.938rem',
                },
              }}
              name="identifier"
              onChange={(e: any) => dispatch(updateApplicationIdentifier(e.target.value))}
              value={identifier}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Extension URL</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              disabled
              InputProps={{
                endAdornment: (
                  <CopyToClipboard text={url}>
                    <ContentCopyIcon
                      sx={{
                        backgroundColor: 'white !important',
                        padding: '0.313rem',
                        cursor: 'pointer !important',
                      }}
                      onClick={() => toast.success('Copied URL')}
                    />
                  </CopyToClipboard>
                ),
              }}
              value={url}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Organization Domain</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                name="domain"
                onChange={(e: any) => dispatch(updateDomain(e.target.value))}
                value={domain}
              />
              <Typography
                display={!domain || isValidDomain(domain) ? 'none' : 'flex'}
                sx={{ fontSize: '0.625rem', color: 'red' }}
              >
                Please provide a valid domain.
              </Typography>
            </>
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Attribute Key</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '0.938rem',
                },
              }}
              name="attributeKey"
              onChange={(e: any) => dispatch(updateAttributeKey(e.target.value))}
              value={attributeKey}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Enable Auditing</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <Switch
              checked={auditing}
              onChange={(event: any) => dispatch(updateEnableAuditing(event.target.checked))}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Enable CASB</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <Switch
              checked={casb}
              onChange={(event: any) => dispatch(updateEnableCASB(event.target.checked))}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography>Enable Multistaff</ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <Switch
              checked={multistaff}
              onChange={(event: any) => dispatch(updateEnableMultistaff(event.target.checked))}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" width="100%" mt="1rem" gap="1rem">
        <Cancel />
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(updateBasicSettings())}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </>
  );
}

export default ShopifyBasicSettings;
