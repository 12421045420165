import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getCookie } from '../../../../../utils/utility';
import { AppGroupStateFactory } from '../class-group-factory';
import { getAllGroups, redirectToEditScreen } from '../../appSlice';
import { updateClose } from './group';

export const configureNewGroup = createAsyncThunk(
  'configureNewGroup',
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { app } = state.application;
    try {
      const step = await AppGroupStateFactory.updateGroupApplicationSettings(
        thunkAPI.dispatch,
        app,
        state.groupSettings,
      );
      if (
        step === process.env.REACT_APP_CONFIGURATION_COMPLETED
        && app !== process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY
      ) {
        await thunkAPI.dispatch(redirectToEditScreen(true));
      }
      await thunkAPI.dispatch(getAllGroups());
      await thunkAPI.dispatch(updateClose(false));
      return step;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const deleteGroupConfiguration = createAsyncThunk(
  'groups/deleteGroupConfiguration',
  async (
    { id, app }: { id: string; app: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const token = await getCookie('token');
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-group-settings`,
          {
            id,
            app,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async () => {
          toast.success('Deletion successful.');
          await dispatch(getAllGroups());
        });
      return rejectWithValue('Failed Group Deletion.');
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessage = err.response.data.errors[0].message;
        toast.error(errorMessage);
        return rejectWithValue(errorMessage);
      }
      toast.error('Policy deletion unsuccessful.');
      return rejectWithValue(err.message);
    }
  },
);
