import React, { useState } from 'react';
import {
  Box,
  InputAdornment,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  Checkbox,
  TableBody,
  IconButton,
  MenuItem,
  TableRow,
  Menu,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppDispatch, RootState } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { StyledSelect, menuItemCSS } from '../../../../../../styles/select';
import { SubLabel } from '../../../../../../styles/sublabel';
import {
  buttonIconStyles,
  mediumIconStyles,
} from '../../../../../../styles/icon';
import {
  zeroPaddingAutoWidthButton,
  cyanBlueBackgroundButtonStyles,
} from '../../../../../../styles/button';
import ShopifyAddGroupDrawer from './addNewGroup';
import {
  updateClose,
  updateGroupConfiguration,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import {
  addNewUser,
  removeUser,
  updatePassword,
  updateUserName,
  updateGroup,
} from '../../../../../../Redux/Slices/Application/BasicSettings/basicSettingsSlice';
import { updateBasicSettings } from '../../../../../../Redux/Slices/Application/BasicSettings/updateApplicationHandler';
import { SmallHeading } from '../../../../../../styles/smallHeading';
import { deleteGroupConfiguration } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';

function ShopifyNonPlusUserConfiguration() {
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState(null);
  const { configuredGroups, app } = useSelector(
    (state: RootState) => state.application,
  );
  const { shopifyUsers } = useSelector(
    (state: RootState) => state.basicSettings,
  );

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    sourceId: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(sourceId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const addNewGroup = () => {
    dispatch(updateClose(true));
  };

  const handleDeleteGroup = async (id: string) => {
    await dispatch(deleteGroupConfiguration({ id, app }));
  };

  const handleEditGroup = async (id: string) => {
    dispatch(updateClose(false));
    await configuredGroups.forEach(async (element: any) => {
      if (element._id === id) {
        await dispatch(updateGroupConfiguration(element));
        dispatch(updateClose(true));
      }
    });
    setAnchorEl(null);
  };
  return (
    <Box display="flex" width="100%" py="0.5rem" gap="1rem">
      <ShopifyAddGroupDrawer />
      <Box
        border="0.063rem solid #EBEBEB"
        borderRadius="0.625rem"
        width="50%"
        p="1rem"
      >
        <SmallHeading>Add Groups and Manage User Configuration</SmallHeading>
        <SubLabel>Add Groups to configure IAM Users</SubLabel>
        <Box display="flex" justifyContent="space-between" py="1.5rem">
          <StyledTextField
            id="input-with-icon-textfield"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={buttonIconStyles} />
                </InputAdornment>
              ),
            }}
            sx={{ width: '15rem' }}
          />
          <Button
            sx={{ ...cyanBlueBackgroundButtonStyles, width: '8rem' }}
            onClick={addNewGroup}
          >
            <AddIcon sx={{ ...buttonIconStyles, color: 'white' }} />
            &nbsp; Add New
          </Button>
        </Box>
        <TableContainer>
          <Table sx={{ border: 'none', boxShadow: 'none' }}>
            <TableHead>
              <TableCell sx={zeroPaddingAutoWidthButton}>
                <Checkbox size="small" />
              </TableCell>
              <TableCell sx={zeroPaddingAutoWidthButton}>Group Name</TableCell>
              <TableCell sx={zeroPaddingAutoWidthButton}>Description</TableCell>
              <TableCell sx={zeroPaddingAutoWidthButton} align="right">
                Action
              </TableCell>
            </TableHead>
            <TableBody>
              {configuredGroups.map((group: any) => (
                <TableRow key={group._id}>
                  <TableCell sx={zeroPaddingAutoWidthButton}>
                    <Checkbox
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  </TableCell>
                  <TableCell sx={zeroPaddingAutoWidthButton}>
                    {group.identifier}
                  </TableCell>
                  <TableCell sx={zeroPaddingAutoWidthButton}>
                    {group.description}
                  </TableCell>
                  <TableCell align="right" sx={zeroPaddingAutoWidthButton}>
                    <IconButton
                      onClick={(event) => handleMenuOpen(event, group._id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && currentId === group._id}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          boxShadow: 'none',
                          border: '0.063rem solid #E3E3E3',
                        },
                      }}
                    >
                      <MenuItem
                        sx={menuItemCSS}
                        onClick={() => handleEditGroup(group._id)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        sx={menuItemCSS}
                        onClick={() => handleDeleteGroup(group._id)}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        border="0.063rem solid #EBEBEB"
        borderRadius="0.625rem"
        width="50%"
        p="1rem"
      >
        <SmallHeading>Shopify Staff User Mapping</SmallHeading>
        <SubLabel>Please Shopify Staff User Details</SubLabel>
        <Box display="flex" justifyContent="space-between" py="1.5rem">
          <StyledTextField
            id="input-with-icon-textfield"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={buttonIconStyles} />
                </InputAdornment>
              ),
            }}
            sx={{ width: '15rem' }}
          />
          <Button
            sx={{ ...cyanBlueBackgroundButtonStyles, width: '8rem' }}
            onClick={() => dispatch(addNewUser())}
          >
            <AddIcon sx={{ ...buttonIconStyles, color: 'white' }} />
            &nbsp; Add User
          </Button>
        </Box>
        <TableContainer sx={{ height: { xl: '35vh', md: '38vh' } }}>
          <Table sx={{ border: 'none', boxShadow: 'none' }}>
            <TableHead>
              <TableCell sx={zeroPaddingAutoWidthButton}>IAM Email</TableCell>
              <TableCell sx={zeroPaddingAutoWidthButton}>
                Staff User Email
              </TableCell>
              <TableCell sx={zeroPaddingAutoWidthButton}>
                Staff User Password
              </TableCell>
              <TableCell sx={zeroPaddingAutoWidthButton} align="right">
                Action
              </TableCell>
            </TableHead>
            <TableBody>
              {shopifyUsers?.map((element: any, index: number) => (
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      paddingY: '0.5rem !important',
                    }}
                  >
                    <StyledSelect
                      fullWidth
                      value={element.group_name}
                      sx={{ width: '7rem !important' }}
                      onChange={(event: any) => dispatch(
                        updateGroup({
                          groupName: event.target.value,
                          index,
                        }),
                      )}
                    >
                      {configuredGroups.map((source: any) => (
                        <MenuItem
                          key={source.identifier}
                          value={source.identifier}
                          sx={menuItemCSS}
                        >
                          {source.identifier}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingY: '0.5rem !important',
                      paddingX: '0 !important',
                    }}
                  >
                    <StyledTextField
                      fullWidth
                      id={`board-name-${index}`}
                      size="small"
                      name="username"
                      variant="outlined"
                      onChange={(event: any) => dispatch(
                        updateUserName({
                          username: event.target.value,
                          index,
                        }),
                      )}
                      value={element.username}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingY: '0 !important',
                      paddingX: '0.5rem',
                    }}
                  >
                    <StyledTextField
                      id={`board-name-${index}`}
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      disabled={element.encrypted}
                      variant="outlined"
                      onChange={(event: any) => dispatch(
                        updatePassword({
                          password: event.target.value,
                          index,
                        }),
                      )}
                      value={element.encrypted ? '********' : element.password}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: '0 !important' }}>
                    <DeleteOutlineOutlinedIcon
                      sx={mediumIconStyles}
                      onClick={() => dispatch(removeUser(index))}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(updateBasicSettings())}
        >
          Save Configuration
        </Button>
      </Box>
    </Box>
  );
}

export default ShopifyNonPlusUserConfiguration;
