import React, { useState } from 'react';
import {
  Box, Switch, Button,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSubstitution,
} from '../../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../../Redux/store';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { StyledTextField } from '../../../../styles/textField';
import { SubTitle } from '../../../../styles/subtitle';
import { SubLabel } from '../../../../styles/sublabel';

function Substitution() {
  const dispatch = useDispatch();
  const proxyInputDetails = useSelector((state: RootState) => state.proxySettings);
  const [htmlString, setHtmlString] = useState<any[]>(proxyInputDetails.Substitution.htmlString || []);
  const [locationHeader, setLocationHeader] = useState<any[]>(proxyInputDetails.Substitution.locationHeader || []);

  const addHtmlString = () => {
    setHtmlString([...htmlString, { find: '', replace: '' }]);
  };

  const updateReduxState = (updatedHtmlString: any[]) => {
    dispatch(updateSubstitution({
      ...proxyInputDetails.Substitution,
      htmlString: updatedHtmlString,
    }));
  };

  const removeHtmlString = (index: number) => {
    const updatedHtmlString = [...htmlString];
    updatedHtmlString.splice(index, 1);
    setHtmlString(updatedHtmlString);
    updateReduxState(updatedHtmlString);
  };

  const handleChangeHtmlString = (index: number, event: any) => {
    const { name, value } = event.target;
    const updatedHtmlString = [...htmlString];
    updatedHtmlString[index] = {
      ...updatedHtmlString[index],
      [name]: value,
    };

    setHtmlString(updatedHtmlString);
    updateReduxState(updatedHtmlString);
  };

  const addFormFieldsLocationHeader = () => {
    setLocationHeader([...locationHeader, { find: '', replace: '' }]);
  };

  const handleChangeLocationHeader = (index: number, event: any) => {
    const { name, value } = event.target;
    const updatedLocationHeader = [...locationHeader];
    updatedLocationHeader[index] = {
      ...updatedLocationHeader[index],
      [name]: value,
    };
    setLocationHeader(updatedLocationHeader);
    dispatch(updateSubstitution({
      ...proxyInputDetails.Substitution,
      locationHeader: updatedLocationHeader,
    }));
  };

  const handleSubstitutionToggle = (event: any) => {
    dispatch(updateSubstitution({
      ...proxyInputDetails.Substitution,
      enable: event.target.checked,
    }));
  };

  const removeFieldsLocationHeader = (index: number) => {
    const updatedLocationHeader = [...locationHeader];
    updatedLocationHeader.splice(index, 1);
    setLocationHeader(updatedLocationHeader);
    dispatch(updateSubstitution({
      ...proxyInputDetails.Substitution,
      locationHeader: updatedLocationHeader,
    }));
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ResponsiveTypography>Substitution</ResponsiveTypography>
          <Switch
            name="SubstitutionToggle"
            onChange={handleSubstitutionToggle}
            checked={proxyInputDetails?.Substitution?.enable}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <SubTitle>
          String/HTML Tag Substitution
        </SubTitle>
        <SubLabel>
          1. String replacement is a method to replace a keyword by a desired
          new keyword in HTML response.
        </SubLabel>
        <SubLabel>
          2. Substitution feature for string replacement is used to find and
          replace a string from the HTTP response of primary server address.
        </SubLabel>
        <SubLabel>
          3. HTML tag replacement is a method to replace an entire HTML tag from
          response by a desired new HTML tag.
        </SubLabel>
        <SubLabel>
          4. Substitution feature for HTM tag replacement is used to find and
          replace a HTML tag from the HTTP response of primary server address.
        </SubLabel>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addHtmlString}>+ Add Member</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {htmlString.map((html: any, index: number) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                name="find"
                label="eg. Example"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: '12.5rem',
                }}
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                value={html.find || ''}
                onChange={(event:any) => handleChangeHtmlString(index, event)}
              />
              <StyledTextField
                id="outlined-basic"
                size="small"
                name="replace"
                label="eg. New Example"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: '12.5rem',
                }}
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                value={html.replace || ''}
                onChange={(event:any) => handleChangeHtmlString(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeHtmlString(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <br />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <ResponsiveTypography>
          Location Header Substitution
        </ResponsiveTypography>
        <SubLabel>
          1. The HTTP Location header is a response header that is used under 2
          circumstances to ask a browser to redirect a URL (status code 302) or
          provide information about the location of a newly created resource
          (status code of 201). Its usage is often confused with another HTTP
          Header which is HTTP Content-Location header.
        </SubLabel>
        <SubLabel>
          2. Substitution feature for location based restriction is used to find
          and replace a domain from the primary server address to the proxied
          domain.
        </SubLabel>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFieldsLocationHeader}>+ Add Member</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {locationHeader.map((element: any, index: number) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                name="find"
                label="eg. example.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: '12.5rem',
                }}
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                value={element.find || ''}
                onChange={(event:any) => handleChangeLocationHeader(index, event)}
              />
              <StyledTextField
                id="outlined-basic"
                size="small"
                name="replace"
                label="eg. newsite.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: '12.5rem',
                }}
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                value={element.replace || ''}
                onChange={(event:any) => handleChangeLocationHeader(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFieldsLocationHeader(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Substitution;
