import React, { useState } from 'react';
import {
  Box, Switch, Button,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch, useSelector } from 'react-redux';
import { updateCORS } from '../../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../../Redux/store';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { StyledTextField } from '../../../../styles/textField';
import { SubLabel } from '../../../../styles/sublabel';

function CORS() {
  const dispatch = useDispatch();
  const corsFeature = useSelector((state: RootState) => state.proxySettings.CORS);

  const [domain, setDomain] = useState<string[]>(corsFeature.DomainList || []);

  const addFormFields = () => {
    setDomain([...domain, '']);
  };

  const updateReduxState = (updatedDomains: string[]) => {
    dispatch(updateCORS({
      ...corsFeature,
      DomainList: updatedDomains,
    }));
  };
  const removeFields = (index: number) => {
    const updatedDomains = [...domain];
    updatedDomains.splice(index, 1);
    setDomain(updatedDomains);
    updateReduxState(updatedDomains);
  };

  const handleChange = (index: number, event: any) => {
    const updatedDomains = [...domain];
    updatedDomains[index] = event.target.value;
    setDomain(updatedDomains);
    updateReduxState(updatedDomains);
  };

  const handleCORSDomainToggle = (event: any) => {
    dispatch(updateCORS({
      ...corsFeature,
      enable: event.target.checked,
    }));
  };

  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ResponsiveTypography>CORS</ResponsiveTypography>
          <Switch
            onChange={handleCORSDomainToggle}
            checked={corsFeature.enable}
          />
        </Box>
        <SubLabel>
          CORS feature is used to enable access to specific domains that are
          submitted by a user to make asynchronous requests to the server
          address.
        </SubLabel>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFields}>+ Add Domain</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {domain.map((element: any, index: number) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                label="Eg.(<domain>.com)"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: '25rem',
                }}
                inputProps={{
                  style: {
                    fontSize: '0.938rem',
                  },
                }}
                value={element || ''}
                onChange={(event:any) => handleChange(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFields(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CORS;
