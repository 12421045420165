import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, Divider } from '@mui/material';
import google from '../assets/images/google-logo.png';
import zoom from '../assets/images/zoom.png';
import shopify from '../assets/images/shopify.png';
import wordpress from '../assets/images/wordpress.png';
import casb from '../assets/images/casb-solution.png';
import headerBased from '../assets/images/header-based.png';
import urlRewriting from '../assets/images/re-writing.png';
import MFA from '../assets/images/mfa.png';

function GettingStarted() {
  const images = [
    {
      title: process.env.REACT_APP_GOOGLE_KEY,
      path: google,
    },
    {
      name: 'Zoom',
      path: zoom,
    },
    {
      name: 'Shopify',
      path: shopify,
    },
    {
      name: 'WordPress',
      path: wordpress,
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: '#F3F5FA',
        color: '#717171',
      }}
    >
      <Box
        sx={{
          px: '0.313rem',
        }}
      >
        <Typography variant="h3" color="#3D3D3D" sx={{ fontWeight: 600 }}>
          Get Started with our Reverse Proxy
        </Typography>
        <br />
        <Typography>Securing enterprises and cloud apps without trading performance</Typography>
        <br />
        <br />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h3" color="#0F2C5E" sx={{ fontWeight: 500 }}>
              95%
            </Typography>
            <Typography>increase in security</Typography>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box>
            <Typography variant="h3" color="#0F2C5E" sx={{ fontWeight: 500 }}>
              50+
            </Typography>
            <Typography>active users</Typography>
          </Box>
        </Box>
        <br />
        <br />
        <Typography>We Integrate with 20+ Solutions</Typography>
        <br />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            justifyContent: 'space-between',
          }}
        >
          {images.map((item) => (
            <img height="2.5rem" src={item.path} alt={item.name} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function Item({ title, description, imagePath }: any) {
  if (title === 'Get Started with our Reverse Proxy') {
    return <GettingStarted />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '2rem',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#F3F5FA',
        color: '#717171',
        px: '3rem',
      }}
    >
      <Box>
        <Typography variant="h4" color="#3D3D3D" fontWeight={600}>
          {title}
        </Typography>
        <br />
        <Typography>{description}</Typography>
      </Box>
      <br />
      <Box>
        <img src={imagePath} alt={title} width="100%" />
      </Box>
    </Box>
  );
}

export default function SignUpCarousel() {
  const items = [
    {
      title: 'Get Started with our Reverse Proxy',
      description: 'Securing enterprises and cloud apps without trading performance.',
    },
    {
      title: 'Cloud Access Security Brokers',
      description: 'Control access to your cloud data in real time with CASB security.',
      imagePath: casb,
    },
    {
      title: 'URL Re-writing',
      description: 'Rewrite site URLs to make them readable and user-friendly.',
      imagePath: urlRewriting,
    },
    {
      title: 'MFA Authentication',
      description:
        ' Add an MFA layer over any cloud app that does not inherently have any security methods enabled.',
      imagePath: MFA,
    },
    {
      title: 'Header Based Authentication',
      description:
        'Login to on premise apps with a URL header to authenticate user agents using an HTTP header.',
      imagePath: headerBased,
    },
  ];

  return (
    <Carousel
      interval={3000}
      autoPlay
      animation="slide"
      sx={{
        width: '100%',
        height: '97vh',
      }}
    >
      {items.map((item) => (
        <Item title={item.title} description={item.description} imagePath={item.imagePath} />
      ))}
    </Carousel>
  );
}
