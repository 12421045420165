import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import { RootState, AppDispatch } from '../../../../Redux/store';
import {
  lightBackgroundButtonStyles,
  lightBlueBackgroundButtonStyles,
} from '../../../../styles/button';
import SPMetadataModal from '../../../../components/CASBApp/AuthenticationSource/SPMetadataModal';
import ImportIDPMetadata from '../../../../components/CASBApp/AuthenticationSource/ImportIDPMetadata';
import {
  downloadXML,
  generateAndOpenMetadataURL,
} from '../../../../utils/SAMLAuthentication';
import {
  generateMetaData,
  testSAMLConnection,
} from '../../../../utils/utility';
import {
  updateAuthenticationID,
  updateSPMetadata,
} from '../../../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';
import { SubLabel } from '../../../../styles/sublabel';
import { SubTitle } from '../../../../styles/subtitle';
import { apps } from '../../app';
import { buttonIconStyles } from '../../../../styles/icon';
import { ResponsiveTypography } from '../../../../styles/inputLabel';

function AuthenticationSourceInformation() {
  const [open, setOpen] = useState(false);
  const [idpOpen, setIdpOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { identifier, _id } = useSelector(
    (state: RootState) => state.authenticationSource,
  );
  const { app } = useSelector((state: RootState) => state.application);

  const isValidIdentifier = !!identifier;

  const handleIdentifierError = () => {
    toast.error('Please enter valid authentication name.');
  };

  const createHandler = (fn: any) => async () => {
    if (!isValidIdentifier) {
      handleIdentifierError();
      return;
    }
    await fn({ identifier, _id, dispatch });
  };

  const downloadXMLHandler = createHandler(downloadXML);
  const generateAndOpenMetadataURLHandler = createHandler(
    generateAndOpenMetadataURL,
  );

  const handleGenerateMetaData = () => {
    if (!isValidIdentifier) {
      handleIdentifierError();
      return;
    }
    if (!_id) {
      generateMetaData(identifier)
        .then((data: any) => {
          setOpen(true);
          dispatch(
            updateAuthenticationID((data as any)?.success?.sp_metadata?._id),
          );
          dispatch(updateSPMetadata((data as any).success?.sp_metadata?.saml));
        })
        .catch((error: any) => {
          toast.error(error);
        });
    }
  };
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={{
          xl: '1rem !important',
          lg: '0.75rem !important',
          md: '0.5rem !important',
        }}
        borderBottom="0.063rem solid #E3E3E3"
        pb={1}
      >
        <SubTitle>Note</SubTitle>
        <SubLabel>
          We support all standard IDPs like&nbsp; miniOrange, Azure AD, Okta,
          Ping, Keycloak etc.&nbsp;
          <a
            href="https://www.miniorange.com/casb/integrations/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
          &nbsp; to view all our integrations.
        </SubLabel>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={{
          xl: '1rem !important',
          lg: '0.75rem !important',
          md: '1rem !important',
        }}
        borderBottom="0.063rem solid #E3E3E3"
        py={{ xl: '1rem', lg: '0.75rem', md: '0.5rem' }}
      >
        <SPMetadataModal open={open} setOpen={setOpen} />
        <ImportIDPMetadata open={idpOpen} setOpen={setIdpOpen} />
        <ResponsiveTypography>SP Metadata</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          {!_id ? (
            <Button
              sx={{
                ...lightBackgroundButtonStyles,
                width: {
                  xxl: '11rem !important',
                  xl: '10rem !important',
                  lg: '8rem !important',
                  md: '6rem !important',
                },
              }}
              onClick={handleGenerateMetaData}
            >
              Generate Metadata
            </Button>
          ) : (
            <Button
              sx={{
                ...lightBackgroundButtonStyles,
                width: {
                  xxl: '11rem !important',
                  xl: '10rem !important',
                  lg: '8rem !important',
                  md: '6rem !important',
                },
              }}
              onClick={() => setOpen(true)}
            >
              View Metadata
            </Button>
          )}
          <Button
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '11rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            onClick={generateAndOpenMetadataURLHandler}
          >
            Metadata URL
          </Button>
        </Box>
        <Button
          sx={{
            ...lightBackgroundButtonStyles,
            width: {
              xxl: '12rem !important',
              xl: '10rem !important',
              lg: '8rem !important',
              md: '6rem !important',
            },
          }}
          onClick={downloadXMLHandler}
        >
          Download Metadata
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={{
          xl: '1rem !important',
          lg: '0.75rem !important',
          md: '1rem !important',
        }}
        borderBottom="0.063rem solid #E3E3E3"
        py="1rem"
      >
        <ResponsiveTypography>IDP Metadata</ResponsiveTypography>
        <Button
          sx={{
            ...lightBackgroundButtonStyles,
            width: {
              xxl: '12rem !important',
              xl: '10rem !important',
              lg: '8rem !important',
              md: '6rem !important',
            },
          }}
          onClick={() => setIdpOpen(true)}
        >
          Upload Metadata
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        borderBottom="0.063rem solid #E3E3E3"
        py="1rem"
      >
        <ResponsiveTypography>Validate Connection</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            sx={{
              ...lightBlueBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            startIcon={(
              <SettingsEthernetOutlinedIcon
                sx={{ ...buttonIconStyles, color: '#0065FF' }}
              />
            )}
            onClick={() => testSAMLConnection(_id, app)}
            disabled={!_id}
          >
            Test Connection
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={{
          xl: '1rem !important',
          lg: '0.75rem !important',
          md: '1rem !important',
        }}
        borderBottom="0.063rem solid #E3E3E3"
        py="1rem"
      >
        <ResponsiveTypography>Guide</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            startIcon={
              <YouTubeIcon sx={{ ...buttonIconStyles, color: 'red' }} />
            }
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
          >
            Video Guide
          </Button>
          <Button
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
                md: '6rem !important',
              },
            }}
            startIcon={
              <ArticleIcon sx={{ ...buttonIconStyles, color: 'blue' }} />
            }
            onClick={() => window.open(`${apps[app].guide}#step2`, '_blank')}
          >
            Setup Guide
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AuthenticationSourceInformation;
